import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "./Sub Component/Navbar";
import Carousel from "react-multi-carousel";
import StickyBox from "react-sticky-box";
import dayjs from 'dayjs';

// Modal Import
import { Animated } from "react-animated-css";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import Modal from "react-modal";
// import Calendar from './Sub Component/calender'
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
// Icon import
import { MdLocationPin, MdOutlineShoppingCart } from "react-icons/md";
import { IoCloseOutline, IoChatbubblesSharp } from "react-icons/io5";
import { AiFillApple, AiFillStar } from "react-icons/ai";
import { BsFillStarFill, } from "react-icons/bs";
import { FiChevronLeft, FiCornerUpRight } from "react-icons/fi";
import { RWebShare } from 'react-web-share'



// Credit Card Import
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";

// AOS Transation import
import "aos/dist/aos.css";
import Aos from "aos";
import Footer from "./Sub Component/footer";
import { useSSRSafeId } from "@react-aria/ssr";
import axios from "axios";
import Swal from "sweetalert2";
import "../assets/style.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { logDOM } from "@testing-library/react";
import api from '../assets/variable'
import { open_google_map } from "../store/acion";
Aos.init();

function Home(props) {


  let arr = [
    {
      id: 0,
      title: "Saloon Service",
      image: "https://freedesignfile.com/upload/2017/09/Beautician-for-customer-beauty-services-Stock-Photo-09.jpg",
      status: "HairCut",
      details: "Get info For Messge Get info ",
      select: false,
    },
    {
      id: 1,
      title: "Hair Cut",
      image: "https://hips.hearstapps.com/esq.h-cdn.co/assets/17/29/980x980/square-1500667303-es-072117-talk-to-your-barber-about-your-hair.jpg?resize=640:*",
      status: "In Home",
      details: "Get info For Hair Cut",
      select: false,
    },
    {
      id: 2,
      title: "Massage",
      image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS1MVmN5D6q5w6yBEUwQ0SjGuNFWs3ycLM5Ew&usqp=CAU",
      status: "In Shop",
      details: "Get info For Messge Get info ",
      select: false,
    },
    {
      id: 3,
      title: "Nail Services ",
      image: "https://s3.amazonaws.com/salonclouds-uploads/blog/blog_1575589880234492352.png",
      status: "In Shop",
      details: "Get info For Messge Get info ",
      select: false,
    },
    {
      id: 4,
      title: "Saloon Service",
      image: "https://freedesignfile.com/upload/2017/09/Beautician-for-customer-beauty-services-Stock-Photo-09.jpg",
      status: "HairCut",
      details: "Get info For Messge Get info ",
      select: false,
    },
    {
      id: 5,
      title: "Nail Service",
      image:
        "https://i.guim.co.uk/img/media/0da62f03250c88a717e0beab4972cc7584b9e333/0_275_6000_3600/master/6000.jpg?width=1200&height=900&quality=85&auto=format&fit=crop&s=74cf7d5692c89b8072cf0c1822478fa8",
      status: "In Shop",
      details: "Get info For Messge Get info ",
      select: false,
    },
    {
      id: 6,
      title: "Barber Shop",
      image: "https://hips.hearstapps.com/esq.h-cdn.co/assets/17/29/980x980/square-1500667303-es-072117-talk-to-your-barber-about-your-hair.jpg?resize=640:*",
      status: "In Home",
      details: "Get info For Messge Get info ",
      select: false,
    },
    {
      id: 7,
      title: "Special HairCut",
      image: "https://static9.depositphotos.com/1518767/1119/i/450/depositphotos_11193354-stock-photo-blond-haired-man-having-a.jpg",
      status: "In Shop",
      details: "Get info For Messge Get info ",
      select: false,
    },
    {
      id: 8,
      title: "Hair Cut",
      image: "https://hips.hearstapps.com/esq.h-cdn.co/assets/17/29/980x980/square-1500667303-es-072117-talk-to-your-barber-about-your-hair.jpg?resize=640:*",
      status: "In Home",
      details: "Get info For Messge Get info ",
      select: false,
    },
    {
      id: 9,
      title: "Massage",
      image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS1MVmN5D6q5w6yBEUwQ0SjGuNFWs3ycLM5Ew&usqp=CAU",
      status: "In Shop",
      details: "Get info For Messge Get info ",
      select: false,
    },
    {
      id: 10,
      title: "Nail Services ",
      image: "https://s3.amazonaws.com/salonclouds-uploads/blog/blog_1575589880234492352.png",
      status: "In Shop",
      details: "Get info For Messge Get info ",
      select: false,
    },

    {
      id: 11,
      title: "Saloon Service",
      image: "https://freedesignfile.com/upload/2017/09/Beautician-for-customer-beauty-services-Stock-Photo-09.jpg",
      status: "HairCut",
      details: "Get info For Messge Get info ",
      select: false,
    },
    {
      id: 12,
      title: "Nail Service",
      image:
        "https://i.guim.co.uk/img/media/0da62f03250c88a717e0beab4972cc7584b9e333/0_275_6000_3600/master/6000.jpg?width=1200&height=900&quality=85&auto=format&fit=crop&s=74cf7d5692c89b8072cf0c1822478fa8",
      status: "In Shop",
      details: "Get info For Messge Get info ",
      select: false,
    },
    {
      id: 13,
      title: "Barber Shop",
      image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT9BdlbQAaTCy8reWSsWCwtJhD2P6HUxYbnUw&usqp=CAU",
      status: "In Home",
      details: "Get info For Messge Get info ",
      select: false,
    },
    {
      id: 14,
      title: "Special HairCut",
      image: "https://static9.depositphotos.com/1518767/1119/i/450/depositphotos_11193354-stock-photo-blond-haired-man-having-a.jpg",
      status: "In Shop",
      details: "Get info For Messge Get info ",
      select: false,
    },
  ];
  let [loading, setLoading] = useState(false);
  let [share_loading, setShare_Loading] = useState(false);
  let [share_detect, setShare_detect] = useState(false);
  let [Searched_Prefrence, setPrefrence] = useState(localStorage.getItem('Searched_History') ? JSON.parse(localStorage.getItem('Searched_History')) : { searchingText: '' })
  let [searched_prefrenceLocation, setSearched_Location] = useState(localStorage.getItem('Selected_location') ? JSON.parse(localStorage.getItem('Selected_location')) : { addrs: '' })
  let [error, setError] = useState("");
  const params = useLocation();
  const [value, onChange] = useState(new Date());
  const [value1, setValue1] = React.useState(dayjs(new Date()));
  let [time, setTime] = useState([10]);
  let [seeMore, setseeMore] = useState(false);
  let [shop_data, setshop_data] = useState(false);
  let [time_slot, settime_slot] = useState([]);
  let [card_token, setcard_token] = useState("");
  let [near_by, setnear_by] = useState([]);
  let [show_near, setshow_near] = useState(false);
  let [shop_id, setshop_id] = useState(false);
  let [artist_select, setartist_select] = useState();
  let [current_user, setcurrent_user] = useState();
  let [addresshow, setAddressshow] = useState('')
  let [selected, setSelected] = useState(['Prefrence']);
  let [showLabel, setShowLabel] = useState(true)
  let [Searched_value, setSearched_Value] = useState(Searched_Prefrence.searchingText)
  let [searched_Label, setSearched_Label] = useState(`${searched_prefrenceLocation.addrs.slice(0, 20)} . ${Searched_Prefrence.link ? Searched_Prefrence.link : ''}`)
  let getFilteredCategories = (e, paramCheck) => {
    let checkProperty = e.servicedata?.filter((e => selected.includes('Prefrence') ? e?.toLowerCase().includes(paramCheck.toLowerCase()) : e))
    if (checkProperty?.length > 0) {
      return e
    }

  }


  let getFilteredCategoriesSearch = (e, paramCheck) => {
    let checkProperty = e.servicedata.filter((e => e.toLowerCase().includes(paramCheck.toLowerCase())))
    if (checkProperty.length > 0) {
      return e
    }

  }

  let near_by_filtered = near_by !== undefined ?
    selected.includes('All') ? near_by.filter(e => e.name.toLowerCase().includes(Searched_value.toLocaleLowerCase()) || getFilteredCategoriesSearch(e, Searched_value)) : near_by.filter((e) => getFilteredCategories(e, Searched_Prefrence.link)).filter(e => e.name.toLowerCase().includes(Searched_value.toLocaleLowerCase()) || getFilteredCategoriesSearch(e, Searched_value))
    :
    []
  console.log("Returned>>>>>", near_by_filtered);
  const nearby = async () => {
    setLoading(true);
    console.log(",,,,,>>>>>>>>>>", props.state);
    setcurrent_user('')

    try {
      const response = await axios.post(
        `${api}/api/user/getshops`,
        {
          userlocation: [searched_prefrenceLocation.lat, searched_prefrenceLocation.lng],
          // userlocation: location,

        },
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );





      console.log(",,,,,>>>>>>>>>>>>>>>>>>>>>>>>>>nnnn", response);
      setnear_by(response.data.data);
      setLoading(false);
      setshow_near(true);
     



    } catch (error) {
      console.log("error>>>>>>", error);

      setLoading(false);
      if (error.response.data.error == 'Auth Invalid') {
        navigate('/')

        return
      }
      toast.error(error.response.data.error);
    }
  };
  useEffect(() => {
    document.body.style.background = ``;
    Modal.setAppElement("body");
    // if (!localStorage.getItem('_id') && !window.location.href.includes('&Item')) {
    //   navigate('/')
    // }



    // if (!localStorage.getItem('Searched_History') && !window.location.href.includes('&Item')) {
    //   navigate('/')
    // }


    // if (!localStorage.getItem('Selected_location') && !window.location.href.includes('&Item')) {
    //   navigate('/')
    // }
  });
  useEffect(() => {




    nearby();
    // if (searched_prefrenceLocation !== null) {
    //   setAddressshow(searched_prefrenceLocation.addrs)

    // }

    if (window.location.href.includes('&Item')) {
       let refred = window.location.href.slice(window.location.href.indexOf('&Item'),window.location.href.indexOf("?")==-1?window.location.href.length:window.location.href.indexOf("?"));

      localStorage.setItem("RefredUrl", refred)

      setShare_Loading(true)
      setShare_detect(true)
      let uid = window.location.href.slice(window.location.href.indexOf('&Item') + 5,window.location.href.indexOf("?")==-1?window.location.href.length:window.location.href.indexOf("?"))
      console.log("Link Detected", uid)
      openModaldetails(uid)

    }
  }, []);

  let navigate = useNavigate();
  let [animationIn, setanimationIn] = useState("fadeInUp");
  let [outanimation, setOutAnimation] = useState("fadeOutDown");
  const [day, setday] = useState(["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]);
  const [appot, setappot] = useState();

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
      paritialVisibilityGutter: 60,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 50,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      paritialVisibilityGutter: 30,
    },
  };



  // test functionallity

  let [selectedArr, setSelectedArr] = useState([]);
  let [random, setRandom] = useState();

  let seletedFoo = (data, h, category, main) => {
    console.log("k", data);
    setSelectedArr2([]);
    settime_slot([]);
    setSelectedArr3([]);
    if (category == "shop") {
      if (h == "shop") {
        if (selectedArr.filter((e) => e.type2 == "home").length > 0) {
          console.log("error", h);
          alert("You have always selected In Home Service");
        } else {
          data.type2 = h;
          let obj = {
            serviceId: data._id,
            category: main.name,
            categoryId: main._id,
            name: data.name,
            price: data.price,
            time: parseFloat(data.time),
            type2: h,
          };
          let a = selectedArr;

          if (a.filter((e) => e.serviceId == obj.serviceId).length > 0) {
            // console.log("yyyyyyyyy",selectedArr );

            a = selectedArr.filter((e) => e.serviceId != obj.serviceId);
          } else {
            a.push(obj);
            a.sort(function (a, b) {
              return a - b;
            });
          }

          setRandom(Math.random() * 1000);
          setSelectedArr(a);
        }
      } else {





        if (selectedArr.filter((e) => e.type2 == "shop").length > 0) {
          console.log("error", h);
          alert("You have always selected In Shop Service");



        } else {

          console.log("CHECK", localStorage.getItem('user_location'));
          if (JSON.parse(localStorage.getItem('user_location')).length === 0 || localStorage.getItem('user_location') == null) {
            if (window.location.href.includes('&Item')) {

               let refred = window.location.href.slice(window.location.href.indexOf('&Item'),window.location.href.indexOf("?")==-1?window.location.href.length:window.location.href.indexOf("?"));

              localStorage.setItem("RefredUrl", refred)
              navigate('/map')
              return
            }

          }

          let obj = {
            serviceId: data._id,
            category: main.name,
            categoryId: main._id,
            name: data.name,
            price: data.priceinhouse,
            time: parseFloat(data.timeinhouse),
            type2: h,
          };
          let a = selectedArr;

          if (a.filter((e) => e.serviceId == obj.serviceId).length > 0) {
            // console.log("yyyyyyyyy",selectedArr );

            a = selectedArr.filter((e) => e.serviceId != obj.serviceId);
          } else {
            a.push(obj);
            a.sort(function (a, b) {
              return a - b;
            });
          }

          setRandom(Math.random() * 1000);
          setSelectedArr(a);
        }




      }
    } else {
      if (h == "shop") {
        if (selectedArr.filter((e) => e.type2 == "home").length > 0) {
          console.log("error", h);
          alert("You have always selected In Home Service");
        } else {
          data.type2 = h;
          let obj = {
            serviceId: data._id,
            category: main.name,
            categoryId: main._id,
            name: data.name,
            price: data.price,
            time: parseFloat(data.time),
            type2: h,
          };
          let a = selectedArr;

          if (a.filter((e) => e.serviceId == obj.serviceId).length > 0) {
            // console.log("yyyyyyyyy",selectedArr );

            a = selectedArr.filter((e) => e.serviceId != obj.serviceId);
          } else {
            a.push(obj);
            a.sort(function (a, b) {
              return a - b;
            });
          }

          setRandom(Math.random() * 1000);
          setSelectedArr(a);
        }
      } else {
        if (selectedArr.filter((e) => e.type2 == "shop").length > 0) {
          console.log("error", h);
          alert("You have always selected In Shop Service");
        } else {

          console.log("CHECK", localStorage.getItem('user_location'));
          if (JSON.parse(localStorage.getItem('user_location')).length === 0 || localStorage.getItem('user_location') == null) {
            if (window.location.href.includes('&Item')) {

               let refred = window.location.href.slice(window.location.href.indexOf('&Item'),window.location.href.indexOf("?")==-1?window.location.href.length:window.location.href.indexOf("?"));

              localStorage.setItem("RefredUrl", refred)
              navigate('/map')
              return
            }

          }




          data.type2 = h;
          let obj = {
            serviceId: data._id,
            category: main.name,
            categoryId: main._id,
            name: data.name,
            price: data.priceinhouse,
            time: parseFloat(data.timeinhouse),
            type2: h,
          };
          let a = selectedArr;

          if (a.filter((e) => e.serviceId == obj.serviceId).length > 0) {
            // console.log("yyyyyyyyy",selectedArr );

            a = selectedArr.filter((e) => e.serviceId != obj.serviceId);
          } else {
            a.push(obj);
            a.sort(function (a, b) {
              return a - b;
            });
          }

          setRandom(Math.random() * 1000);
          setSelectedArr(a);
        }
      }
    }
  };

  let [selectedArr1, setSelectedArr1] = useState([]);

  let seletedFoo1 = (id) => {
    let a = selectedArr1;

    if (a.includes(id)) {
      a = selectedArr1.filter((e) => e !== id);
    } else {
      a.push(id);
      a.sort(function (a, b) {
        return a - b;
      });
    }

    setRandom(Math.random() * 1000);
    setSelectedArr1(a);
  };

  let [selectedArr2, setSelectedArr2] = useState([]);
  let [chooseBa, setChooseBa] = useState(false)

  const chooseBest = (arg) => {
    if (shop_data.artistObj.length == 0) {
      return;
    }
    let def = 0;
    let secleted_Max = 0;

    if (arg) {
      setChooseBa(false);
      setSelectedArr2([]);
    } else {
      setChooseBa(true);
      shop_data.artistObj.forEach((element, i) => {
        console.log("ARTIST ", element.artistnumratings, def);
        if (element.artistnumratings > def) {
          def = element.artistnumratings;
          secleted_Max = i
        }
      });

      console.log("SELECTED MAX", secleted_Max);
      if (def == 0) {
        let array = selectedArr2;
        array[0] = shop_data.artistObj[0];
        setSelectedArr2(array);
        setartist_select(shop_data.artistObj[0].artistdata._id)

      } else {
        let arr = [];
        arr[0] = shop_data.artistObj[secleted_Max];
        setSelectedArr2(arr);
        setartist_select(shop_data.artistObj[secleted_Max].artistdata._id)
        console.log("check", shop_data.artistObj[secleted_Max].artistdata._id);
      }


    }
  };

  const selectartist = async (id) => {
    if (chooseBa) {
      setChooseBa(false)
      return
    }
    setartist_select(id.artistdata._id)
    let a = [id];

    if (selectedArr2.includes(id)) {
      a = [];
      setartist_select('')

    } else {
      setartist_select(id.artistdata._id)
      a = [id];
    }

    setRandom(Math.random() * 1000);
    setSelectedArr2(a);
  }
  let seletedFoo2 = async (id,OrderDate) => {
    console.log("aaaaaaaaaaaaaaaaaaaaaaaa",OrderDate);
    if (!id) {
      return
    }
    if (selectedArr.length == 0) {
      Swal.fire({
        // title: '<strong>*Select  Date</strong>',
        icon: "info",
        html: `Select Services</br>.
          `,
        // showConfirmButton:false
        // showCloseButton: true,
        // showCancelButton: true,
      });

      return;
    }

    let time2 = 0;
    for (const v of selectedArr) {
      time2 = time2 + parseInt(v.time);
    }



    Swal.showLoading();

    const today = OrderDate;
    const day2 = day[today.getDay()];
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    let todays = yyyy + "-" + mm + "-" + dd;

    console.log("date>>>>>>>>>>>", selectedArr);

    var date = new Date();
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "PM" : "AM";
    var dd2 = String(date.getDate()).padStart(2, "0");
    var mm2 = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy2 = date.getFullYear();

    let client_todays = yyyy2 + "-" + mm2 + "-" + dd2;

    let appoitment_data = {
      orderdate: mm + "/" + dd + "/" + yyyy,
      shopId: shop_id,
      userId: current_user,
      artistId: id,
      day: day2,
      services: selectedArr,
      products: selectedArr1,
    };
    setappot(appoitment_data);
    let location=JSON.parse(localStorage.getItem('Selected_location'))
    console.log("bbbbbbbbbvc",location);
    // Fetch Artist details
    const body=
    {
      home: false,
      // userId: current_user,
      userlocation:{
       "location":[location.lat,location.lng]
       },
      orderdate: `"${todays}"`,
      servicetime: time2,
      label: ampm,
      ClientMinutes: `"${minutes}"`,
      artistId: id,
      ClientHours: `"${hours}"`,
      clientDateTime: `"${client_todays}"`,
    }
    const response = await axios.post(
      `${api}/api/user/getSlotsLatest`,body,
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    Swal.close();
    console.log("ttttttttttttttt", response,body);
    if (response.data.success) {
      settime_slot(response.data.data);
    } else {
      settime_slot([]);

      Swal.fire({
        title: "<strong>*Select  Date</strong>",
        icon: "info",
        html: ` ${response.data.message} </br>.
        `,
        confirmButtonColor: '#13242A',

        // showCloseButton: true,
        // showCancelButton: true,
      });
    }
  };
  const [selected_product, setSelected_product] = React.useState([0]);

  let [selectedArr3, setSelectedArr3] = useState([]);

  let seletedFoo3 = (id) => {
    let a = [id];

    if (selectedArr3.includes(id)) {
      a = [];
    } else {
      a = [id];
    }

    setRandom(Math.random() * 1000);
    setSelectedArr3(a);
  };

  let [selectedArr4, setSelectedArr4] = useState([]);

  let seletedFoo4 = (id) => {
    let a = [id];

    if (selectedArr4.includes(id)) {
      a = [];
    } else {
      a = [id];
    }

    setRandom(Math.random() * 1000);
    setSelectedArr4(a);
  };

  // Model details Data

  const [modalIsOpendetails, setIsOpendetails] = React.useState(false);
  const [divdetails, setDivdetails] = React.useState(false);

  async function openModaldetails(ShopId2) {
    console.log("KKKKKKKKKk", ShopId2);

    // Fetch Shop details
    setChooseBa(false)
    setLoading(true);

    setshop_id(ShopId2);
    try {
      const response = await axios.post(
        `${api}/api/user/allshopdata`,
        {
          shopId: ShopId2,
        },
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setshop_data(response.data);
      console.log("getAllData response ==>>>>>>>>> ", response.data.shop$);

      setLoading(false);
      setShare_Loading(false)

      if (localStorage.getItem('RefredUrl')) {
        localStorage.removeItem('RefredUrl')

      }
      if (response.data.shop$ == null) {
        navigate('/')

        return
      }


      setIsOpendetails(true);
      setDivdetails(true);

    } catch (error) {
      console.log(">>>>", error);
      setLoading(false);
      setShare_Loading(false)

      if (error.response.data.error == 'Auth Invalid') {
        navigate('/')

        return
      }
      if (localStorage.getItem('RefredUrl')) {
        localStorage.removeItem('RefredUrl')
      }
      toast.error(error.response.data.error);


      // Swal.fire({
      //   title:error.response.data.error,
      //   showDenyButton: false,
      //   showCancelButton: true,
      //   confirmButtonText: 'Yes',
      //   showConfirmButton:false,
      //   denyButtonText: 'No',
      //   customClass: {
      //     actions: 'my-actions',
      //     cancelButton: 'order-1 right-gap',
      //     confirmButton: 'order-2',
      //     denyButton: 'order-3',
      //   }
      // })
    }



  }

  function closeModaldetails() {
    console.log("Close,", share_detect, show_near);
    if (share_detect) {
      // if(!show_near){
      navigate('/')
      return
      // }
    }
    document.getElementsByClassName("content1")[0].style.backgroundImage = 'none'

    setDivdetails(false);
    setTimeout(() => {
      setIsOpendetails(false);
    }, 400);
  }

  // Payment Modal

  const [modalIsOpendetails1, setIsOpendetails1] = React.useState(false);
  const [divdetails1, setDivdetails1] = React.useState(false);

  function openModaldetails1() {
    setIsOpendetails1(true);
    setDivdetails1(true);
  }

  function closeModaldetails1() {
    setDivdetails1(false);
    setTimeout(() => {
      setIsOpendetails1(false);
    }, 400);
  }
  let [name, setName] = useState("");
  let [csv, setCsv] = useState("");
  let [expiry, setexpiry] = useState("");
  let [number, setNumber] = useState("");
  let [focus, setFocus] = useState("");
  let [PhoneNumber, setPhoneNumber] = useState("+"+1);

  let closeModal2 = () => {
    setSelected_product([0]);
    setDivdetails(false);
    setTimeout(() => {
      window.open("#Item_details", "_self");
      document.getElementById("Item_details").style.display = "block";
      // document.getElementById("shadow_Item_details").style.display = "flex";
      document.getElementById("buttonsResponsive").style.display = "flex";
      document.getElementsByClassName("myPRoductdiv")[0].style.display = "flex";
      // document.getElementsByClassName('myPRoductdiv')[1].style.display = 'flex';
      document.getElementById("closeOne").style.display = "flex";
      document.getElementById("BackOne").style.display = "block";

      document.getElementById("closeTwo").style.display = "none";
      document.getElementById("BackTwo").style.display = "none";
      document.getElementById("proceedfirsthalf").style.display = "none";


      // Show NExt Data
      document.getElementById("proceedfirst").style.display = "flex";
      document.getElementsByClassName("currentNav3")[0].style.display = "flex";
      document.getElementById("Artist_Time").style.display = "none";

      document.getElementsByClassName("currentNav4")[0].style.display = "none";
      setDivdetails(true);
      window.open("", "_self");
    }, 300);
  };

  let closeModal2half = () => {
    setSelected_product([0]);
    setDivdetails(false);
    setTimeout(() => {
      window.open("#Item_details", "_self");
      document.getElementById("BackTwo").style.display = "block";
      document.getElementById("BackTwoHalf").style.display = "none";
      // no need hide just show 
      document.getElementById("Artist_Time2").style.display = "none";
      document.getElementById("Artist_Time").style.display = "block";
      document.getElementById("proceedfirsthalf").style.display = "flex";

      document.getElementsByClassName("currentNav4")[0].style.display = "none";
      setDivdetails(true);
      window.open("", "_self");
    }, 300);
  };


  let closeModal3 = () => {
    setDivdetails(false);

    setTimeout(() => {

      document.getElementsByClassName("currentNav4")[0].style.display = "flex";
      document.getElementById("closeTwo").style.display = "flex";
      document.getElementById("BackTwoHalf").style.display = "block";
      document.getElementsByClassName("content1")[0].style.backgroundImage = 'none'

      document.getElementById("Artist_Time2").style.display = "flex";
      document.getElementById("proceedfirsthalf").style.display = "none";

      // Show NExt Data

      document.getElementsByClassName("stickyBox")[0].style.display = "none";
      document.getElementById("BackThree").style.display = "none";


      window.open("", "_self");
      setDivdetails(true);
    }, 300);
  };

  let closeModalForTimeslot = () => {
    setDivdetails(false);

    setTimeout(() => {
      window.open("#SelectTime", "_self");
      document.getElementsByClassName("currentNav4")[0].style.display = "flex";
      document.getElementById("Artist_Time").style.display = "flex";
      document.getElementById("closeTwo").style.display = "block";
      document.getElementById("BackTwo").style.display = "block";

      // Show NExt Data

      document.getElementsByClassName("stickyBox")[0].style.display = "none";
      document.getElementById("closeThree").style.display = "none";
      document.getElementById("BackThree").style.display = "none";

      setDivdetails(true);
      window.open("", "_self");
    }, 300);
  };

  let resetStates = () => {
    setSelectedArr([]);
    setSelectedArr1([]);
    setSelectedArr2([]);
    setSelected_product([0]);
  };

  const total_order = () => {
    let service = 0;
    let product = 0;

    selectedArr.map((v, i) => {
      service = service + v.price;
    });

    selectedArr1.map((v, i) => {
      product = product + v.price;
    });

    //  let total=
    // settax(12)
    return (service + product).toFixed(2);
  };

  const total_tax = () => {
    let service = 0;
    let product = 0;

    selectedArr.map((v, i) => {
      service = service + v.price;
    });

    selectedArr1.map((v, i) => {
      product = product + v.price;
    });

    //  let total=

    return (((service + product) / 100) * 3).toFixed(2);
  };


  const final_total = () => {
    let service = 0;
    let product = 0;

    selectedArr.map((v, i) => {
      service = service + v.price;
    });

    selectedArr1.map((v, i) => {
      product = product + v.price;
    });

    //  let total=
    let sub_total = (service + product).toFixed(2);
    let tax = (((service + product) / 100) * 3).toFixed(2);
    // let tip = selectedArr4.length > 0 ? selectedArr4[0] : 0;

    return (parseFloat(tax)) + parseFloat(sub_total)
  };

  const grand_total = () => {
    let service = 0;
    let product = 0;

    selectedArr.map((v, i) => {
      service = service + v.price;
    });

    selectedArr1.map((v, i) => {
      product = product + v.price;
    });

    let total = (service + product).toFixed(2);
    let tax = (((service + product) / 100) * 3).toFixed(2);
    let tip = selectedArr4.length > 0 ? selectedArr4[0] : 0;

    return parseFloat(tax) + parseFloat(total) + tip;
  };

  let checkvalidity = async () => {
    setError("");
    if (selectedArr.length === 0) {
      setError("Please Select a Service To Continue.");
      return;
    }

    if (selectedArr2.length === 0) {
      setError("Please Select a Artist  To Continue.");
      return;
    }

    if (selectedArr3.length === 0) {
      setError("Please Select a Time Slot To Continue.");
      return;
    }

    if (card_token == "") {
      setError("Please Select a payment method");
      return;
    }

    Swal.showLoading();

    let service = 0;
    let product = 0;
    let type = "";
    selectedArr.map((v, i) => {
      service = service + v.price;
      type = v.type2;
    });

    selectedArr1.map((v, i) => {
      product = product + v.price;
    });

    let total = (service + product).toFixed(2);
    let tax = (((service + product) / 100) * 3).toFixed(2);
    // let tip = selectedArr4.length > 0 ? selectedArr4[0] : 0;

    let total_bill =
      (await parseFloat(tax))
      + parseFloat(total)
    //  +tip;
console.log("current user",current_user);
    let appoitment = {
      seen: false,
      promocode: "NY2020",
      orderdate: appot.orderdate,
      shopId: appot.shopId,
      artistId: appot.artistId,
      deliveryinstruction: "pls do this thx",
      products: appot.products,
      services: appot.services,
      userId: current_user,
      totalbill: total_bill,
      totaldiscount: 0,
      paymethod: card_token,
      inhouse: type == "home" ? "yes" : "no",
      status: "awaiting-acceptance",
      appointment: {
        shopId: appot.shopId,
        day: appot.day,
        type: type,
        timeslot: selectedArr3[0].timeslot,
        timelabel: selectedArr3[0].label,
        artistId: appot.artistId,
      },
      totaldiscount: 0,
      customeraddresscustom: "Fortress",
      customeraddress: [34.231, 73.21313],
      paymenttype: "card",
      deliveryinstruction: "Some Info",
    };

    console.log("appoitment>>>>>>>>>>>>>>>>",JSON.stringify(appoitment));

    try {
      const response = await axios.post(`${api}/api/user/addappointment`, appoitment, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      });

      Swal.fire(
        "Appointment Confirmed"
        // 'You clicked the button!',
        // 'success'
      );

      setTimeout(() => {
        closeModaldetails();
        Swal.close();
        navigate("/AvailableApps")
      }, 1500);

      console.log("res>>>>>..", response);
    } catch (error) {
      Swal.close();
      console.log("eeeeee", error);
      Swal.fire({
        title: error.response.data.message,
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: "Yes",
        showConfirmButton: false,
        denyButtonText: "No",
        customClass: {
          actions: "my-actions",
          cancelButton: "order-1 right-gap",
          confirmButton: "order-2",
          denyButton: "order-3",
        },
      });
    }
  };

  const check_out = async () => {
    Swal.showLoading();
    let asd = expiry.slice(-2);
    let asd2 = expiry.slice(0, 2);
    let cardDetail = {
      cardNumber: number,
      expMonth: expiry.slice(0, 2),
      expYear: expiry.slice(-2),
      cvc: csv,
    };

   

    try {
      const response = await axios.post(`${api}/api/user/cardToken`, cardDetail, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      });

      console.log("token", PhoneNumber);
      try {
        const last4Str = String(number).slice(-4); // 👉️ '6789'
        // const last4Num = Number(last4Str)
        let cardDetail2 = {
          // userId: current_user,
          number:PhoneNumber,
          cardtoken: response.data.token.id,
          paymentmethods: [
            {
              cardtype: "mastercard",
              expiry: `${expiry.slice(0, 2)}/${expiry.slice(-2)}`,
              endswith: last4Str,
            },
          ],
        };
        const response22 = await axios.patch(`${api}/api/user/addpaymentmethod`, cardDetail2, {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
          },
        });

        console.log(">>>>>.2222222222nnnnnn",response22.data, response22.data.data.paymentmethods[response22.data.data.paymentmethods.length - 1]._id);
        setcurrent_user(response22?.data?.data?._id)
        setcard_token(response22.data.data.paymentmethods[response22.data.data.paymentmethods.length - 1]._id);
        Swal.close();
        closeModaldetails1();
      } catch (error) {
        console.log("aaaaa",error);
        Swal.fire({
          title: error,
          showDenyButton: false,
          showCancelButton: true,
          confirmButtonText: "Yes",
          showConfirmButton: false,
          denyButtonText: "No",
          customClass: {
            actions: "my-actions",
            cancelButton: "order-1 right-gap",
            confirmButton: "order-2",
            denyButton: "order-3",
          },
        });
      }
    } catch (error) {
      console.log(">>>>>>..", error);
      Swal.close();
      Swal.fire({
        title: error.response.data.errorMessage ? error.response.data.errorMessage.code : "SERVER ERROR",
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: "Yes",
        showConfirmButton: false,
        denyButtonText: "No",
        customClass: {
          actions: "my-actions",
          cancelButton: "order-1 right-gap",
          confirmButton: "order-2",
          denyButton: "order-3",
        },
      });
    }
  };

  const filter_service = (service) => {
    let service2 = []
    for (let x in service) {
      if (x < 3) {
        service2.push(service[x])

      }
    }
    return service2.join(' . ')



  }

  const filter_service2 = (service) => {
    let service2 = []
    for (let x in service) {
      if (x < 3) {
        service2.push(service[x].name)

      }
    }
    return service2.join(' . ')
  }




  const proceed_to_timeslot = () => {
    if (artist_select) {


      seletedFoo2(artist_select,value)
      setanimationIn("fadeInUp");
      setOutAnimation("fadeOutDown");
      setDivdetails(false);
      setTimeout(() => {
        setDivdetails(true);
        document.getElementById("BackTwo").style.display = "none";
        document.getElementById("BackTwoHalf").style.display = "block";
        // no need hide just show 
        document.getElementById("Artist_Time2").style.display = "flex";
        document.getElementById("Artist_Time").style.display = "none";
        // document.getElementById("proceedfirsthalf").style.display = "none";

        document.getElementsByClassName("currentNav4")[0].style.display = "flex";

      }, 300);



    } else {
      toast.error('Select Artist')

    }


  }



  return (
    <>
      {(loading || share_loading) ? (
        <div className="loadingOpac" id="loadingOpac">
          <img src={require("../assets/loading.gif")} className="loadingIMG" />
        </div>
      ) : (
        ""
      )}

      {/* Navbar Component  */}

      <Navbar Login={show_near} />
      <ToastContainer />

      {/* Slider Component  */}

      {/* <div>
        <div
          id="carouselExampleControls"
          className="carousel slide"
          data-bs-ride="carousel"
          style={{ zIndex: -1 }}
        >
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                src="https://sb-photography.be/wp-content/uploads/slide-1-1600x680.jpg"
                className="d-block w-100 imgsid"
                alt="Cruuz Slider image"
              />
            </div>
            <div className="carousel-item">
              <img
                src="https://media.self.com/photos/5fe1235b09bd6aa8ef12d7fb/2:1/w_1280,c_limit/massage_body.jpeg"
                className="d-block w-100 imgsid"
                alt="Cruuz Slider image2"
              />
            </div>
            <div className="carousel-item">
              <img
                src="https://wpbingosite.com/wordpress/foden/wp-content/uploads/revslider/slider-baber/slide-baber-3.jpg"
                className="d-block w-100 imgsid"
                alt="Cruuz Slider image3"
              />
            </div>
          </div>
        </div>
      </div> */}
      {/* Overlay on Slider Compnent */}
      {/* <div className="overlaySlider"></div> */}

      {/* Daily Deals On Slider Compnent */}
      {/* Daily Deals  */}
      {/* <div className="catagries_divw ">
        <div className="heading_main_map">
          <font className="heading_main_map_font22 text-white"></font>
        </div>

        <Carousel
          itemclassName="image-item"
          responsive={responsive1}
        >
          {arr.map((post, indx) => {
            return (
              <div
                className="mainRecommendedCard cursor-pointer"
                key={indx}
                data-aos="zoom-in-left"
                data-aos-duration="1500"
                data-aos-once="true"
                onClick={() => openModaldetails()}
              >
                <img
                  style={{ height: 130, borderRadius: 3, width: 155 }}
                  src={post.image}
                  alt="Loading."
                />

                <div
                  style={{
                    width: 150,
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "space-around",
                    flexDirection: "column",
                    paddingLeft: 7,
                  }}
                  className="overflowT"
                >
                  <h6 className="postTitle2 ">{post.title}</h6>

                  <font
                    style={{
                      fontSize: 14,
                      fontWeight: "500",
                      color: "red",
                    }}
                  >
                    {post.status}
                  </font>
                </div>
                <div className="absolute top-2 right-1 py-1 px-2 bg-white rounded-lg">
                  <span
                    className="text-md"
                    style={{
                      fontSize: 13,
                      fontWeight: "bold",
                      color: "red",
                    }}
                  >
                    10%
                    <span
                      className="text-md ml-1"
                      style={{
                        fontSize: 11,
                        fontWeight: "bold",
                        color: "red",
                      }}
                    >
                      OFF
                    </span>
                  </span>
                </div>
              </div>
            );
          })}
        </Carousel>
      </div> */}

      {/* Recommended */}

      {/* <div className="catagries_div">
        <div className="heading_main_map">
          <font className="heading_main_map_font22 text-2xl ml-2">
            Recommended
          </font>
        </div>


        <Carousel responsive={responsive}>
          {arr.map((v, index) => {
            return (
              <div
                className="flex justify-center items-center cursor-pointer"
                onClick={() => openModaldetails()}
              >
                <div className="container flex justify-center">
                  <div className="width pt-3 pb-3">
                    <div className="bg-white relative   transition duration-500 rounded-lg shoadowHover">
                      <img className="slider_image" src={v.image} alt="" />
                      <div className="py-3 px-3 rounded-lg bg-white slider_Card">
                        <h1 className="text-gray-700 font-bold text-2xl mb-1 hover:text-gray-900 hover:cursor-pointer hFix">
                          {v.title}
                        </h1>
                        <div className="price">
                          100 mi .
                          <font
                            style={{
                              fontSize: 16,
                              fontWeight: "500",
                              color: "red",
                            }}
                          >
                            {v.status}
                          </font>
                        </div>
                      </div>
                     
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Carousel>
      </div> */}
      <div className="inputmapBox1 mt-3 mb-0 pl-3" >
        <FiChevronLeft size={25} color={"#13242a"} onClick={() => localStorage.getItem('Selected_location') ? navigate("/Categories") : navigate("/map")} />



        <input
          placeholder="Enter what you need?"
          className=" mapsearchbar fontPopin bg-white"
          value={showLabel ? searched_Label : Searched_value}
          onChange={(e) => setSearched_Value(e.target.value)}
          onFocus={() => {
            setShowLabel(false)
          }}

        />
      </div>


      {/* Nearby */}
      <div className="catagries_div pb-5">
        {/* <div className="heading_main_map">
          <font className="heading_main_map_font22 text-2xl ml-2">Nearby</font>
        </div> */}
        <div className="cateogories-buttons-home d-flex flex-row align-items-center justify-content-start ">



          <div
            onClick={() => setSelected(['All'])}
            className={selected.includes('All') ? "categories-button-selected" : "categories-button-simple"}
          >
            All
          </div>


          <div onClick={() => setSelected(['Prefrence'])} className={selected.includes('Prefrence') ? "categories-button-selected" : "categories-button-simple"}
          >
            Prefrence
          </div>
        </div>
        <div className="mainFlex">
          {show_near ? (
            <>
              {near_by_filtered.map((v, i) => {
                return (
                  <div className="newCard" onClick={() => openModaldetails(v.shopId)}>
                    <img className="newImageBox" src={typeof v.shopphotos != undefined ? v.shopphotos[0] : ""} alt="" />
                    <div className="divTextBox mt-2">
                      <div className="newtextbox">
                        <span className="mainBarC fontPopin">
                          <AiFillStar className="mlch" color="#13242a" size={14} />{v.shoprating > 0 ? v.shoprating : '5.0'} {" "}({v.shoptotalratings > 0 ? v.shoptotalratings : 1})  . {(v.distance / 1000).toFixed(1)} mi
                        </span>
                        <p className='fontPopin' >{v.name}</p>


                        <span className="text-slate-500 text-sm oneliner">

                          {filter_service(v.servicedata)}
                          {v.servicedata && v.servicedata.length < 3 ? v.servicedata.length == 0 ? "Instant Booking" : " . Instant Booking" : ''}

                        </span>


                      </div>

                      {/* <button className="newboxButton">Book Now</button> */}
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <></>
          )}

          {console.log(show_near && near_by_filtered.length, show_near, near_by_filtered.length)}
          {show_near && near_by_filtered.length == 0 ?

            <div className="no-Search">
              <img src={require('../assets/nosearch.png')} />

              <p className="noSearchHeading">
                Ups!... no results found


              </p>

              <p className="nosearchpara">
                Please try another search
              </p>
            </div>
            :
            ''
          }





          {/* {arr.map((v, i) => {
            return (
              <div className="newCard">
                <img className="newImageBox" src={v.image} alt="" />
                <div className="divTextBox">
                  <div className="newtextbox">
                    <p>{v.title}</p>
                    <span>
                      <MdLocationPin size={18} style={{ marginLeft: -4 }} />
                      2.1 mi . In Home
                    </span>
                  </div>

                  <button className="newboxButton">Book Now</button>
                </div>
              </div>
            );
          })} */}
        </div>
      </div>

      <Modal
        isOpen={modalIsOpendetails}
        onRequestClose={closeModaldetails}
        // style={customStyles}
        contentLabel="Example Modal"
        className={"modal3"}
      >
        <Animated
          animationIn={animationIn}
          animationOut={outanimation}
          isVisible={divdetails}
        // isVisible={true}
        >
          <div className="modaling" style={{ width: "100%", height: "100vh", margin: "0%" }}>
            <div className="popup1">
              <div
                className="closeIcon"
                id="closeOne"
                onClick={() => {
                  closeModaldetails();
                  resetStates();
                  setanimationIn("fadeInUp");
                  setOutAnimation("fadeOutDown");
                }}
              >





                <img src={require('../assets/close.png')} />
              </div>

              <RWebShare
                data={{
                  text: "Hi! Check Out this.",
                  url: window.location.href.includes('&Item') ? window.location.href : `${window.location.href + '&Item' + shop_id}`,
                  title: "Flamingos",
                }}
                onClick={() => console.log("shared successfully!")}
              >
                <div
                  className="backarrows"
                  id="BackOne"
                >

                  <img src={require('../assets/backincon.png')} />



                </div>
              </RWebShare>
              {console.log(window.location.href.includes('&Item') ? window.location.href : `${window.location.href + '&Item' + shop_id}`)}
              {/* <RWebShare
                data={{
                  text: "Hi! Check Out this.",
                  url: window.location.href.includes('&Item') ? window.location.href : `${window.location.href + '&Item' + shop_id}`,
                  title: "Flamingos",
                }}
                onClick={() => console.log("shared successfully!")}
              > */}

              <div
                className="backarrows1"
                onClick={
                  () => {
                    if (JSON.parse(localStorage.getItem('user_location')).length === 0 || localStorage.getItem('user_location') == null) {
                      
                      if (window.location.href.includes('&Item')) {

                         let refred = window.location.href.slice(window.location.href.indexOf('&Item'),window.location.href.indexOf("?")==-1?window.location.href.length:window.location.href.indexOf("?"));

                        localStorage.setItem("RefredUrl", refred)
                        navigate('/map')
                        return
                      }
                    }
                      else{

                        open_google_map(shop_data.shop$.location)
                      }
                  }
                }
              >

                <img src={require('../assets/arrow.png')} />



              </div>
              {/* </RWebShare> */}
              <div
                className="closeIcon"
                id="closeTwo"
                onClick={() => {
                  alert('kjj')
                  // closeModaldetails();
                  resetStates();
                  setanimationIn("fadeInUp");
                  setOutAnimation("fadeOutDown");
                }}
              >
                <img src={require('../assets/close.png')} />
              </div>
              <RWebShare
                data={{
                  text: "Hi! Check Out this.",
                  url: window.location.href.includes('&Item') ? window.location.href : `${window.location.href + '&Item' + shop_id}`,
                  title: "Flamingos",
                }}
                onClick={() => console.log("shared successfully!")}
              >
                <div
                  className="backarrows"
                  id="BackTwo"
                  onClick={() => {
                    // closeModal2();
                  }}
                >

                  <img src={require('../assets/backincon.png')} />



                </div>
              </RWebShare>
              <RWebShare
                data={{
                  text: "Hi! Check Out this.",
                  url: window.location.href.includes('&Item') ? window.location.href : `${window.location.href + '&Item' + shop_id}`,
                  title: "Flamingos",
                }}
                onClick={() => console.log("shared successfully!")}
              >
                <div
                  className="backarrows"
                  id="BackTwoHalf"
                  onClick={() => {
                    // closeModal2half();
                  }}
                >
                  <img src={require('../assets/backincon.png')} />


                </div>
              </RWebShare>

              <RWebShare
                data={{
                  text: "Hi! Check Out this.",
                  url: window.location.href.includes('&Item') ? window.location.href : `${window.location.href + '&Item' + shop_id}`,
                  title: "Flamingos",
                }}
                onClick={() => console.log("shared successfully!")}
              >
                <div
                  className="backarrows"
                  id="BackThree"
                  onClick={() => {
                    // closeModal3();
                  }}
                >
                  <img src={require('../assets/backincon.png')} />

                </div>
              </RWebShare>
              <div
                className="closeIcon"

                id="closeThree "
                onClick={() => {
                  closeModaldetails();
                  resetStates();
                  setanimationIn("fadeInUp");
                  setOutAnimation("fadeOutDown");
                }}
              >
                <img src={require('../assets/close.png')} />

              </div>
              <div className="content1">
                <section id="Item_details" className="text-gray-700 body-font overflow-hidden bg-white ">
                  <div className=" d-flex flex-wrap align-items-start flex-row justify-content-start classimageDiv">
                    <img alt="ecommerce" className="item_detailImage  object-cover object-center " src={shop_data.shop$ ? shop_data.shop$.photos[0] : ""} />

                    <div className="item_detailImage1">
                      <img
                        alt="ecommerce"
                        className="item_detailImage2 brt1  object-cover object-center  "
                        src={shop_data.shop$ ? (shop_data.shop$.photos[1] ? shop_data.shop$.photos[1] : shop_data.shop$.photos[0]) : ""}
                      />
                      <img
                        alt=""
                        className="item_detailImage2 pt-1 brt2 object-cover object-center "
                        src={shop_data.shop$ ? (shop_data.shop$.photos[2] ? shop_data.shop$.photos[2] : shop_data.shop$.photos[0]) : ""}
                      />
                    </div>
                  </div>
                  <div style={{ width: "98%" }} className="w-full container lg:w-1/2  d-flex align-items-start justify-content-start pt-1 pb-2">
                    <div className="w-full d-flex flex-wrap align-items-start flex-column justify-content-start">

                      <div className="  mt-2 d-flex align-items-start justify-content-start flex-column w-full">
                        <div className="d-flex w-full align-items-start justify-content-between flex-row">
                          <div className="headingWid">
                            <span className="headingdetails mt-2">{shop_data.shop$ != undefined ? shop_data.shop$.shopName : ""}</span>
                            <div className="flex mb-0 -mt-1  flex-wrap" >
                              <span className="flex items-center justify-content-start span400">
                                <BsFillStarFill className="StarRating" />

                                <span className="mainBarC ml-0 text-xs span400">
                                  {shop_data.shop$ != undefined ? (shop_data.ratingObj ? (shop_data.ratingObj.shoprating ? shop_data.ratingObj.shoprating.toFixed(1) : "5.0") : "") : ""}
                                </span>
                                <span className="mainBarC text-xs  ml-1 span400">
                                  ({shop_data.shop$ != undefined ? shop_data.ratingObj.totalratings == 0 ? 1 : shop_data.ratingObj.totalratings : ""})</span>
                              </span>
                              <span className="mainBarC text-xs  ml-1 span400">
                                . 2.1 mi
                              </span>
                            </div>
                            <h1 className="mb-2  locationDetails mt-1">
                              {shop_data.services$ && filter_service2(shop_data.services$)}

                              {shop_data.services$ && shop_data.services$.length < 3 ? shop_data.services$.length == 0 ? "Instant Booking" : " . Instant Booking" : ''}
                            </h1>

                          </div>
                          <IoChatbubblesSharp size={35} className="chatIcon" color="13242a" />
                        </div>
                      </div>





                      {/* <div className="flex align-center">
                          <span className="flex mr-3 pr-3 py-2 border-r-2 border-gray-200 align-center">
                            <div
                              style={{
                                backgroundColor: "rgba(211, 210, 211, 0.44)",
                                borderRadius: 100,
                                padding: 5,
                                cursor: "pointer",
                              }}
                            >
                              <IoLocationOutline size={30} color="#13242A" />
                            </div>
                          </span>

                          <span className="title-font font-medium text-2xl text-gray-900 mt-3">
                            $58.00
                          </span>
                        </div> */}
                    </div>
                  </div>
                </section>
                <div className="shadowSh1 bg-red-300" id="shadow_Item_details"></div>

                <div className="mainDivTop  justify-content-start" id="buttonsResponsive">
                  {/* <div className="heading_main_map"> */}
                  <font
                    className={selected_product.includes(0) ? "heading_btn ml-3 mt-3" : "heading_btn_inactive ml-3 mt-3"}
                    onClick={() => {
                      setSelected_product([]);
                      setSelected_product([0]);
                      document.getElementById("PRoducts").style.display = "none";
                      document.getElementById("services").style.display = "flex";
                    }}
                  >
                    Services{" "}
                  </font>
                  {/* </div> */}
                  {/* <div className="heading_main_map"> */}
                  <font
                    className={selected_product.includes(1) ? "heading_btn ml-4 mt-3" : "heading_btn_inactive ml-4 mt-3"}
                    onClick={() => {
                      setSelected_product([]);
                      setSelected_product([1]);
                      document.getElementById("PRoducts").style.display = "flex";
                      document.getElementById("services").style.display = "none";
                    }}
                  >
                    Products
                  </font>
                </div>

                <div className="d-flex align-items-start justify-content-between flex-wrap mainFirstBox ">
                  <div className="modaling1">
                    <div className="mainDivTop mb-2" id="mainHeadings">
                      <div className="heading_main_map">
                        <font className="heading_main_map_font22 text-2xl">Services </font>
                      </div>
                      <div className="heading_main_map">
                        <font className="heading_main_map_font22 text-2xl">Products </font>
                      </div>
                    </div>

                    <div className="mainDivTop -mt-3">
                      <div id="services" className="myPRoductdiv">
                        {shop_data.services$ ? (
                          <div className="mainFlexCol">
                            {shop_data.shop$.category == "shop" ? (
                              <>
                                {shop_data.services$.map((v0, i0) =>
                                  v0.subcategories.map((v, i) => {
                                    return (
                                      <>
                                        {v.available_inhouse ? (
                                          <>
                                            <div
                                              onClick={() => seletedFoo(v, "shop", "shop", v0)}
                                              className={
                                                i == 0
                                                  ? " relative   cursor-pointer  pl-1 pr-1    d-flex flex-row  align-items-center justify-content-around mainBoxing1 "
                                                  : " relative   cursor-pointer  pl-1 pr-1    d-flex flex-row  align-items-center justify-content-around mainBoxing1 mt-1"
                                              }
                                            >
                                              <div className=" d-flex flex-column ml-0 align-item-start  justify-content-start  wid-80">
                                                <h2 className=" fontPopin mainBarC marginTB">{v.name} - In Shop</h2>

                                                <span className="overflow-hidden   margiT">{v.description}</span>
                                              </div>
                                              <div
                                                className="showflex borderBox 	d-flex align-items-center "
                                                id={
                                                  selectedArr.filter((e) => e.serviceId == v._id && e.type2 == "shop").length > 0
                                                    ? // selectedArr.includes(v)
                                                    "hoverShowEff"
                                                    : "simpleefe"
                                                }
                                              >
                                                ${v.price.toFixed(2)}
                                              </div>
                                            </div>

                                            <div
                                              onClick={() => seletedFoo(v, "home", "shop", v0)}
                                              className={
                                                i == 0
                                                  ? " relative   cursor-pointer  pl-1 pr-1    d-flex flex-row  align-items-center justify-content-around mainBoxing1 "
                                                  : " relative   cursor-pointer  pl-1 pr-1    d-flex flex-row  align-items-center justify-content-around mainBoxing1 mt-1"
                                              }
                                            >
                                              <div className=" d-flex flex-column ml-0 align-item-start  justify-content-start  wid-80">
                                                <h2 className="fontPopin mainBarC marginTB">{v.name} - In Home</h2>

                                                <span className="overflow-hidden   margiT">{v.description}</span>
                                              </div>
                                              <div
                                                className="showflex borderBox 	d-flex align-items-center "
                                                id={
                                                  selectedArr.filter((e) => e.serviceId == v._id && e.type2 == "home").length > 0
                                                    ? // selectedArr.includes(v.type='home')
                                                    "hoverShowEff"
                                                    : "simpleefe"
                                                }
                                              >
                                                ${v.priceinhouse.toFixed(2)}
                                              </div>
                                            </div>
                                          </>
                                        ) : (
                                          <div
                                            onClick={() => seletedFoo(v, "shop", "shop", v0)}
                                            className={
                                              i == 0
                                                ? " relative   cursor-pointer  pl-1 pr-1    d-flex flex-row  align-items-center justify-content-around mainBoxing1 "
                                                : " relative   cursor-pointer  pl-1 pr-1    d-flex flex-row  align-items-center justify-content-around mainBoxing1 mt-1"
                                            }
                                          >
                                            <div className=" d-flex flex-column ml-0 align-item-start  justify-content-start  wid-80">
                                              <h2 className=" fontPopin mainBarC marginTB">{v.name} - In Shop</h2>

                                              <span className="overflow-hidden   margiT">{v.description}</span>
                                            </div>
                                            <div
                                              className="showflex borderBox 	d-flex align-items-center "
                                              id={
                                                selectedArr.filter((e) => e.serviceId == v._id && e.type2 == "shop").length > 0
                                                  ? // selectedArr.includes(v)
                                                  "hoverShowEff"
                                                  : "simpleefe"
                                              }
                                            >
                                              ${v.price.toFixed(2)}
                                            </div>
                                          </div>
                                        )}
                                      </>
                                    );
                                  })
                                )}
                              </>
                            ) : (
                              // for independent Artist
                              <>
                                {shop_data.services$.map((v0, i0) =>
                                  v0.subcategories.map((v, i) => {
                                    return (
                                      <>
                                        {v.available_inhouse ? (
                                          <div
                                            onClick={() => seletedFoo(v, "home", "ind_artist", v0)}
                                            className={
                                              i == 0
                                                ? " relative   cursor-pointer  pl-1 pr-1    d-flex flex-row  align-items-center justify-content-around mainBoxing1 "
                                                : " relative   cursor-pointer  pl-1 pr-1    d-flex flex-row  align-items-center justify-content-around mainBoxing1 mt-1"
                                            }
                                          >
                                            <div className=" d-flex flex-column ml-0 align-item-start  justify-content-start  wid-80">
                                              <h2 className=" fontPopin mainBarC marginTB">{v.name} - In Home</h2>

                                              <span className="overflow-hidden   margiT">{v.description}</span>
                                            </div>
                                            <div
                                              className="showflex borderBox 	d-flex align-items-center "
                                              id={
                                                selectedArr.filter((e) => e.serviceId == v._id && e.type2 == "home").length > 0
                                                  ? // selectedArr.includes(v)
                                                  "hoverShowEff"
                                                  : "simpleefe"
                                              }
                                            >
                                              ${v.priceinhouse.toFixed(2)}
                                            </div>
                                          </div>
                                        ) : (
                                          <div
                                            onClick={() => seletedFoo(v, "shop", "ind_artist", v0)}
                                            className={
                                              i == 0
                                                ? " relative   cursor-pointer  pl-1 pr-1    d-flex flex-row  align-items-center justify-content-around mainBoxing1 "
                                                : " relative   cursor-pointer  pl-1 pr-1    d-flex flex-row  align-items-center justify-content-around mainBoxing1 mt-1"
                                            }
                                          >
                                            <div className=" d-flex flex-column ml-0 align-item-start  justify-content-start  wid-80">
                                              <h2 className=" fontPopin mainBarC marginTB">{v.name} - In Shop</h2>

                                              <span className="overflow-hidden   margiT">{v.description}  </span>
                                            </div>
                                            <div
                                              className="showflex borderBox 	d-flex align-items-center "
                                              id={
                                                selectedArr.filter((e) => e.serviceId == v._id && e.type2 == "shop").length > 0
                                                  ? // selectedArr.includes(v.type='home')
                                                  "hoverShowEff"
                                                  : "simpleefe"
                                              }
                                            >
                                              ${v.price.toFixed(2)}
                                            </div>
                                          </div>
                                        )}
                                      </>
                                    );
                                  })
                                )}
                              </>
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>

                      <div id="PRoducts" className=" myPRoductdiv shoadowBox">
                        {shop_data.product$ ? (
                          <div className="mainFlexCol">
                            {shop_data.product$.map((v00, i00) =>
                              v00.subcategories.map((v, i) => {
                                return (
                                  <div
                                    onClick={() => seletedFoo1(v)}
                                    className={
                                      i == 0
                                        ? " relative   cursor-pointer  pl-1 pr-1    d-flex flex-row  align-items-center justify-content-around mainBoxing1 "
                                        : " relative   cursor-pointer  pl-1 pr-1    d-flex flex-row  align-items-center justify-content-around mainBoxing1 mt-1"
                                    }
                                  >
                                    <div className=" d-flex flex-column ml-0 align-item-start  justify-content-start  wid-80">
                                      <h2 className=" fontPopin mainBarC marginTB">{v.name}</h2>

                                      <span className="overflow-hidden   margiT">{v.details}</span>
                                    </div>
                                    <div className="showflex borderBox 	d-flex align-items-center  " id={selectedArr1.includes(v) ? "hoverShowEff" : "simpleefe"}>
                                      ${v.price.toFixed(2)}
                                    </div>
                                  </div>
                                );
                              })
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className="borderWidit mt-2 mb-3"></div>

                    <div
                      className=" 
          bg-white        
         flex-column
          
          "

                    >
                      <div>




                        <div id="Artist_Time" className=" flex-col">

                          <div className="align-items-start justify-content-start flex-column w-full pl-4 pr-4 headingOptional">
                            <div className="d-flex w-full align-items-start justify-content-between flex-row">
                              <div className="headingWid">
                                <span className="headingdetails mt-2">{shop_data.shop$ != undefined ? shop_data.shop$.shopName : ""}</span>
                                <div className="flex mb-0 -mt-1  flex-wrap" >
                                  <span className="flex items-center justify-content-start span400">
                                    <BsFillStarFill className="StarRating" />

                                    <span className="mainBarC ml-0 text-xs span400">
                                      {shop_data.shop$ != undefined ? (shop_data.ratingObj ? (shop_data.ratingObj.shoprating ? shop_data.ratingObj.shoprating.toFixed(1) : "5.0") : "") : ""}
                                    </span>
                                    <span className="mainBarC text-xs  ml-1 span400">
                                      ({shop_data.shop$ != undefined ? shop_data.ratingObj.totalratings == 0 ? 1 : shop_data.ratingObj.totalratings : ""})</span>
                                  </span>
                                  <span className="mainBarC text-xs  ml-1 span400">
                                    . 2.1 mi
                                  </span>
                                </div>
                                <h1 className="mb-2  locationDetails mt-1">
                                  {shop_data.services$ && filter_service2(shop_data.services$)}
                                  {shop_data.services$ && shop_data.services$.length < 3 ? shop_data.services$.length == 0 ? "Instant Booking" : " . Instant Booking" : ''}

                                </h1>

                              </div>
                              <IoChatbubblesSharp size={35} className="chatIcon" color="13242a" />
                            </div>
                          </div>

                          {/* <p
                          style={{
                            fontSize: 25,
                            fontWeight: "600",
                            color: "black",

                            width: "100%",
                            textAlign: "left",
                          }}
                        >
                          Artist And Time
                        </p> */}
                          <div className="selectionColorMatker">
                            <span
                              className=" mt-2 fontPopin"
                              style={{
                                fontSize: 22,
                                fontWeight: "500",

                                // paddingLeft: 10,
                              }}
                            >
                              Select a Pro
                            </span>
                            {/* <hr  className="mt-1"/> */}
                            {shop_data.artistObj ? (
                              <div className="horizontalView mt-1">


                                <div className={chooseBa ? "horizontalViewdivhover" : "horizontalViewdiv"} onClick={() => chooseBest(chooseBa)} >

                                  <img className="imgDol mt-3 " style={{ width: 40, height: 40 }} src={chooseBa ? require('../assets/whiteSc.png') : require('../assets/blacksc.png')} />
                                  <p className={chooseBa ? " mt-3 text-white font-medium chooseCard" : " mt-3 chooseCard"}>
                                    Choose <br /> a Service
                                  </p>

                                  <p className=" mt-2 chooseCard1 ">
                                    with any Professional
                                  </p>
                                </div>



                                {shop_data.artistObj.map((v, i) => {

                                  return (
                                    <div className={selectedArr2.includes(v) ? "horizontalViewdivhover" : "horizontalViewdiv"} onClick={() => selectartist(v)}>
                                      <img className="imgDol " src={v.artistdata.profilePhoto} />
                                      <span
                                        className=" mt-2 changeSpantext"
                                        style={{
                                          fontSize: 14,
                                          fontWeight: "bold",
                                          textAlign: "center",
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          width: 80,
                                        }}
                                      >
                                        {v.artistdata.name}
                                      </span>
                                      <span
                                        className="text-md changeSpantext1"
                                        style={{
                                          fontSize: 12,
                                          // fontWeight: "500",
                                          textAlign: "center",
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          width: 90,
                                          color: selectedArr2.includes(v) ? "#fff" : "grey",
                                        }}
                                      >
                                        {v.artistdata.bio}
                                      </span>

                                      <div className="underline" ></div>
                                    </div>
                                  );
                                })}



                              </div>
                            ) : (
                              ""
                            )}

                          </div>

                        </div>

                        <div id="Artist_Time2" className="w-100  align-items-start justify-content-between flex-row flexWrapCont">

                          <div className=" align-items-start justify-content-start flex-column w-full pl-4 pr-4 headingOptional">
                            <div className="d-flex w-full align-items-start justify-content-between flex-row">
                              <div className="headingWid">
                                <span className="headingdetails mt-2">{shop_data.shop$ != undefined ? shop_data.shop$.shopName : ""}</span>
                                <div className="flex mb-0 -mt-1  flex-wrap" >
                                  <span className="flex items-center justify-content-start span400">
                                    <BsFillStarFill className="StarRating" />

                                    <span className="mainBarC ml-0 text-xs span400">
                                      {shop_data.shop$ != undefined ? (shop_data.ratingObj ? (shop_data.ratingObj.shoprating ? shop_data.ratingObj.shoprating.toFixed(1) : "5.0") : "") : ""}
                                    </span>
                                    <span className="mainBarC text-xs  ml-1 span400">
                                      ({shop_data.shop$ != undefined ? shop_data.ratingObj.totalratings == 0 ? 1 : shop_data.ratingObj.totalratings : ""})</span>
                                  </span>
                                  <span className="mainBarC text-xs  ml-1 span400">
                                    . 2.1 mi
                                  </span>
                                </div>
                                <h1 className="mb-2  locationDetails mt-1">
                                  {shop_data.services$ && filter_service2(shop_data.services$)}
                                  {shop_data.services$ && shop_data.services$.length < 3 ? shop_data.services$.length == 0 ? "Instant Booking" : " . Instant Booking" : ''}

                                </h1>

                              </div>
                              <IoChatbubblesSharp size={35} className="chatIcon" color="13242a" />
                            </div>
                          </div>


                          <div className="w-full selectionColorMatker d-flex ">
                            <div className="widthContrl" id="SelectTime">
                              <span
                                className=" fontPopin"
                                style={{
                                  fontSize: 22,
                                  fontWeight: "500",
                                  width: "100%",
                                  textAlign: "start",
                                  color: '#13242a',
                                  marginTop: 10
                                }}
                              >
                                Select a Time
                              </span>
                              {/* <Calendar
                                formatShortWeekday={(locale, value) => [ 'S','M', 'T', 'W', 'T', 'F', 'S'][value.getDay()]}    
                                formatMonthYear={(locale, value) => [ 'Jan' + " "+ value.getFullYear(),'Feb'+ " "+ value.getFullYear(), 'Mar'+ " "+ value.getFullYear(), 'Apr'+ " "+ value.getFullYear(), 'May'+ " "+ value.getFullYear(), 'Jun'+ " "+ value.getFullYear(), 'Jul'+ " "+ value.getFullYear(),'Aug'+ " "+ value.getFullYear(),'Sep'+ " "+ value.getFullYear(),'Oct'+ " "+ value.getFullYear(),'Nov'+ " "+ value.getFullYear(),'Dec'+ " "+ value.getFullYear()][value.getMonth()]} 
                                calendarType= 'US'
                                // selectRange
                                onChange={(e) => {
                                  onChange(e);
                                  seletedFoo2(artist_select)
                                  console.log(e);
                                }}
                                value={value}
                              /> */}


                              <div style={{ width: '100%' }}>

                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                  <StaticDatePicker

                                    displayStaticWrapperAs="desktop"
                                    openTo="day"
                                    disableHighlightToday
                                    value={value1}
                                    onChange={(newValue) => {
                                      setValue1(newValue)
                                      onChange(newValue.toDate());
                                      seletedFoo2(artist_select,newValue.toDate())
                                    }}
                                    renderInput={(params) => <TextField size='medium' {...params} />}
                                  />
                                </LocalizationProvider>
                              </div>
                            </div>

                            <div className="d-flex w-full flex-col">
                              <span className=" Alltime">All Available Time</span>

                              <div className="d-flex align-items-end justify-content-end">
                                <span
                                  className="text-md mt-0  fontPopin"
                                  style={{
                                    fontSize: 13,
                                    fontWeight: "500",
                                    color: "grey",
                                    textTransform: 'capitalize'
                                  }}
                                >
                                  Morning
                                </span>
                              </div>

                              <div className="d-flex flex-row flex-wrap align-items-center justify-content-start ml-1 flexConr">
                                {time_slot.map((v, i) => {
                                  if (time >= i) {
                                    return (
                                      <div
                                        onClick={() => seletedFoo3(v)}
                                        className={selectedArr3.includes(v) ? "horizontalViewdiv1 mt-1 shadow-md ml-2 hoverThisUP" : "horizontalViewdiv1 mt-1 shadow-md ml-2 "}
                                      >
                                        {v.timeslot}

                                        {v.label}
                                      </div>
                                    );
                                  }
                                })}
                              </div>


                              {seeMore ? (
                                <div
                                  onClick={() => {
                                    setTime(10);
                                    setseeMore(false);
                                  }}
                                  className="w-full d-flex align-items-end justify-content-end mb-2 pb-3 mt-1"
                                >
                                  <h4 className="clearAll text-sm mr-3">See Less...</h4>
                                </div>
                              ) : (
                                <div
                                  onClick={() => {
                                    setTime(time_slot.length);
                                    setseeMore(true);
                                  }}
                                  className="w-full d-flex align-items-end justify-content-end mb-2 pb-3 mt-1"
                                >
                                  <h4 className="clearAll text-sm mr-3">See More...</h4>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>


                      </div>
                    </div>
                  </div>
                  <StickyBox className="stickyBox flex-col" offsetTop={0} offsetBottom={10}>

                    <div className="align-items-start justify-content-start flex-column w-full pl-4 pr-4 headingOptional mt-2.5" >
                      <div className="d-flex w-full align-items-start justify-content-between flex-row">
                        <div className="headingWid">
                          <span className="headingdetails mt-2">{shop_data.shop$ != undefined ? shop_data.shop$.shopName : ""}</span>
                          <div className="flex mb-0 -mt-1  flex-wrap" >
                            <span className="flex items-center justify-content-start span400">
                              <BsFillStarFill className="StarRating" />

                              <span className="mainBarC ml-0 text-xs span400">
                                {shop_data.shop$ != undefined ? (shop_data.ratingObj ? (shop_data.ratingObj.shoprating ? shop_data.ratingObj.shoprating.toFixed(1) : "5.0") : "") : ""}
                              </span>
                              <span className="mainBarC text-xs  ml-1 span400">
                                ({shop_data.shop$ != undefined ? shop_data.ratingObj.totalratings == 0 ? 1 : shop_data.ratingObj.totalratings : ""})</span>
                            </span>
                            <span className="mainBarC text-xs  ml-1 span400">
                              . 2.1 mi
                            </span>
                          </div>
                          <h1 className="mb-2  locationDetails mt-1">
                            {shop_data.services$ && filter_service2(shop_data.services$)}
                            {shop_data.services$ && shop_data.services$.length < 3 ? shop_data.services$.length == 0 ? "Instant Booking" : " . Instant Booking" : ''}

                          </h1>

                        </div>
                        <IoChatbubblesSharp size={35} className="chatIcon" color="13242a" />
                      </div>
                    </div>

                    <div className="selectionColorMatker ">
                      <div className="receiption " id="stickyBar">
                        <p

                          className="ml-1 paymentInfo "
                        >
                          Payment information
                        </p>

                        <div className="d-flex flex-row w-full align-items-end justify-content-between bg-red mt-3">
                          <p
                            className="text-sm ml-1 text-black fontPopin"
                            style={{
                              fontWeight: '700',
                              fontSize: '0.9em'

                            }}
                          >
                            Add a tip
                          </p>



                          <p
                            className="text-sm ml-2 fontPopin "
                            style={{ fontSize: '0.93em', color: 'grey' }}
                          >
                            20% (~20.00$)
                          </p>
                        </div>

                        <div className=" w-full d-flex flex-row align-items-center justify-content-between flex-wrap borderboxJus pb-3 pt-2">
                          {[5, 10, 15, 20, 25, 30].map((v, i) => {
                            return (
                              <div
                                onClick={() => seletedFoo4(v)}
                                className={selectedArr4.includes(v) ? "horizontalViewdiv2 mt-1 ml-1 horizontalViewdiv2Select" : "horizontalViewdiv2 mt-1 ml-1"}
                              >
                                {v}%
                              </div>
                            );
                          })}
                        </div>

                        {/* <p
                        className="text-sm ml-2 mt-2"
                        style={{
                          fontWeight: "500",
                          color: "grey",
                          textAlign: "left",
                          cursor: "pointer",
                          width: "100%",
                        }}
                      >
                        Your Order
                      </p>
                      {selectedArr.map((v, i) => (
                        <div className=" cursor-pointer d-flex flex-row w-full align-items-end justify-content-between borderboxJus mt-1 pb-1 pt-1">
                          <h1 className="text-sm font-bold leading-5 mb-1 ml-1">{v.name}</h1>
                          <div
                            className="showflex borderBox text-xs mb-1 pl-2 pr-2"
                            style={{
                              fontWeight: "bold",
                              color: "#13242A",
                              textAlign: "center",
                              cursor: "pointer",
                            }}
                          >
                            {v.price.toFixed(2)} $
                          </div>
                        </div>
                      ))}

                      {selectedArr1.map((v, i) => (
                        <div className=" cursor-pointer d-flex flex-row w-full align-items-end justify-content-between borderboxJus mt-1 pb-1 pt-1">
                          <h1 className="text-sm font-bold leading-5 mb-1 ml-1">{v.name}</h1>
                          <div
                            className="showflex borderBox text-xs mb-1 pl-2 pr-2"
                            style={{
                              fontWeight: "bold",
                              color: "#13242A",
                              textAlign: "center",
                              cursor: "pointer",
                            }}
                          >
                            {v.price.toFixed(2)} $
                          </div>
                        </div>
                      ))} */}

                        <div className="w-full d-flex flex-row align-items-center justify-content-between mt-4 ">
                          <p
                            className="showflex text-base"
                            style={{
                              fontWeight: "500",
                              color: "grey",
                              textAlign: "center",
                            }}
                          >
                            Sub Total
                          </p>

                          <p className="showflex purpleText font-bold	  text-base">${total_order()}</p>
                        </div>


                        <div className="w-full d-flex flex-row align-items-center justify-content-between mt-2">
                          {/* <p
                            className="showflex text-xs"
                            style={{
                              fontWeight: "500",
                              color: "grey",
                              textAlign: "center",
                            }}
                          >
                            Tip : {selectedArr4.length > 0 ? selectedArr4[0] : 0}%
                          </p> */}

                          {/* <p className="showflex purpleText  text-xs">${selectedArr4.length > 0 ? selectedArr4[0] : 0}</p> */}
                        </div>


                        <div className="w-full d-flex flex-row align-items-center justify-content-between mt-2 ">
                          <p
                            className="showflex text-sm"
                            style={{
                              fontWeight: "500",
                              color: "grey",
                              textAlign: "center",
                            }}
                          >
                            Taxes & Fees
                          </p>

                          <p className="showflex purpleText  text-sm">${total_tax()}</p>
                        </div>


                        <div className="w-full d-flex flex-row align-items-center justify-content-between mt-3">
                          <p
                            className="showflex text-sm"
                            style={{
                              fontWeight: "500",
                              color: "grey",
                              textAlign: "center",
                            }}
                          >
                            Total
                          </p>

                          <p className="showflex purpleText  text-sm">${final_total()}</p>
                        </div>

                        <div className="w-full d-flex flex-row align-items-center justify-content-start mt-2">
                          <p
                            className="showflex text-sm"
                            style={{
                              fontWeight: "500",
                              color: "#5CA3C7",
                              textAlign: "center",

                            }}
                          >
                            Add Promo
                          </p>

                        </div>

                        {/* <div className="w-full d-flex flex-row align-items-center justify-content-between mt-2 pl-1 pr-1">
                        <p
                          className="showflex text-xs"
                          style={{
                            fontWeight: "500",
                            color: "grey",
                            textAlign: "center",
                          }}
                        >
                          Grand Total
                        </p>

                        <p className="showflex purpleText  text-xs">{grand_total()} $</p>
                      </div> */}

                        {/* <div className="w-full d-flex flex-row align-items-center justify-content-between mt-2 pl-1 pr-1">
                        <p
                          className="showflex text-xs"
                          style={{
                            fontWeight: "500",
                            color: "grey",
                            textAlign: "center",
                          }}
                        >
                          Artist
                        </p>
                        {selectedArr2.length == 0 ? (
                          <a onClick={() => (window.screen.width < 1053 ? closeModal3() : window.open("#Artist_Time", "_self"))}>
                            <p className="showflex purpleText text-xs">
                              Select Artist
                              <FaAngleRight className="ml-1 " size={10} />
                            </p>
                          </a>
                        ) : (
                          <p className="showflex purpleText text-xs mr-1">{selectedArr2[0].artistdata.name}</p>
                        )}
                      </div>

                      <div className="w-full d-flex flex-row align-items-center justify-content-between mt-2 pl-1 pr-1">
                        <p
                          className="showflex text-xs"
                          style={{
                            fontWeight: "500",
                            color: "grey",
                            textAlign: "center",
                          }}
                        >
                          Services
                        </p>

                        <p className="showflex purpleText text-xs">
                          Kids
                          <FaAngleRight className="ml-1 " size={10} />
                        </p>
                      </div>

                      <div className="w-full d-flex flex-row align-items-center justify-content-between mt-2 pl-1 pr-1">
                        <p
                          className="showflex text-xs"
                          style={{
                            fontWeight: "500",
                            color: "grey",
                            textAlign: "center",
                          }}
                        >
                          TimeSlot
                        </p>

                        {selectedArr3.length > 0 ? (
                          <p className="showflex purpleText text-xs mr-1">
                            {selectedArr3[0].timeslot}
                            {selectedArr3[0].label}
                          </p>
                        ) : (
                          <a onClick={() => (window.screen.width < 1053 ? closeModalForTimeslot() : window.open("#SelectTime", "_self"))}>
                            <p className="showflex purpleText text-xs">
                              Select TimeSlot
                              <FaAngleRight className="ml-1 " size={10} />
                            </p>
                          </a>
                        )}
                      </div> */}

                        {/* <div className="w-full d-flex flex-row align-items-center justify-content-between mt-2 pl-1 pr-1">
                        <p
                          className="showflex text-xs"
                          style={{
                            fontWeight: "500",
                            color: "grey",
                            textAlign: "center",
                          }}
                        >
                          Add Promo Code
                        </p>

                        <p className="showflex purpleText text-xs">
                          Apply Code
                          <FaAngleRight className="ml-1 " size={10} />
                        </p>
                      </div> */}

                        {/* <div className="w-full d-flex flex-row align-items-center justify-content-between mt-2 pl-1 pr-1">
                        <p
                          className="showflex text-xs"
                          style={{
                            fontWeight: "500",
                            color: "grey",
                            textAlign: "center",
                          }}
                        >
                          Add Payment Method
                        </p>
                        {console.log(">>>>>>>>>>>>>>", number.slice(-4))}
                        <p
                          onClick={() => {
                            openModaldetails1();
                          }}
                          className="showflex purpleText text-xs"
                        >
                          {card_token == "" ? (
                            <>
                              Select Method
                              <FaAngleRight className="ml-1 " size={10} />
                            </>
                          ) : (
                            number.slice(-4)
                          )}
                        </p>
                      </div> */}

                        <p
                          className="text-xs ml-2"
                          style={{
                            fontWeight: "500",
                            color: "red",
                            textAlign: "center",
                            width: "100%",
                            textAlign: "left",
                          }}
                        >
                          {error}
                        </p>

                        {/* <div
                          onClick={() => {
                            // checkvalidity();
                          }}
                          className="w-100 d-flex align-items-center justify-content-center mt-3 mb-2"
                        >
                          <button
                            type="button"
                            className=" paymentButton d-flex flex-row align-items-center justify-content-center text-white bg-black  font-medium rounded-lg   py-2 text-center mr-2 mb-2  shadow-md  fw-600  text-base"

                          >
                            Book With <AiFillApple className="ml-1 mr-1" color="#fff" size={20} /> Pay
                          </button>
                        </div> */}


                        <div className="d-flex orSc align-items-center justify-content-between text-xs text-slate-400">
                          <div className="orSection">

                          </div>
                          OR
                          <div className="orSection">

                          </div>
                        </div>

                        {card_token == '' ?
                          <div
                            onClick={() => {
                              openModaldetails1();
                            }}
                            className="w-100 d-flex align-items-center justify-content-center mt-3"
                          >
                            <button
                              type="button"
                              className=" paymentButton d-flex flex-row align-items-center justify-content-center text-white bg-black  font-medium rounded-lg   py-2.5 text-center mr-2 mb-2  shadow-md  fw-500 "
                            >
                              Book With Card
                            </button>
                          </div>
                          :

                          <div
                            onClick={() => {
                              checkvalidity();
                            }}
                            className="w-100 d-flex align-items-center justify-content-center mt-3"
                          >
                            <button
                              type="button"
                              className=" paymentButton d-flex flex-row align-items-center justify-content-center text-white bg-black  font-medium rounded-lg   py-2 text-center mr-2 mb-2  shadow-md  fw-600  text-base"

                            >
                              Book Now
                            </button>
                          </div>


                        }




                        <p className="text-xs w-full text-center text-slate-500 mb-3">
                          By Booking an Appointment you agree to the
                          <br />
                          <span className="text-black mr-1 cursor-pointer">
                            Term & Conditions
                          </span>
                          and
                          <span className="text-black ml-1 cursor-pointer">
                            Privacy Policy
                          </span>
                        </p>
                      </div>

                    </div>
                  </StickyBox>
                </div>

                {/* <div className="modaling2 " id="mapBox">
                  <div className="shadowSh"></div>
                  <div
                    className="  
          bg-white        
          d-flex flex-row w-100 flex-wrap  align-items-start justify-content-around
         
          "
                  >
                    <p
                      style={{
                        fontSize: 25,
                        fontWeight: "600",
                        color: "black",
                        marginTop: 10,
                        width: "100%",
                        textAlign: "left",
                      }}
                    >
                      Where You Will be
                    </p>

                    <div style={{ width: "100%", marginTop: 15 }}>
                      <iframe
                        width="100%"
                        height="400"
                        frameborder="0"
                        scrolling="no"
                        marginheight="0"
                        marginwidth="0"
                        src="https://maps.google.com/maps?width=100%25&amp;height=400&amp;hl=en&amp;q=1%20Grafton%20Street,%20Dublin,%20Ireland+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                      >
                        <a href="https://www.maps.ie/distance-area-calculator.html">measure distance on map</a>
                      </iframe>
                    </div>
                  </div>
                </div> */}
              </div>

              {selectedArr.length > 0 || selectedArr1.length > 0 ? (
                <div className="w-90 align-items-center justify-content-center mt-1 processButton">
                  <div className="flex space-x-4">
                    <div
                      className="currentNav3 "
                      id="proceedfirst"
                      onClick={() => {
                        setanimationIn("fadeInUp");
                        setOutAnimation("fadeOutDown");
                        setDivdetails(false);
                        setTimeout(() => {
                          setDivdetails(true);
                          // hide Before Data
                          document.getElementById("Item_details").style.display = "none";
                          // document.getElementById(
                          //   "shadow_Item_details"
                          // ).style.display = "none";
                          document.getElementById("buttonsResponsive").style.display = "none";
                          document.getElementsByClassName("myPRoductdiv")[0].style.display = "none";
                          document.getElementsByClassName("myPRoductdiv")[1].style.display = "none";
                          document.getElementById("closeOne").style.display = "none";
                          document.getElementById("BackOne").style.display = "none";

                          document.getElementById("closeTwo").style.display = "block";
                          document.getElementById("BackTwo").style.display = "block";

                          document.getElementById("proceedfirst").style.display = "none";

                          // Show NExt Data
                          document.getElementsByClassName("currentNav3")[0].style.display = "none";
                          document.getElementById("Artist_Time").style.display = "flex";
                          document.getElementById("proceedfirsthalf").style.display = "flex";
                          // document.getElementsByClassName("currentNav4")[0].style.display = "flex";
                          // window.open("#Artist_Time", "_self");
                        }, 300);
                      }}
                    >
                      <MdOutlineShoppingCart size={28} className='ml-1' />

                      <span className="mr-3">Proceed</span>

                      <p className="fontPopin mr-4 ">{selectedArr.length > 0 ? selectedArr.length : ''}</p>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}



              <div className="w-90 align-items-center justify-content-center mt-1 processButton">
                <div className="flex space-x-4">
                  <div
                    className="currentNav5 "
                    id="proceedfirsthalf"
                    onClick={proceed_to_timeslot}>

                    <span>Proceed</span>


                  </div>
                </div>
              </div>




              <div className="w-90 align-items-center justify-content-center processButton" >
                <div className="flex space-x-4">
                  <div
                    className="currentNav5 currentNav4 "
                    onClick={() => {

                      if (selectedArr3.length == 0) {
                        toast.error('Select Time Slot')
                        return
                      }
                      setanimationIn("fadeInUp");
                      setOutAnimation("fadeOutDown");
                      setDivdetails(false);
                      setTimeout(() => {
                        setDivdetails(true);
                        // hide Before Data
                        document.getElementsByClassName("currentNav4")[0].style.display = "none";
                        document.getElementById("closeTwo").style.display = "none";
                        document.getElementById("BackTwoHalf").style.display = "none";
                        document.getElementById("Artist_Time").style.display = "none";
                        document.getElementById("Artist_Time2").style.display = "none";
                        document.getElementById("proceedfirsthalf").style.display = "none";
                        document.getElementsByClassName("content1")[0].style.backgroundImage = 'linear-gradient(#fff, #f2f2f6)'


                        // Show NExt Data

                        document.getElementsByClassName("stickyBox")[0].style.display = "flex";
                        document.getElementById("BackThree").style.display = "block";
                        // window.open("#stickyBar", "_self");
                      }, 300);
                    }}
                  >
                    Proceed
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Animated>
      </Modal>

      <Modal isOpen={modalIsOpendetails1} onRequestClose={closeModaldetails} contentLabel="Example Modal" className={"modal3"}>
        <Animated animationIn={animationIn} animationOut={outanimation} isVisible={divdetails1}>
          <div className="modaling paymentModal">
            <div className=" popupres popup1 ">
              <div
                className="closeIcon"
                onClick={() => closeModaldetails1()}
              >
                <img src={require('../assets/close.png')} />

              </div>
              {/* <IoCloseOutline
                style={{
                  position: "absolute",
                  top: 10,
                  right: 10,
                  cursor: "pointer",
                }}
                size={25}
                color="#13242A"
              /> */}

              {/* <IoChevronBack
                onClick={() => {
                  closeModaldetails1();
                  setIsOpendetails(true);
                  setDivdetails(true);
                }}
                style={{
                  position: "absolute",
                  top: 15,
                  left: 13,
                  cursor: "pointer",
                }}
                size={30}
                color="#13242A"
              /> */}

              <div className="headCenter">
                <h1 className=" font-bold  mb-4 text-xl">Add Payment Method</h1>
              </div>

              <Cards cvc={csv} expiry={expiry} name={name} number={number} focused={`${focus}`} />

              <div className="flex lookBox row cardInputs">
                <div className="relative rounded-md p-3 hover:bg-gray-100 cursor-pointer shadow p-3  bg-white rounded d-flex align-center justify-content-between ">
                  <font
                    style={{
                      fontSize: 14,
                      fontWeight: "500",
                      color: "#13242A",
                    }}
                  >
                    Name
                  </font>
                  <input
                    onFocus={() => {
                      setFocus("name");
                    }}
                    onChange={(e) => setName(e.target.value)}
                    className="creditInput"
                  />
                </div>
                <div className="relative rounded-md p-3 hover:bg-gray-100 cursor-pointer shadow p-3  bg-white rounded d-flex align-center justify-content-between">
                  <font
                    style={{
                      fontSize: 14,
                      fontWeight: "500",
                      color: "#13242A",
                    }}
                  >
                    Number
                  </font>
                  <input
                    maxLength={16}
                    onFocus={() => {
                      setFocus("number");
                    }}
                    onChange={(e) => setNumber(e.target.value)}
                    className="creditInput"
                  />
                </div>
                <div className="relative rounded-md p-3 hover:bg-gray-100 cursor-pointer shadow p-3  bg-white rounded d-flex align-center justify-content-between">
                  <font
                    style={{
                      fontSize: 14,
                      fontWeight: "500",
                      color: "#13242A",
                    }}
                  >
                    Date
                  </font>
                  <input
                    maxLength={6}
                    onFocus={() => {
                      setFocus("expiry");
                    }}
                    onChange={(e) => setexpiry(e.target.value)}
                    className="creditInput"
                  />
                </div>
                <div className="relative rounded-md p-3 hover:bg-gray-100 cursor-pointer shadow p-3  bg-white rounded d-flex align-center justify-content-between" style={{ width: 300 }}>
                  <font
                    style={{
                      fontSize: 14,
                      fontWeight: "500",
                      color: "#13242A",
                    }}
                  >
                    CVC
                  </font>

                  <input
                    maxLength={4}
                    className="creditInput"
                    onFocus={() => {
                      setFocus("cvc");
                    }}
                    onChange={(e) => {
                      setCsv(e.target.value);
                    }}
                  />
                </div>
                
                <div className="relative rounded-md p-3 hover:bg-gray-100 cursor-pointer shadow p-3  bg-white rounded d-flex align-center justify-content-between" style={{ width: 300 }}>
                  <font
                    style={{
                      fontSize: 14,
                      fontWeight: "500",
                      color: "#13242A",
                    }}
                  >
                    Contact No
                  </font>
                  <input
                    // defaultValue={'+'+1}
                    value={PhoneNumber}
                    maxLength={12}
                    onChange={(e)=>{
                      console.log(e.target.value.toString(),e.target.value);
                      if (e.target.value.startsWith("+1")) {
                        
                        setPhoneNumber(e.target.value)
                        
                      }
                    }
                  }
                    placeholder="(801) 123 12321"        
                    className="creditInput"
                    
                   
                  />
                </div>
              </div>

              <div
                onClick={() => {
                  check_out();
                }}
                className="w-100 d-flex align-items-center justify-content-center mt-0 processButtonAmo"
              >
                <button
                  type="button"
                  className="text-gray-900 hover:text-white border border-gray-800 hover:bg-gray-900 focus:ring-0 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 dark:border-gray-600 dark:text-gray-400 shadow-md dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800 fw-bold  text-sm"
                  style={{ width: 200 }}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </Animated>
      </Modal>

      <Footer />
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    name: state.name,
  };
};

export default connect(mapStateToProps, null)(Home);
