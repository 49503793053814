import React, { useEffect } from "react";
import { connect } from "react-redux";
import Navbar from "./Sub Component/Navbar";
import "react-multi-carousel/lib/styles.css";
import "aos/dist/aos.css"; // You can also use <link> for styles
import Aos from "aos";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

function Availbility(props) {
  let navigate = useNavigate();
  useEffect(() => {
    document.body.style.background = ``;
  });

  return (
    <>
      {/* Navbar  */}
      <Navbar 
       show={true}
       Login={false} />
      <ToastContainer />

      {/* <img className="mainBoxi" src={require("../assets/back.png")} /> */}

      <div className="mainBoxi1" >

      </div>
      <div className="mainSearchBox ">
        <p className="text-5xl text-white fontPopin centerappavailable">
          Get Mobile App Now!
        
          <p className="text-sm text-white fontPopin justifyappdownload ">
          You can track your appointments now.<br /> Download App to track appointments.
          </p>
        </p>

        <div className="badgeDiv">
          <a href="https://play.google.com/store/apps/details?id=com.cruzz.userend">

            <img className="badgeIMAGE" src={require('../assets/playstore.png')} />
          </a>
          <a href="https://apps.apple.com/us/app/cruuz-book-an-appointment/id1531584697">

            <img className="badgeIMAGE1" src={require('../assets/appstore.png')} />
          </a>

        </div>
      </div>

      <div className="badgeDiv1">

      <a href="https://play.google.com/store/apps/details?id=com.cruzz.userend">

        <img className="badgeIMAGE" src={require('../assets/playstore.png')} />

</a>
        <a href="https://apps.apple.com/us/app/cruuz-book-an-appointment/id1531584697">

        <img className="badgeIMAGE1" src={require('../assets/appstore.png')} />


</a>
      </div>

      {/* <Footer /> */}
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    name: state.name,
  };
};

export default connect(mapStateToProps, null)(Availbility);
