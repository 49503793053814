import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import "react-multi-carousel/lib/styles.css";
import "aos/dist/aos.css"; // You can also use <link> for styles
import Aos from "aos";
import { BsSearch, BsChevronLeft } from "react-icons/bs";
import { GoPrimitiveDot } from "react-icons/go";
import "../../assets/chat.css";
import { Animated } from "react-animated-css";
import { get_all_Users, get_all_Chats,SendMessage } from '../../store/acion/index';
import { Timestamp } from "firebase/firestore";

Aos.init();
function Chats(Props) {
  let [animationIn, setanimationIn] = useState("fadeInUp");
  let [outanimation, setOutAnimation] = useState("fadeOutDown");
  let [chatModal, setChatModal] = useState(true);
  let [loading, setLoading] = useState(false);
  let [open_Details, setopen_Details] = useState(false);

  let [all_users_State, set_AllUsers_State] = useState([])
  let [currentchat, setcurrentchat] = useState([])

  useEffect(() => { 
    if (Props.All_Users == undefined) {

      Props.get_all_Users(setLoading)
    }
    else {
      set_AllUsers_State(Props.All_Users)
    }



  })


  let showChats = (recvicerName,recvicerID,senderName,senderID,content) => {
    if (window.innerWidth < 850) {
      setChatModal(false);

      setTimeout(() => {
        document.getElementById("chat-list").style.display = "none";
        document.getElementById("chatings").style.display = "block";
        setChatModal(true);
      }, 500);
    }

setcurrentchat({
  recvicerName,
  recvicerID,
  senderName,
  senderID,
  content,
})
    Props.get_all_Chats(setLoading, setopen_Details)

  };

  let BackChat = () => {
    setChatModal(false);

    setTimeout(() => {
      document.getElementById("chat-list").style.display = "block";
      document.getElementById("chatings").style.display = "none";
      setChatModal(true);
    }, 500);
  };
  const timestamp=(a)=>{
    if(a){

    
console.log("aaaaa",a.seconds);

let unix_timestamp = a.seconds
var date = new Date(unix_timestamp * 1000);
var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0'+minutes : minutes;
  var strTime = hours + ':' + minutes +"" + ampm;
return strTime
    }
    else{
      return ""
    }
  }
  return (
    <>
      {loading ? (
        <div className="loadingOpac" id="loadingOpac">
          <img src={require("../../assets/loading.gif")} className="loadingIMG" />
        </div>
      ) : (
        ""
      )}
      <Animated
        animationIn={animationIn}
        animationOut={outanimation}
        isVisible={chatModal}
      >
        <div className="main-chat-box">
          <div className="chat-list" id="chat-list">
            <p className="chat-list-chat-heading fw-bold fontPopin mainBarC mt-2">
              Chats
            </p>

            <div className="chat-list-search-box">
              <BsSearch size={20} color="#B9B9B9" />

              <input
                className="chat-list-search ml-4 fontPopin"
                placeholder="Search your messages"
              />
            </div>

            <div className="all-chat-lists mt-4">
              {console.log("rENDER", all_users_State)}
              {all_users_State.map((v, i) => {
                const {
                  recvicerName,
                  recvicerID,
                  senderName,
                  senderID,
                  content,
                  created
                }=v.heading.data
                return (
                  <div 
                  key={i}
                  className="chat-cards"
                   onClick={() =>
                    showChats(
                      recvicerName,
                      recvicerID,
                      senderName,
                      senderID)}>                 <img
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTaMtLdm16PjQz09ove355SPCkLSpGRenUNBA&usqp=CAU"
                      className="chat-dp"
                    />
                    <div className="chat-description">
                      <p className="chat-name fw-bold fontPopin">
                        {recvicerName}
                      </p>
                      <p className="chat-last  fontPopin mt-1">
                       {content}
                      </p>
                    </div>

                    <div className="chat-date-new ">
                      <p className="chat-last  fontPopin fw-normal  mt-1">
                        {timestamp(created)}
                      </p>

                      <GoPrimitiveDot color="#6541FF" size={20} />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          {open_Details ?
            <div className="chatings" id="chatings">
              <div className="chat-back">
                <BsChevronLeft
                  size={25}
                  onClick={() => BackChat()}
                  color={"#000"}
                />
              </div>

              <div className="chat-heading">
                <img
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTaMtLdm16PjQz09ove355SPCkLSpGRenUNBA&usqp=CAU"
                  className="chat-dp"
                />

                <div className="chat-description d-flex align-items-center ">
                  <p className="chat-name fw-bold fontPopin">Mimi Nail Salonfgg</p>
                </div>
              </div>

              <div className="chat-area">
                  {Props.All_Chats && Props.All_Chats.reverse().map((v, i) => {
                  const {senderID,receiverID,content,created}=
                  v.data 
                  return                senderID=='5fd705e40c3e39401926adfe'?
                  <div 
                  key={i}
                  className="chat-area-receive">
                            
                  <div className="message-area1 font-light	">
                    {content}
                    </div>
                  <p className="chat-time  fontPopin font-normal  mt-1">
                  {timestamp(created)}
                    </p>
                </div>
                  :
                  <div
                  key={i}
                  className="chat-area-send">
                  <div className="message-area font-light	">
                    {content}
                  </div>
                  <p className="chat-time  fontPopin font-normal  mt-1">
                  {timestamp(created)}
                  </p>
                </div>  
                              })}

               
              </div>

              <div className="chat-input-area">
                <input
                onKeyDown={
                  (e)=>{e.key=='Enter' && 
                  SendMessage(currentchat,e.target.value)}
                }
                  className="chat-message-input fontPopin"
                  placeholder="Message…"
                  onBlur={() => {
                    setTimeout(() => {
                      window.scroll(0, 0)
                    }, 40);
                  }}
                />

                <div className="image-upload">
                  <label for="file-input">
                    <img
                      src={require("../../assets/gallery.png")}
                      className="galery-icon"
                    />
                  </label>

                  <input id="file-input" type="file" />
                </div>
              </div>
            </div>


            :

            ''


          }
        </div>
      </Animated>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    name: state.name,
    All_Users: state.All_Users,
    All_Chats: state.All_Chats
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    get_all_Users: (setLoading) => dispatch(get_all_Users(setLoading)),
    // SendMessage: () => dispatch(SendMessage()),
    get_all_Chats: (setLoading, setopen_Details) => dispatch(get_all_Chats(setLoading, setopen_Details)),

  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Chats);
