import React, { useState } from "react";
import { connect } from "react-redux";
import Navbar from "./Sub Component/NavbarBack";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "aos/dist/aos.css"; // You can also use <link> for styles
import Aos from "aos";
import Footer from "./Sub Component/footer";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AiFillCloseCircle } from "react-icons/ai";
import { BsFillCreditCard2FrontFill } from "react-icons/bs";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";

Aos.init();
function Payments(props) {
  let navigate = useNavigate();
  let [name, setName] = useState("");
  let [csv, setCsv] = useState("");
  let [expiry, setexpiry] = useState("");
  let [number, setNumber] = useState("");
  let [focus, setFocus] = useState("");

  return (
    <>
      {/* Navbar  */}
      <Navbar back="/" />

      {/* Slider  */}

      {/* Nearby */}
      <div className="catagries_div pb-8 mb-3">
        <div className="heading_main_mapbet">
          <font className="heading_main_map_font22">Payments</font>
        </div>
        <font className="heading_main_map_font11 font-bold ml-10 mt-5">
          Add Payment Method
        </font>

        <div className="flex lookBox row">
          <div className="relative rounded-md p-3 hover:bg-gray-100 cursor-pointer shadow p-3 mb-5 bg-white rounded">
            <a href="#popup1">
              <div className="showflex">
                <BsFillCreditCard2FrontFill size={30} color={"#13242A"} />
                <font className="heading_main_map_font11 font-bold ml-10">
                  Credit / Debit Card
                </font>
              </div>
            </a>
          </div>
          <div
            onClick={() => navigate("/map")}
            className="relative rounded-md p-3 hover:bg-gray-100 cursor-pointer shadow p-3 mb-5 bg-white rounded"
          >
            <div className="showflex">
              <BsFillCreditCard2FrontFill size={30} color={"#13242A"} />
              <font className="heading_main_map_font11 font-bold ml-10">
                Google Pay
              </font>
            </div>
          </div>
        </div>

        <div
          role={"button"}
          className="bg-white  d-flex justify-content-around align-start flex-wrap "
        >
          <div className="w-96 h-56  bg-red-100 rounded-xl relative text-white shadow-2xl mt-3 ">
            <img
              className="relative object-cover w-full h-full rounded-xl"
              src="https://i.imgur.com/kGkSg1v.png"
            />

            <div className="w-full px-8 absolute top-8">
              <div className="flex justify-between">
                <div className="">
                  <p className="font-light">Name</p>
                  <p className="font-medium tracking-widest">Karthik P</p>
                </div>
                <img className="w-14 h-14" src="https://i.imgur.com/bbPHJVe.png" />
              </div>
              <div className="pt-1">
                <p className="font-light">Card Number</p>
                <p className="font-medium tracking-more-wider">
                  4642 3489 9867 7632
                </p>
              </div>
              <div className="pt-6 pr-6">
                <div className="flex justify-between">
                  <div className="">
                    <p className="font-light text-xs">Valid</p>
                    <p className="font-medium tracking-wider text-sm">11/15</p>
                  </div>
                  <div className="">
                    <p className="font-light text-xs text-xs">Expiry</p>
                    <p className="font-medium tracking-wider text-sm">03/25</p>
                  </div>

                  <div className="">
                    <p className="font-light text-xs">CVV</p>
                    <p className="font-bold tracking-more-wider text-sm">···</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="w-96 h-56  bg-red-100 rounded-xl relative text-white shadow-2xl mt-3">
            <img
              className="relative object-cover w-full h-full rounded-xl"
              src="https://i.imgur.com/Zi6v09P.png"
            />

            <div className="w-full px-8 absolute top-8">
              <div className="flex justify-between">
                <div className="">
                  <p className="font-light">Name</p>
                  <p className="font-medium tracking-widest">Karthik P</p>
                </div>
                <img
                  className="w-14 h-14 "
                  style={{ objectFit: "fill", width: 60, height: 25 }}
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5e/Visa_Inc._logo.svg/2560px-Visa_Inc._logo.svg.png"
                />
              </div>
              <div className="pt-1">
                <p className="font-light">Card Number</p>
                <p className="font-medium tracking-more-wider">
                  4642 3489 9867 7632
                </p>
              </div>
              <div className="pt-6 pr-6">
                <div className="flex justify-between">
                  <div className="">
                    <p className="font-light text-xs">Valid</p>
                    <p className="font-medium tracking-wider text-sm">11/15</p>
                  </div>
                  <div className="">
                    <p className="font-light text-xs text-xs">Expiry</p>
                    <p className="font-medium tracking-wider text-sm">03/25</p>
                  </div>

                  <div className="">
                    <p className="font-light text-xs">CVV</p>
                    <p className="font-bold tracking-more-wider text-sm">···</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="popup1" className="overlay1">
          <div className="popup1 popupres">
            <a href="#">
              <AiFillCloseCircle
                style={{ position: "absolute", top: 15, right: 13 }}
                size={30}
                color="#13242A"
              />
            </a>

            <div className="heading_main_mapbet">
              <font className="heading_main_map_font22">
                Add Debit / Credit Card
              </font>
            </div>
            <h1 className="heading_main_map_font11 font-bold ml-10 mb-5">
              Add Payment Method
            </h1>

            <Cards
              cvc={csv}
              expiry={expiry}
              name={name}
              number={number}
              focused={`${focus}`}
            />

            <div className="flex lookBox row cardInputs">
             
           

            <div className="relative rounded-md p-3 hover:bg-gray-100 cursor-pointer shadow p-3 mb-5 bg-white rounded d-flex align-center justify-content-between">
                  <font
                    style={{
                      fontSize: 14,
                      fontWeight: "500",
                      color: "#13242A",
                    }}
                  >
                    Name
                  </font>
                <input onFocus={()=>{setFocus("name");}}  onChange={(e)=>setName(e.target.value)} className="creditInput" />
              </div>




              <div className="relative rounded-md p-3 hover:bg-gray-100 cursor-pointer shadow p-3 mb-5 bg-white rounded d-flex align-center justify-content-between">
                  <font
                    style={{
                      fontSize: 14,
                      fontWeight: "500",
                      color: "#13242A",
                    }}
                  >
                    Number
                  </font>
                <input  maxLength={16} onFocus={()=>{setFocus("number");}}   onChange={(e)=>setNumber(e.target.value)} className="creditInput" />
              </div>






            


              <div className="relative rounded-md p-3 hover:bg-gray-100 cursor-pointer shadow p-3 mb-5 bg-white rounded d-flex align-center justify-content-between">
                  <font
                    style={{
                      fontSize: 14,
                      fontWeight: "500",
                      color: "#13242A",
                    }}
                  >
                    Date
                  </font>
                <input maxLength={6} onFocus={()=>{setFocus("expiry");}}  onChange={(e)=>setexpiry(e.target.value)} className="creditInput" />
              </div>

              <div className="relative rounded-md p-3 hover:bg-gray-100 cursor-pointer shadow p-3 mb-5 bg-white rounded d-flex align-center justify-content-between" style={{width:300}}>
                  <font
                    style={{
                      fontSize: 14,
                      fontWeight: "500",
                      color: "#13242A",
                    }}
                  >
                    CVC
                  </font>
               
                <input maxLength={4} className="creditInput" onFocus={()=>{setFocus("cvc");}} onChange={(e)=>{ setCsv(e.target.value)}} />
              </div>

            </div>
          </div>
        </div>
      </div>


      
      <Footer />
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    name: state.name,
  };
};

export default connect(mapStateToProps, null)(Payments);
