/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { BellIcon, MenuIcon, XIcon } from "@heroicons/react/outline";
import { useNavigate } from 'react-router-dom';
import { BsSearch } from 'react-icons/bs'

const navigation1 = [

];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Navbar(Props) {
  let navigate = useNavigate()

  const navigation = [
    // { name: "Orders", href: "#", current: false ,nav:''},
    { name: "Login", href: "#", current: false, nav: '/PhoneInput' },
  ];

 const navigate_f=()=>{
  if(Props.show){
    navigate("/")

  }else{
    // localStorage.removeItem('Searched_History')
    // localStorage.removeItem('Selected_location')  
  }
    navigate("/")
 }
  return (
    <Disclosure as="nav" className={"mainBar"}>
      {({ open }) => (
        <>
          {console.log("cccccccccccccccccccc", Props)}
          <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
            <div className="relative flex items-center justify-between h-16">
              {/* <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div> */}


              {
                window.location.href.includes('AvailableApps') ?
                  <div className="loginButton1"  >

                  </div>
                  :
                  <>
                  <div className="loginButton" onClick={() => navigate('/AvailableApps')}>
                    Apps

                  </div>
                  

                 {
                 !localStorage.getItem("_id")?
                <div
                 className="loginButton margin-left10"
                  onClick={() => navigate('/PhoneInput')}>
                 Orders
                  </div>:
                  <div
                   className="loginButton margin-left10"
                   onClick={() => navigate('/order')}>
                   Orders
                  </div>


                 }
                  </>
              }


              <div className="flex-1 flex items-center justify-center  navbaralign "  >
                <div className="flex-shrink-0 flex items-center">
                  <img
                    onClick={navigate_f}

                    className="block lg:hidden h-6 w-auto"
                    style={{ objectFit: 'cover', width: 100, cursor: 'pointer' }}
                    src={require('../../assets/logoW.png')}
                    alt="Workflow"
                  />
                  <img
                    onClick={() => {
                      // localStorage.removeItem('Searched_History')
                      // localStorage.removeItem('Selected_location')
                      navigate("/")
                    }}
                    className="hidden lg:block h-7 w-auto"
                    style={{ objectFit: 'cover', cursor: 'pointer' }}

                    src={require('../../assets/logoW.png')}
                    alt="Workflow"
                  />
                </div>
                {/* <div className="hidden sm:block sm:ml-6">
                  <div className="flex space-x-4">
                    {navigation.map((item) => (
                      <a
                      data-aos="flip-up"
                      data-aos-duration="800"
                        key={item.name}
                        href={item.href}
                        onClick={()=>  navigate(item.nav)}
                        className={classNames(
                          item.current
                            ? "bg-gray-900 text-white"
                            : "text-white",
                          "block px-3 py-2 rounded-md text-base font-medium"
                        )}
                        aria-current={item.current ? "page" : undefined}
                      >
                        {item.name}
                      </a>
                    ))}
                    <div className="relative inline-block text-left">
                      <div>
                        <a
                          onClick={() => navigate('/Address')}
                          className={classNames(

                            "text-white",
                            "block px-3 py-2 rounded-md text-base font-medium d-flex cursor-pointer"
                          )}>
                          Islamabad
                          <svg className="-mr-1 ml-2 h-5 w-5 mt-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                          </svg>
                        </a>
                      </div>

                    </div>
                  </div>
                </div> */}
              </div>
              {localStorage.getItem("_id")
                ?
                <Menu as="div" className="ml-3 relative ">
                  <div>
                    <Menu.Button
                      data-aos="fade-left"
                      data-aos-duration="1200"
                      className=" flex text-sm rounded-full focus:outline-none ">
                      <span className="sr-only">Open user menu</span>

                      <img
                        className="h-9 w-9 rounded-full"
                        src={require('../../assets/avatar.png')}
                        alt=""
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="origin-top-left absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {/* <Menu.Item>
                        {({ active }) => (
                          <a

                            onClick={() => navigate('/Profile')}
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700"
                            )}
                          >
                            Your Profile
                          </a>
                        )}
                      </Menu.Item>

                      <Menu.Item>
                        {({ active }) => (
                          <a

                            onClick={() => navigate('/Payments')}
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700"
                            )}
                          >
                            Payments
                          </a>
                        )}
                      </Menu.Item>



                      <Menu.Item>
                        {({ active }) => (
                          <a
                            onClick={() => navigate('/Address')}
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700"
                            )}
                          >
                            Address
                          </a>
                        )}
                      </Menu.Item>


                      <Menu.Item>
                        {({ active }) => (
                          <a

                            onClick={() => navigate('/Support')}
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700"
                            )}
                          >
                            Support
                          </a>
                        )}
                      </Menu.Item> 
 */}

                      <Menu.Item>
                        {({ active }) => (
                          <a
                            onClick={() => {
                              localStorage.clear()
                              navigate('/')
                            }}
                            href="#"
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700"
                            )}
                          >
                            Sign out
                          </a>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
                :
                navigation.map((item) => (
                  <div className="loginButton" onClick={() => navigate(item.nav)}>
                    {item.name}

                  </div>
                ))}

            </div>
          </div>

          <Disclosure.Panel className="sm:hidden zindexSet">
            <div className="px-2 pt-2 pb-3 space-y-1">
              <div className="flex space-x-4">
                {navigation1.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className={item.filled ? "currentNav" : "currentNav1"}
                    aria-current={item.current ? "page" : undefined}
                  >
                    {item.name}
                  </Disclosure.Button>
                ))}
              </div>


            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
