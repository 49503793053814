const INITIAL_STATE = {
  name: "HAssan",
  All_Users:undefined,
  All_Chats:undefined,
  All_Orders:undefined,
  Home:undefined

};

export default (state = INITIAL_STATE, action) => {


  if (action.type === 'All_Users') {

    return( {
      ...state,
      All_Users: action.payload,
    });
  }

  if (action.type === 'All_Chats') {

    return( {
      ...state,
      All_Chats: action.payload,
    });
  }
  if (action.type === 'All_Orders') {
    return( {
      ...state,
      All_Orders:action.payload,
    });
  }
  if (action.type === 'Home') {
    return( {
      ...state,
      Home:action.payload,
    });
  }
  return state;
};
