import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "./Sub Component/NavbarBack";
import Carousel from "react-multi-carousel";
import StickyBox from "react-sticky-box";
import api from "../assets/variable";

// Modal Import
import { Animated } from "react-animated-css";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import Modal from "react-modal";

// Icon import
import { IoLocationOutline } from "react-icons/io5";
import { AiOutlineCloseCircle, AiOutlineShoppingCart } from "react-icons/ai";
import { FaAngleRight } from "react-icons/fa";
import { BsFillCreditCard2FrontFill, BsFillStarFill } from "react-icons/bs";

// Credit Card Import
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";

// AOS Transation import
import "aos/dist/aos.css";
import Aos from "aos";
import Footer from "./Sub Component/footer";
import axios from 'axios'
Aos.init();

export default function ItemDetails(props) {
  
  // Fetch Shop details

  const shop_detail= async ()=>{
    const response = await axios.post(`${api}/api/user/allshopdata`)
    console.log("getAllData response ==> ", response)
    // if (response.data && response.data.status && response.data.status === true) {
    //     return response.data.data
    // }
    // return response.data.data
  }

useEffect(()=>{
console.log("h>>>>>>.");
shop_detail()

},[])


  
  // Model 0 Data

  const [modalIsOpen, setIsOpen] = React.useState(true);
  const [div, setDiv] = React.useState(true);



  // Model 1 Data

  const [modalIsOpen1, setIsOpen1] = React.useState(false);
  const [div1, setDiv1] = React.useState(false);

  function openModal1() {
    setIsOpen1(true);
    setDiv1(true);
  }

  function closeModal1() {
    setDiv1(false);
    setTimeout(() => {
      setIsOpen1(false);
    }, 800);
  }

  // Model 2 Data

  const [modalIsOpen2, setIsOpen2] = React.useState(false);
  const [div2, setDiv2] = React.useState(false);

  function openModal2() {
    setIsOpen2(true);
    setDiv2(true);
  }

  function closeModal2() {
    setDiv2(false);
    setTimeout(() => {
      setIsOpen2(false);
    }, 800);
  }

  // Model 3 Data

  const [modalIsOpen3, setIsOpen3] = React.useState(false);
  const [div3, setDiv3] = React.useState(false);

  function openModal3() {
    setIsOpen3(true);
    setDiv3(true);
  }

  function closeModal3() {
    setDiv3(false);
    setTimeout(() => {
      setIsOpen3(false);
    }, 800);
  }

  // Model 4 Data
  let [name, setName] = useState("");
  let [csv, setCsv] = useState("");
  let [expiry, setexpiry] = useState("");
  let [number, setNumber] = useState("");
  let [focus, setFocus] = useState("");
  const [modalIsOpen4, setIsOpen4] = React.useState(false);
  const [div4, setDiv4] = React.useState(false);

  function openModal4() {
    setIsOpen4(true);
    setDiv4(true);
  }

  function closeModal4() {
    setDiv4(false);
    setTimeout(() => {
      setIsOpen4(false);
    }, 800);
  }


  let navigate = useNavigate();

  const responsive1 = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 7,
      paritialVisibilityGutter: 60,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      paritialVisibilityGutter: 50,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
      paritialVisibilityGutter: 30,
    },
  };
  let arr = [
    {
      id: 0,
      title: "Saloon Service",
      image:
        "https://freedesignfile.com/upload/2017/09/Beautician-for-customer-beauty-services-Stock-Photo-09.jpg",
      status: "HairCut",
      details: "Get info For Messge Get info ",
      select: false,
    },
    {
      id: 1,
      title: "Hair Cut",
      image:
        "https://hips.hearstapps.com/esq.h-cdn.co/assets/17/29/980x980/square-1500667303-es-072117-talk-to-your-barber-about-your-hair.jpg?resize=640:*",
      status: "In Home",
      details: "Get info For Hair Cut",
      select: false,
    },
    {
      id: 2,
      title: "Massage",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS1MVmN5D6q5w6yBEUwQ0SjGuNFWs3ycLM5Ew&usqp=CAU",
      status: "In Shop",
      details: "Get info For Messge Get info ",
      select: false,
    },
    {
      id: 3,
      title: "Nail Services ",
      image:
        "https://s3.amazonaws.com/salonclouds-uploads/blog/blog_1575589880234492352.png",
      status: "In Shop",
      details: "Get info For Messge Get info ",
      select: false,
    },
    {
      id: 4,
      title: "Saloon Service",
      image:
        "https://freedesignfile.com/upload/2017/09/Beautician-for-customer-beauty-services-Stock-Photo-09.jpg",
      status: "HairCut",
      details: "Get info For Messge Get info ",
      select: false,
    },
    {
      id: 5,
      title: "Nail Service",
      image:
        "https://i.guim.co.uk/img/media/0da62f03250c88a717e0beab4972cc7584b9e333/0_275_6000_3600/master/6000.jpg?width=1200&height=900&quality=85&auto=format&fit=crop&s=74cf7d5692c89b8072cf0c1822478fa8",
      status: "In Shop",
      details: "Get info For Messge Get info ",
      select: false,
    },
    {
      id: 6,
      title: "Barber Shop",
      image:
        "https://hips.hearstapps.com/esq.h-cdn.co/assets/17/29/980x980/square-1500667303-es-072117-talk-to-your-barber-about-your-hair.jpg?resize=640:*",
      status: "In Home",
      details: "Get info For Messge Get info ",
      select: false,
    },
    {
      id: 7,
      title: "Special HairCut",
      image:
        "https://static9.depositphotos.com/1518767/1119/i/450/depositphotos_11193354-stock-photo-blond-haired-man-having-a.jpg",
      status: "In Shop",
      details: "Get info For Messge Get info ",
      select: false,
    },
    {
      id: 8,
      title: "Hair Cut",
      image:
        "https://hips.hearstapps.com/esq.h-cdn.co/assets/17/29/980x980/square-1500667303-es-072117-talk-to-your-barber-about-your-hair.jpg?resize=640:*",
      status: "In Home",
      details: "Get info For Messge Get info ",
      select: false,
    },
    {
      id: 9,
      title: "Massage",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS1MVmN5D6q5w6yBEUwQ0SjGuNFWs3ycLM5Ew&usqp=CAU",
      status: "In Shop",
      details: "Get info For Messge Get info ",
      select: false,
    },
    {
      id: 10,
      title: "Nail Services ",
      image:
        "https://s3.amazonaws.com/salonclouds-uploads/blog/blog_1575589880234492352.png",
      status: "In Shop",
      details: "Get info For Messge Get info ",
      select: false,
    },

    {
      id: 11,
      title: "Saloon Service",
      image:
        "https://freedesignfile.com/upload/2017/09/Beautician-for-customer-beauty-services-Stock-Photo-09.jpg",
      status: "HairCut",
      details: "Get info For Messge Get info ",
      select: false,
    },
    {
      id: 12,
      title: "Nail Service",
      image:
        "https://i.guim.co.uk/img/media/0da62f03250c88a717e0beab4972cc7584b9e333/0_275_6000_3600/master/6000.jpg?width=1200&height=900&quality=85&auto=format&fit=crop&s=74cf7d5692c89b8072cf0c1822478fa8",
      status: "In Shop",
      details: "Get info For Messge Get info ",
      select: false,
    },
    {
      id: 13,
      title: "Barber Shop",
      image:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT9BdlbQAaTCy8reWSsWCwtJhD2P6HUxYbnUw&usqp=CAU",
      status: "In Home",
      details: "Get info For Messge Get info ",
      select: false,
    },
    {
      id: 14,
      title: "Special HairCut",
      image:
        "https://static9.depositphotos.com/1518767/1119/i/450/depositphotos_11193354-stock-photo-blond-haired-man-having-a.jpg",
      status: "In Shop",
      details: "Get info For Messge Get info ",
      select: false,
    },
  ];

  const [value, onChange] = useState(new Date());




  return (
    <>
      <div className="d-flex align-items-start justify-content-between flex-wrap mainFirstBox">
        <div className="modaling1" >
          <div
            className=" 
          bg-white        
          d-flex flex-column
          
          "
          >
            <div>
              <p
                style={{
                  fontSize: 25,
                  fontWeight: "600",
                  color: "black",
                  marginTop: 10,
                  width: "100%",
                  textAlign: "left",
                }}
              >
                Artist And Time
              </p>
              <span
                className="text-md mt-2"
                style={{
                  fontSize: 14,
                  fontWeight: "500",
                  color: "grey",
                  // paddingLeft: 10,
                }}
              >
                Select a Pro
              </span>
              {/* <hr  className="mt-1"/> */}
              <div className="horizontalView mt-1">
                {arr.map((v, i) => {
                  return (
                    <div className="horizontalViewdiv" key={i}>
                      <img className="imgDol " src={v.image} />
                      <span
                        className="text-md mt-1"
                        style={{
                          fontSize: 13,
                          fontWeight: "bold",
                          textAlign: "center",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: 70,
                        }}
                      >
                        {v.title}
                      </span>

                      <span
                        className="text-md"
                        style={{
                          fontSize: 11,
                          fontWeight: "500",
                          textAlign: "center",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: 70,
                          color: "grey",
                        }}
                      >
                        User Details
                      </span>

                      <div className="underline"></div>
                    </div>
                  );
                })}
              </div>
              <div className="w-100 d-flex align-items-start justify-content-between flex-row flexWrapCont">
                <div className="widthContrl">
                  <span
                    className="text-md mb-3"
                    style={{
                      fontSize: 14,
                      fontWeight: "500",
                      color: "grey",
                      // paddingLeft: 10,
                      width:'100%',
                      textAlign:'start'
                    }}
                  >
                    Select Time
                  </span>
                  <Calendar onChange={onChange} value={value} />
                </div>

                <div>
                  <span
                    className="text-md mt-2"
                    style={{
                      fontSize: 13,
                      fontWeight: "500",
                      color: "grey",
                      paddingLeft: 10,
                    }}
                  >
                    All Available Time
                  </span>

                  <div className="d-flex align-items-end justify-content-end">
                    <span
                      className="text-md mt-0  "
                      style={{
                        fontSize: 13,
                        fontWeight: "500",
                        color: "#13242A",
                      }}
                    >
                      Morning
                    </span>
                  </div>

                  {/* <div className="horizontalView1 mt-1"> */}
                  <div className="d-flex flex-row flex-wrap align-items-center justify-content-start ml-1 flexConr">
                    {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((v, i) => {
                      return (
                        <div key={i} className="horizontalViewdiv1 mt-1 shadow-md ml-2">
                          02:23 am
                        </div>
                      );
                    })}
                  </div>

                  <div className="d-flex align-items-end justify-content-end">
                    <span
                      className="text-md mt-2  "
                      style={{
                        fontSize: 13,
                        fontWeight: "500",
                        color: "#13242A",
                       
                      }}
                    >
                      Evening
                    </span>
                  </div>

                  {/* <div className="horizontalView1 mt-1"> */}
                  <div className="d-flex flex-row flex-wrap align-items-center justify-content-start ml-1 flexConr">
                    {[0, 1, 2, 3, 4].map((v, i) => {
                      return (
                        <div  key={i} className="horizontalViewdiv1 mt-1 shadow-md ml-2">
                          02:23 am
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>

              <div className="w-full d-flex align-items-end justify-content-end">
                <h4 className="clearAll text-sm mr-3">
                  Clear All
                </h4>
              </div>

              <div
          >
                                <div className="borderWidit mt-5"></div>

            <div
              className="  
             
          d-flex flex-row w-100 flex-wrap  align-items-start justify-content-around
          "
            >
             
              <p
                style={{
                  fontSize: 25,
                  fontWeight: "600",
                  color: "black",
                  marginTop: 10,
                  width: "100%",
                  textAlign: "left",
                }}
              >
                Shop Name
              </p>

              <div className="w-100 d-flex flex-row flex-wrap align-item-center justify-content-around">
                <div className="mainBoxDivi3">
                  <div className="d-flex flex-column align-items-center  ">
                    <div className="w-100 d-flex flex-row align-items-center justify-content-between mb-2 ">
                      <p
                        style={{
                          fontSize: 14,
                          fontWeight: "500",
                          color: "grey",
                        }}
                        // className="ml-2"
                      >
                        Your Order
                      </p>

                      <p
                        style={{
                          fontSize: 15,
                          fontWeight: "500",
                          color: "#13242A",
                          textAlign: "center",
                          cursor: "pointer",
                          
                        }}
                        className='mr-1'
                      >
                        Add
                      </p>
                    </div>

                    <div className="borderWidit"></div>

                    <div className="flexControl
                     d-flex align-items-center justify-content-between flex-wrap flex-row">
                      {arr.map((v, i) => {
                        return (
                          <div
                          key={i}
                            className=" cursor-pointer  p-1   d-flex flex-row  align-items-center justify-content-between mainBoxing2 mt-1"
                          >
                            <div className=" d-flex flex-column ml-2 mr-5 align-item-start  justify-content-start ">
                              <h2 className="text-sm textcolor fw-bold">
                                {v.title}
                              </h2>

                              <p   className=" text-xs"
                                 style={{
                                 overflow:'hidden',
                                 height:20
                                }}
                              >
                                {v.details}
                                
                              </p>
                            </div>
                            <div
                              className="showflex borderBox  text-xs	d-flex align-items-center pl-1 pr-1"
                              id='simpleefe'
                            >
                              $30.00
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    
              <div className="w-full d-flex align-items-end justify-content-end mt-4">
                <h4 className="clearAll text-sm">
                  Remove All
                </h4>
              </div>
                  </div>
                </div>

              
              </div>

            
            </div>
          </div>
            </div>
          </div>
        </div>
        <StickyBox   className='stickyBox' offsetTop={0} offsetBottom={10}>

        <div   className="receiption">
                <p
                  style={{
                    fontSize: 25,
                    fontWeight: "600",
                    color: "black",
                    marginTop: 10,
                    width: "100%",
                    textAlign: "left",
                  }}
                  className="ml-1 mt-2"
                >
                  Payment information
                </p>

                <p
                  style={{
                    fontSize: 14,
                    fontWeight: "500",
                    color: "grey",
                    textAlign: "left",
                    cursor: "pointer",
                    width: "100%",
                  }}
                  className="ml-2 mt-2"
                >
                  Add a Tip
                </p>

                <div className="d-flex flex-row align-items-center justify-content-between flex-wrap">
                  {arr.map((v, i) => {
                    return (
                      <div  key={i} className="horizontalViewdiv2 mt-1 ml-1">
                        {Math.round(Math.floor(Math.random() * 100))} $
                      </div>
                    );
                  })}
                </div>

                <div className="w-full d-flex flex-row align-items-center justify-content-between mt-2 pl-1 pr-1">
                  <p
                    className="showflex text-sm"
                    style={{
                      fontWeight: "500",
                      color: "grey",
                      textAlign: "center",
                    }}
                  >
                    Total
                  </p>

                  <p className="showflex purpleText mr-2 text-sm">100 $</p>
                </div>

                <div className="w-full d-flex flex-row align-items-center justify-content-between mt-2 pl-1 pr-1">
                  <p
                    className="showflex text-sm"
                    style={{
                      fontWeight: "500",
                      color: "grey",
                      textAlign: "center",
                    }}
                  >
                    Services
                  </p>

                  <p className="showflex purpleText text-sm">
                    Kids
                    <FaAngleRight className="ml-1 " size={15} />
                  </p>
                </div>

                <div className="w-full d-flex flex-row align-items-center justify-content-between mt-2 pl-1 pr-1">
                  <p
                    className="showflex text-sm"
                    style={{
                   
                      fontWeight: "500",
                      color: "grey",
                      textAlign: "center",
                    }}
                   
                  >
                    Add Promo Code
                  </p>

                  <p className="showflex purpleText text-sm">
                    Apply Code
                    <FaAngleRight className="ml-1 " size={15} />
                  </p>
                </div>

                <div className="w-full d-flex flex-row align-items-center justify-content-between mt-2 pl-1 pr-1">
                  <p
                    className="showflex text-sm"
                    style={{
                      fontWeight: "500",
                      color: "grey",
                      textAlign: "center",
                    }}
                  >
                    Add Payment Method
                  </p>

                  <p className="showflex purpleText text-sm">
                    Select Method
                    <FaAngleRight className="ml-1 " size={15} />
                  </p>
                </div>
                <div className="w-100 d-flex align-items-center justify-content-center mt-4">
                <button type="button" className="text-gray-900 hover:text-white border border-gray-800 hover:bg-gray-900 focus:ring-0 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:border-gray-600 dark:text-gray-400 shadow-md dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800 fw-bold" style={{width:200}}>Continue</button>

              </div>
              </div>
        </StickyBox>
      </div>

      <div className="modaling2 " >
        <div className="shadowSh"></div>
        <div
          className="  
          bg-white        
          d-flex flex-row w-100 flex-wrap  align-items-start justify-content-around
         
          "
        
        >
          <p
            style={{
              fontSize: "2em",
              fontWeight: "600",
              color: "Black",
              marginTop: 10,
              width: "100%",
              textAlign: "left",
              marginBottom: 10,
            }}
          >
            Where You Will be
          </p>

          <div style={{ width: "100%" }}>
            <iframe
              width="100%"
              height="400"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              src="https://maps.google.com/maps?width=100%25&amp;height=400&amp;hl=en&amp;q=1%20Grafton%20Street,%20Dublin,%20Ireland+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            >
              <a href="https://www.maps.ie/distance-area-calculator.html">
                measure distance on map
              </a>
            </iframe>
          </div>

         
        </div>
      </div>


        {/* Modal 0 Component  */}

        <Modal
        isOpen={modalIsOpen}
        // onRequestClose={closeModal}
        // style={customStyles}
        contentLabel="Example Modal"
        className={"modal2"}
      >
        <Animated
          animationIn="bounceInRight"
          animationOut="bounceOutRight"
          isVisible={div}
        >
          <div
            className="modaling"
            style={{ width: "100%", height: "100vh", margin: "0%" }}
          >
            <div
              className="shadow-md  
          bg-white        
          d-flex flex-column
          fixBottom
          "
            >
              <div>
                <div className="d-flex  justify-content-end">
                  <a href="#" style={{ cursor: "pointer" }}>
                    <AiOutlineCloseCircle
                      // onClick={() => closeModal()}
                      // style={{ position: "absolute", top: 15, right: 13 }}
                      size={30}
                      color="#13242A"
                    />
                  </a>
                </div>
                <p
                  style={{
                    fontSize: 25,
                    fontWeight: "600",
                    color: "Black",
                    marginTop: 10,
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  Artist And Time
                </p>
                <span
                  className="text-md mt-2"
                  style={{
                    fontSize: 14,
                    fontWeight: "500",
                    color: "grey",
                    paddingLeft: 10,
                  }}
                >
                  Select a Pro
                </span>
                {/* <hr  className="mt-1"/> */}
                <div className="horizontalView mt-2">
                  {arr.map((v, i) => {
                    return (
                      <div  key={i} className="horizontalViewdiv ">
                        <img className="imgDol " src={v.image} />
                        <span
                          className="text-md mt-1"
                          style={{
                            fontSize: 13,
                            fontWeight: "bold",
                            textAlign: "center",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            width: 70,
                          }}
                        >
                          {v.title}
                        </span>

                        <span
                          className="text-md"
                          style={{
                            fontSize: 11,
                            fontWeight: "500",
                            textAlign: "center",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            width: 70,
                            color: "grey",
                          }}
                        >
                          User Details
                        </span>

                        <div className="underline"></div>
                      </div>
                    );
                  })}
                </div>
                <div className="w-100 d-flex align-items-start justify-content-start flex-column">
                 <div>
                   <span
                    className="text-md mb-3"
                    style={{
                      fontSize: 14,
                      fontWeight: "500",
                      color: "grey",
                      paddingLeft: 10,
                    }}
                  >
                    Select Time
                  </span>
                  <Calendar onChange={onChange} value={value} />
                 </div>
                </div>

                <span
                  className="text-md mt-2"
                  style={{
                    fontSize: 13,
                    fontWeight: "500",
                    color: "grey",
                    paddingLeft: 10,
                  }}
                >
                  All Available Time
                </span>

                <div className="d-flex align-items-end justify-content-end">
                  <span
                    className="text-md mt-0  mr-5 "
                    style={{
                      fontSize: 13,
                      fontWeight: "500",
                      color: "#13242A",
                      paddingLeft: 10,
                    }}
                  >
                    Morning
                  </span>
                </div>

                {/* <div className="horizontalView1 mt-1"> */}
                <div className="d-flex flex-row flex-wrap align-items-center justify-content-between ml-1">
                  {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((v, i) => {
                    return (
                      <div  key={i} className="horizontalViewdiv1 mt-1 ml-3">
                        02:23 am
                      </div>
                    );
                  })}
                </div>

                <div className="d-flex align-items-end justify-content-end">
                  <span
                    className="text-md mt-2  mr-5 "
                    style={{
                      fontSize: 13,
                      fontWeight: "500",
                      color: "#13242A",
                      paddingLeft: 10,
                    }}
                  >
                    Evening
                  </span>
                </div>

                {/* <div className="horizontalView1 mt-1"> */}
                <div className="d-flex flex-row flex-wrap align-items-center justify-content-between ml-1">
                  {[0, 1, 2, 3, 4].map((v, i) => {
                    return (
                      <div  key={i} className="horizontalViewdiv1 mt-1 ml-3">
                        02:23 am
                      </div>
                    );
                  })}
                </div>

            {}
              </div>
            </div>
          </div>
        </Animated>
      </Modal>

      {/* Modal 1   */}

      <Modal
        isOpen={modalIsOpen1}
        onRequestClose={closeModal1}
        // style={customStyles}
        contentLabel=" Modal"
        className={"modal1"}
      >
        <Animated
          animationIn="bounceInRight"
          animationOut="bounceOutRight"
          isVisible={div1}
        >
          <div
            className="modaling"
            style={{ width: "100%", height: "100vh", margin: "0%" }}
          >
            <div
              className="shadow-md   
          bg-white        
          d-flex flex-row w-100 flex-wrap  align-items-start justify-content-around
          fixBottom
          "
            >
              <div className="d-flex  justify-content-end w-100">
                <a href="#" style={{ cursor: "pointer" }}>
                  <AiOutlineCloseCircle
                    onClick={() => closeModal1()}
                    // style={{ position: "absolute", top: 15, right: 13 }}
                    size={30}
                    color="#13242A"
                  />
                </a>
              </div>
              <p
                style={{
                  fontSize: "2em",
                  fontWeight: "600",
                  color: "Black",
                  marginTop: 10,
                  width: "100%",
                  textAlign: "center",
                }}
              >
                Shop Name
              </p>

              <div className="w-100 d-flex flex-row flex-wrap align-item-center justify-content-around">
                <div className="mainBoxDivi3">
                  <div className="d-flex flex-column align-items-center  ">
                    <div className="w-100 d-flex flex-row align-items-center justify-content-between mb-1 borderBitt">
                      <p
                        style={{
                          fontSize: 15,
                          fontWeight: "500",
                          color: "Black",
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                      >
                        Your Order
                      </p>

                      <p
                        style={{
                          fontSize: 15,
                          fontWeight: "500",
                          color: "#13242A",
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                      >
                        Add
                      </p>
                    </div>

                    <div className="borderWidit"></div>

                    {[1, 2].map((v, i) => {
                      return (
                        <div className="relative   cursor-pointer  p-1   d-flex flex-row w-100 align-items-center justify-content-between mainBoxing mt-1">
                          <div className=" d-flex flex-row ml-2 mr-5 align-item-start  justify-content-start align-items-center">
                            <img
                              className="imgDol1"
                              src={
                                "https://static.remove.bg/remove-bg-web/bf3af3e882eb04971b4492a1015ef7e77df29362/assets/start_remove-c851bdf8d3127a24e2d137a55b1b427378cd17385b01aec6e59d5d4b5f39d2ec.png"
                              }
                            />
                            <h1 className="text-m font-bold leading-5 ml-3">
                              Kids
                            </h1>
                          </div>
                          <div
                            className="showflex borderBox"
                            style={{
                              fontSize: 15,
                              fontWeight: "500",
                              color: "#13242A",
                              textAlign: "center",
                              cursor: "pointer",
                            }}
                          >
                            100 $
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>

                <div className="mainBoxDivi3"  >
                  <div className="d-flex flex-column align-items-center  ">
                    <div className="w-100 d-flex flex-row align-items-center justify-content-between mb-2 borderBitt">
                      <p
                        style={{
                          fontSize: 15,
                          fontWeight: "500",
                          color: "Black",
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                        className="ml-2"
                      >
                        Artist and Time
                      </p>

                      <p
                        style={{
                          fontSize: 15,
                          fontWeight: "500",
                          color: "#13242A",
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                        className="mr-2"
                      >
                        Change
                      </p>
                    </div>

                    <div className="borderWidit"></div>

                    {[1, 2].map((v, i) => {
                      return (
                        <div className="relative   cursor-pointer  p-1   d-flex flex-row w-100 align-items-center justify-content-between mainBoxing mt-1">
                          <div className=" d-flex flex-row ml-2 mr-5 align-item-start  justify-content-start align-items-center">
                            <img
                              className="imgDol1"
                              src={
                                "https://static.remove.bg/remove-bg-web/bf3af3e882eb04971b4492a1015ef7e77df29362/assets/start_remove-c851bdf8d3127a24e2d137a55b1b427378cd17385b01aec6e59d5d4b5f39d2ec.png"
                              }
                            />

                            <div className="d-flex flex-column align-items-center justify-content-start">
                              <h1 className="text-m font-bold leading-5">
                                Kids
                              </h1>
                              <p
                                className="showflex"
                                style={{
                                  fontSize: 12,
                                  color: "grey",
                                  textAlign: "center",
                                }}
                              >
                                3.4
                                <BsFillStarFill
                                  size={10}
                                  className="ml-1"
                                /> 12{" "}
                              </p>
                            </div>
                          </div>
                          <div
                            className="showflex"
                            style={{
                              fontSize: 15,
                              fontWeight: "500",
                              color: "grey",
                              textAlign: "center",
                              cursor: "pointer",
                            }}
                          >
                            12:03 AM{" "}
                          </div>
                        </div>
                      );
                    })}
                    <div className="receiption mt-2 ">
                      <div className="w-100 d-flex flex-row align-items-center justify-content-between">
                        <p
                          className="showflex"
                          style={{
                            fontSize: 15,
                            fontWeight: "500",
                            color: "grey",
                            textAlign: "center",
                            cursor: "pointer",
                          }}
                        >
                          Sub Total{" "}
                        </p>

                        <p
                          className="showflex"
                          style={{
                            fontSize: 15,
                            fontWeight: "500",
                            color: "grey",
                            textAlign: "center",
                            cursor: "pointer",
                          }}
                        >
                          100 ${" "}
                        </p>
                      </div>

                      <div className="mainBoxing"></div>

                      <div className="w-100 d-flex flex-row align-items-center justify-content-between">
                        <p
                          className="showflex"
                          style={{
                            fontSize: 15,
                            fontWeight: "500",
                            color: "grey",
                            textAlign: "center",
                            cursor: "pointer",
                          }}
                        >
                          Tax And Fees{" "}
                        </p>

                        <p
                          className="showflex"
                          style={{
                            fontSize: 15,
                            fontWeight: "500",
                            color: "grey",
                            textAlign: "center",
                            cursor: "pointer",
                          }}
                        >
                          100 ${" "}
                        </p>
                      </div>

                      <div className="mainBoxing"></div>

                      <div className="w-100 d-flex flex-row align-items-center justify-content-between">
                        <p
                          className="showflex"
                          style={{
                            fontSize: 15,
                            fontWeight: "500",
                            color: "grey",
                            textAlign: "center",
                            cursor: "pointer",
                          }}
                        >
                          Total{" "}
                        </p>

                        <p
                          className="showflex"
                          style={{
                            fontSize: 15,
                            fontWeight: "500",
                            color: "grey",
                            textAlign: "center",
                            cursor: "pointer",
                          }}
                        >
                          100 ${" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-100 d-flex align-items-center justify-content-center mt-4">
                <div className="flex space-x-4">
                  <a href="#">
                    <div
                      onClick={() => {
                        closeModal1();
                        openModal2();
                      }}
                      className="currentNav3"
                    >
                      Continue
                    </div>
                  </a>{" "}
                </div>
              </div>
            </div>
          </div>
        </Animated>
      </Modal>

      {/* Modal 2   */}

      <Modal
        isOpen={modalIsOpen2}
        onRequestClose={closeModal2}
        // style={customStyles}
        contentLabel=" Modal"
        className={"modal1"}
      >
        <Animated
          animationIn="bounceInRight"
          animationOut="bounceOutRight"
          isVisible={div2}
        >
          <div
            className="modaling"
            style={{ width: "100%", height: "100vh", margin: "0%" }}
          >
            <div
              className="shadow-md   
          bg-white        
          d-flex flex-row w-100 flex-wrap  align-items-start justify-content-around
          fixBottom
          "
            >
              <div className="d-flex  justify-content-end w-100">
                <a href="#" style={{ cursor: "pointer" }}>
                  <AiOutlineCloseCircle
                    onClick={() => closeModal2()}
                    // style={{ position: "absolute", top: 15, right: 13 }}
                    size={30}
                    color="#13242A"
                  />
                </a>
              </div>
              <p
                style={{
                  fontSize: "2em",
                  fontWeight: "600",
                  color: "Black",
                  marginTop: 10,
                  width: "100%",
                  textAlign: "center",
                  marginBottom: 10,
                }}
              >
                CheckOut
              </p>

              <div style={{ width: "100%" }}>
                <iframe
                  width="100%"
                  height="400"
                  frameborder="0"
                  scrolling="no"
                  marginheight="0"
                  marginwidth="0"
                  src="https://maps.google.com/maps?width=100%25&amp;height=400&amp;hl=en&amp;q=1%20Grafton%20Street,%20Dublin,%20Ireland+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                >
                  <a href="https://www.maps.ie/distance-area-calculator.html">
                    measure distance on map
                  </a>
                </iframe>
              </div>

              <div className="receiption">
                <p
                  style={{
                    fontSize: 20,
                    fontWeight: "500",
                    color: "grey",
                    textAlign: "left",
                    cursor: "pointer",
                    width: "100%",
                  }}
                  className="ml-1 mt-2"
                >
                  Payment information
                </p>

                <p
                  style={{
                    fontSize: 14,
                    fontWeight: "500",
                    color: "grey",
                    textAlign: "left",
                    cursor: "pointer",
                    width: "100%",
                  }}
                  className="ml-2 mt-2"
                >
                  Add a Tip
                </p>

                <div className="d-flex flex-row align-items-center justify-content-between flex-wrap">
                  {[0,1,2,3,4,5].map((v, i) => {
                    return (
                      <div className="horizontalViewdiv2 mt-1 ml-1">
                        {Math.round(Math.floor(Math.random() * 100))} $
                      </div>
                    );
                  })}
                </div>

                <div className="w-full d-flex flex-row align-items-center justify-content-between mt-2 pl-1 pr-1">
                  <p
                    className="showflex"
                    style={{
                      fontSize: 15,
                      fontWeight: "500",
                      color: "grey",
                      textAlign: "center",
                    }}
                  >
                    Total
                  </p>

                  <p className="showflex purpleText mr-2">100 $</p>
                </div>

                <div className="w-full d-flex flex-row align-items-center justify-content-between mt-2 pl-1 pr-1">
                  <p
                    className="showflex"
                    style={{
                      fontSize: 15,
                      fontWeight: "500",
                      color: "grey",
                      textAlign: "center",
                    }}
                  >
                    Services
                  </p>

                  <p className="showflex purpleText">
                    Kids
                    <FaAngleRight className="ml-1 " size={15} />
                  </p>
                </div>

                <div className="w-full d-flex flex-row align-items-center justify-content-between mt-2 pl-1 pr-1">
                  <p
                    className="showflex"
                    style={{
                      fontSize: 15,
                      fontWeight: "500",
                      color: "grey",
                      textAlign: "center",
                    }}
                  >
                    Add Promo Code
                  </p>

                  <p className="showflex purpleText">
                    Apply Code
                    <FaAngleRight className="ml-1 " size={15} />
                  </p>
                </div>

                <div className="w-full d-flex flex-row align-items-center justify-content-between mt-2 pl-1 pr-1">
                  <p
                    className="showflex"
                    style={{
                      fontSize: 15,
                      fontWeight: "500",
                      color: "grey",
                      textAlign: "center",
                    }}
                  >
                    Add Payment Method
                  </p>

                  <p className="showflex purpleText">
                    Select Method
                    <FaAngleRight className="ml-1 " size={15} />
                  </p>
                </div>
              </div>

              <div className="w-100 d-flex align-items-center justify-content-center mt-4">
                <div className="flex space-x-4">
                  <a href="#">
                    <div
                      className="currentNav3"
                      onClick={() => {
                        closeModal2();
                        openModal3();
                      }}
                    >
                      Continue
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Animated>
      </Modal>

      {/* Modal 3   */}

      <Modal
        isOpen={modalIsOpen3}
        onRequestClose={closeModal3}
        // style={customStyles}
        contentLabel=" Modal"
        className={"modal1"}
      >
        <Animated
          animationIn="bounceInRight"
          animationOut="bounceOutRight"
          isVisible={div3}
        >
          <div
            className="modaling"
            style={{ width: "100%", height: "100vh", margin: "0%" }}
          >
            <div
              className="shadow-md   
          bg-white        
          d-flex flex-row w-100 flex-wrap  align-items-start justify-content-around
          fixBottom
          "
            >
              <div className="d-flex  justify-content-end w-100">
                <a href="#" style={{ cursor: "pointer" }}>
                  <AiOutlineCloseCircle
                    onClick={() => closeModal3()}
                    // style={{ position: "absolute", top: 15, right: 13 }}
                    size={30}
                    color="#13242A"
                  />
                </a>
              </div>
              <p
                style={{
                  fontSize: "2em",
                  fontWeight: "600",
                  color: "Black",
                  marginTop: 10,
                  width: "100%",
                  textAlign: "center",
                }}
              >
                Select Payment
              </p>

              <font className="heading_main_map_font11 font-bold  mt-10">
                Add Payment Method
              </font>

              <div className="flex lookBox row">
                <div
                  onClick={() => {
                    closeModal3();
                    openModal4();
                  }}
                  className="relative rounded-md p-3 hover:bg-gray-100 cursor-pointer shadow p-3 mb-5 bg-white rounded"
                >
                  <div className="showflex">
                    <BsFillCreditCard2FrontFill size={30} color={"#13242A"} />
                    <font className="heading_main_map_font11 font-bold ml-10">
                      Credit / Debit Card
                    </font>
                  </div>
                </div>
                <div
                  onClick={() => navigate("/map")}
                  className="relative rounded-md p-3 hover:bg-gray-100 cursor-pointer shadow p-3 mb-5 bg-white rounded"
                >
                  <div className="showflex">
                    <BsFillCreditCard2FrontFill size={30} color={"#13242A"} />
                    <font className="heading_main_map_font11 font-bold ml-10">
                      Google Pay
                    </font>
                  </div>
                </div>
              </div>

              <div
                role={"button"}
                className="bg-white  d-flex justify-content-around align-start flex-wrap  flex-column"
              >
                <div className="w-96 h-56  bg-red-100 rounded-xl relative text-white shadow-2xl mt-3 ">
                  <img
                    className="relative object-cover w-full h-full rounded-xl"
                    src="https://i.imgur.com/kGkSg1v.png"
                  />

                  <div className="w-full px-8 absolute top-8">
                    <div className="flex justify-between">
                      <div className="">
                        <p className="font-light">Name</p>
                        <p className="font-medium tracking-widest">Karthik P</p>
                      </div>
                      <img
                        className="w-14 h-14"
                        src="https://i.imgur.com/bbPHJVe.png"
                      />
                    </div>
                    <div className="pt-1">
                      <p className="font-light">Card Number</p>
                      <p className="font-medium tracking-more-wider">
                        4642 3489 9867 7632
                      </p>
                    </div>
                    <div className="pt-6 pr-6">
                      <div className="flex justify-between">
                        <div className="">
                          <p className="font-light text-xs">Valid</p>
                          <p className="font-medium tracking-wider text-sm">
                            11/15
                          </p>
                        </div>
                        <div className="">
                          <p className="font-light text-xs text-xs">Expiry</p>
                          <p className="font-medium tracking-wider text-sm">
                            03/25
                          </p>
                        </div>

                        <div className="">
                          <p className="font-light text-xs">CVV</p>
                          <p className="font-bold tracking-more-wider text-sm">
                            ···
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="w-96 h-56  bg-red-100 rounded-xl relative text-white shadow-2xl mt-3">
                  <img
                    className="relative object-cover w-full h-full rounded-xl"
                    src="https://i.imgur.com/Zi6v09P.png"
                  />

                  <div className="w-full px-8 absolute top-8">
                    <div className="flex justify-between">
                      <div className="">
                        <p className="font-light">Name</p>
                        <p className="font-medium tracking-widest">Karthik P</p>
                      </div>
                      <img
                        className="w-14 h-14 "
                        style={{ objectFit: "fill", width: 60, height: 25 }}
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5e/Visa_Inc._logo.svg/2560px-Visa_Inc._logo.svg.png"
                      />
                    </div>
                    <div className="pt-1">
                      <p className="font-light">Card Number</p>
                      <p className="font-medium tracking-more-wider">
                        4642 3489 9867 7632
                      </p>
                    </div>
                    <div className="pt-6 pr-6">
                      <div className="flex justify-between">
                        <div className="">
                          <p className="font-light text-xs">Valid</p>
                          <p className="font-medium tracking-wider text-sm">
                            11/15
                          </p>
                        </div>
                        <div className="">
                          <p className="font-light text-xs text-xs">Expiry</p>
                          <p className="font-medium tracking-wider text-sm">
                            03/25
                          </p>
                        </div>

                        <div className="">
                          <p className="font-light text-xs">CVV</p>
                          <p className="font-bold tracking-more-wider text-sm">
                            ···
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-100 d-flex align-items-center justify-content-center mt-4">
                <div className="flex space-x-4">
                  <a href="#">
                    <div
                      className="currentNav3"
                      onClick={() => {
                        closeModal3();
                        openModal4();
                      }}
                    >
                      Continue
                    </div>
                  </a>{" "}
                </div>
              </div>
            </div>
          </div>
        </Animated>
      </Modal>

      {/* Modal 4   */}

      <Modal
        isOpen={modalIsOpen4}
        onRequestClose={closeModal4}
        // style={customStyles}
        contentLabel=" Modal"
        className={"modal1"}
      >
        <Animated
          animationIn="bounceInRight"
          animationOut="bounceOutRight"
          isVisible={div4}
        >
          <div
            className="modaling"
            style={{ width: "100%", height: "100vh", margin: "0%" }}
          >
            <div
              className="shadow-md   
          bg-white        
          d-flex flex-row w-100 flex-wrap  align-items-start justify-content-around
          fixBottom
          "
            >
              <div className="d-flex  justify-content-end w-100">
                <a href="#" style={{ cursor: "pointer" }}>
                  <AiOutlineCloseCircle
                    onClick={() => closeModal4()}
                    // style={{ position: "absolute", top: 15, right: 13 }}
                    size={30}
                    color="#13242A"
                  />
                </a>
              </div>

              <div className="heading_main_mapbet">
                <font className="heading_main_map_font22 text-lg">
                  Add Debit / Credit Card
                </font>
              </div>

              <div className="flex lookBox row cardInputs mt-2">
                <Cards
                  cvc={csv}
                  expiry={expiry}
                  name={name}
                  number={number}
                  focused={`${focus}`}
                />

                <div className="relative rounded-md p-3 hover:bg-gray-100 cursor-pointer shadow p-3 mb-5 bg-white rounded d-flex align-center justify-content-between">
                  <font
                    style={{
                      fontSize: 14,
                      fontWeight: "500",
                      color: "#13242A",
                    }}
                  >
                    Name
                  </font>
                  <input
                    onFocus={() => {
                      setFocus("name");
                    }}
                    onChange={(e) => setName(e.target.value)}
                    className="creditInput"
                  />
                </div>

                <div className="relative rounded-md p-3 hover:bg-gray-100 cursor-pointer shadow p-3 mb-5 bg-white rounded d-flex align-center justify-content-between">
                  <font
                    style={{
                      fontSize: 14,
                      fontWeight: "500",
                      color: "#13242A",
                    }}
                  >
                    Number
                  </font>
                  <input
                    maxLength={16}
                    onFocus={() => {
                      setFocus("number");
                    }}
                    onChange={(e) => setNumber(e.target.value)}
                    className="creditInput"
                  />
                </div>

                <div className="relative rounded-md p-3 hover:bg-gray-100 cursor-pointer shadow p-3 mb-5 bg-white rounded d-flex align-center justify-content-between">
                  <font
                    style={{
                      fontSize: 14,
                      fontWeight: "500",
                      color: "#13242A",
                    }}
                  >
                    Date
                  </font>
                  <input
                    maxLength={6}
                    onFocus={() => {
                      setFocus("expiry");
                    }}
                    onChange={(e) => setexpiry(e.target.value)}
                    className="creditInput"
                  />
                </div>

                <div
                  className="relative rounded-md p-3 hover:bg-gray-100 cursor-pointer shadow p-3 mb-5 bg-white rounded d-flex align-center justify-content-between"
                  style={{ width: 300 }}
                >
                  <font
                    style={{
                      fontSize: 14,
                      fontWeight: "500",
                      color: "#13242A",
                    }}
                  >
                    CVC
                  </font>

                  <input
                    maxLength={4}
                    className="creditInput"
                    onFocus={() => {
                      setFocus("cvc");
                    }}
                    onChange={(e) => {
                      setCsv(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="w-100 d-flex align-items-center justify-content-center mt-4">
                <div className="flex space-x-4">
                  <a href="#">
                    <div
                      className="currentNav3"
                      onClick={() => {
                        closeModal4();
                      }}
                    >
                      Continue
                    </div>
                  </a>{" "}
                </div>
              </div>
            </div>
          </div>
        </Animated>
      </Modal>
    </>
  );
}
