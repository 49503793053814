import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Navbar from "./Sub Component/Navbar";
import Carousel from "react-multi-carousel";
import { FiChevronLeft } from "react-icons/fi";
import "react-multi-carousel/lib/styles.css";
import "aos/dist/aos.css"; // You can also use <link> for styles
import Aos from "aos";
import { useLocation, useNavigate } from "react-router-dom";

Aos.init();
function Categories(props) {
  let navigate = useNavigate();
  const params = useLocation();
  let [addresshow, setAddressshow] = useState("");
  let [loading, setLoading] = useState(true);

  useEffect(() => {

    
      

    if (localStorage.getItem('Selected_location')) {
      // setAddressshow(JSON.parse(localStorage.getItem('Selected_location')).addrs);
      setLoading(false);
    }
  });

  let gotoHome = (v, addresshow) => {
    navigate("/Home");


    localStorage.setItem('Searched_History', JSON.stringify({
      
      link: v,
      searchingText: addresshow
    }))
  };
  return (
    <>
      {loading ? (
        <div className="loadingOpac" id="loadingOpac">
          <img src={require("../assets/loading.gif")} className="loadingIMG" />
        </div>
      ) : (
        ""
      )}
      {/* Navbar  */}
      <Navbar Login={false} />

      <div className="catagries_div ">
        <div className="pt-4 pl-2 pr-1  pb-3">
          <div className="inputmapBox mb-2 pl-3">
            <FiChevronLeft size={25} color={"#13242a"} onClick={() => navigate("/map")} />

            {/* <i className="fa fa-chevron-left text-gray-900   mapsearchbari"></i> */}
            <input
              placeholder="What can we get you?"
              className=" mapsearchbar fontPopin bg-white"
              // disabled
              value={addresshow}
              onChange={e => setAddressshow(e.target.value)}
            />
          </div>
        </div>

        <div className="main-categories categoriestop">
          <div
            className="categories-main-box d-flex align-items-center justify-content-center flex-column mt-3"
            onClick={() => gotoHome("Haircut", addresshow)}
          >
            <div className="categories-box">
              <img src={require("../assets/haircut@3x.png")} />
            </div>
            <p className="font-bold text-black text-sm mt-1.5 fontPopin">
              Haircuts
            </p>
          </div>

          <div
            className="categories-main-box d-flex align-items-center justify-content-center flex-column mt-3"
            onClick={() => gotoHome("Massage", addresshow)}
          >
            <div className="categories-box">
              <img src={require("../assets/body-massage@3x.png")} />
            </div>
            <p className="font-bold text-black text-sm mt-1.5 fontPopin">
              Massage
            </p>
          </div>

          <div
            className="categories-main-box d-flex align-items-center justify-content-center flex-column mt-3"
            onClick={() => gotoHome("Nails", addresshow)}
          >
            <div className="categories-box">
              <img src={require("../assets/nail-polish@3x.png")} />
            </div>
            <p className="font-bold text-black text-sm mt-1.5 fontPopin">
              Nails
            </p>
          </div>
        </div>
        <div className="main-categories">
          <div
            className="categories-main-box d-flex align-items-center justify-content-center flex-column mt-3"
            onClick={() => gotoHome("Salon", addresshow)}
          >
            <div className="categories-box">
              <img src={require("../assets/hair-cutting@3x.png")} />
            </div>
            <p className="font-bold text-black text-sm mt-1.5 fontPopin">
              Salon
            </p>
          </div>

          <div
            className="categories-main-box d-flex align-items-center justify-content-center flex-column mt-3"
            onClick={() => gotoHome("Kids", addresshow)}
          >
            <div className="categories-box">
              <img src={require("../assets/daughter@3x.png")} />
            </div>
            <p className="font-bold text-black text-sm mt-1.5 fontPopin">
              Kids
            </p>
          </div>

          <div
            className="categories-main-box d-flex align-items-center justify-content-center flex-column mt-3"
            onClick={() => gotoHome("Spa", addresshow)}
          >
            <div className="categories-box">
              <img src={require("../assets/spa@3x.png")} />
            </div>
            <p className="font-bold text-black text-sm mt-1.5 fontPopin">Spa</p>
          </div>
        </div>
      </div>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    name: state.name,
  };
};

export default connect(mapStateToProps, null)(Categories);
