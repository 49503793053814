import React, { useState, useEffect } from "react";
import OTPInput from "otp-input-react";
import BackImage from "../../assets/login.jpg";
import "aos/dist/aos.css"; // You can also use <link> for styles
import Aos from "aos";
import { IoCloseOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

Aos.init();
export default function LoginInfoForm(props) {
  let navigate = useNavigate();

  const [OTP, setOTP] = useState("");
  let [number1, setNumber1] = useState(sessionStorage.getItem("otpNumber"));
  let [code, setCode] = useState(false);
  let [error, setErrorMesseg] = useState("");
  console.log(props);
  let [edit, setEdit] = useState(false);

  useEffect(() => {
    document.body.style.background = `url(${BackImage})`;

    console.log(document);
  }, []);

  let VerifyME = () => {
    // console.log("verifing in process");
    setErrorMesseg("");
    // document.getElementById("animation_show_ha").style.display = "flex";

    localStorage.setItem("number", "+42342343");

    // if (OTP == "") {
    //   setErrorMesseg("Please Fill All Field's");
    //   document.getElementById("animation_show_ha").style.display = "none";
    //   return;
    // }
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <div
        id="animation_show_ha"
        style={{
          position: "absolute",
          backgroundColor: "rgb(23, 19, 54,0.8)",
          width: "100%",
          display: "none",
          justifyContent: "center",
          alignItems: "center",
          zIndex: "22",
          height: "100%",
          marginTop: "0px",
        }}
      >
        <div className="loading">Loading&#8230;</div>
      </div>

      <div className="content">
        <div
          className="formDiv1"
          data-aos="flip-left"
          data-aos-duration="1500"
          data-aos-once="true"
        >
          <IoCloseOutline
            onClick={() => navigate("/")}
            color="#fff"
            size={30}
            className="backArrow"
          />
          <h2 className="head">Confirmatation Form</h2>

          <p
            className="pirat"
            style={{
              textAlign: "left",
              color: "#fff",
              fontSize: 13,
              marginTop: 20,
              marginLeft:'14%'
            }}
          >
            Enter Email Address
          </p>

          <input
            className="editNumber"
            onChange={(e) =>
              sessionStorage.setItem("otpNumber", e.target.value)
            }
            style={{ width: 260,  }}
            placeholder="+1 (801) 123 12321"
          />


<p
            className="pirat"
            style={{
              textAlign: "left",
              color: "#fff",
              fontSize: 13,
              marginTop: 20,
              marginLeft:'14%'
            }}
          >
            Enter User Name
          </p>

          <input
            className="editNumber"
            onChange={(e) =>
              sessionStorage.setItem("otpNumber", e.target.value)
            }
            style={{ width: 260,  }}
            placeholder="+1 (801) 123 12321"
          />
          <div className="d-flex">
            <button
              onClick={() => {
                navigate("/");
              }}
              className="buttonMe"
            >
              Back
            </button>
            <button
              onClick={() => {
                navigate("/");
              }}
              className="buttonMe ml-3"
            >
            Complete
            </button>
          </div>

          <p
            style={{
              textAlign: "center",
              width: "80%",
              color: "red",
              fontSize: 12,
            }}
          >
            {error}
          </p>
        </div>
      </div>
    </div>
  );
}
