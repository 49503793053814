import React, { Component } from "react";

import Home from "../component/Home";
import ItemDetails from '../component/itemdetail'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import DailyDealDetails from '../component/DailyDealDetails';
import Profile from '../component/profile';
import Address from '../component/Address';
import Map from '../component/map.js';
import Authuntication from "../component/authuntication";
import Payments from '../component/payments';
import PhoneInput from '../component/Sub Component/phoneInput';
import LoginInfoForm from '../component/Sub Component/LoginInforForm';
import GroupOrder from '../component/GroupOrder';
import Support from '../component/support';
import Order from '../component/order';
import Main from '../component/main';
import Availbility from '../component/App_PlayStore';
import Chats from '../component/chats/Chats';
import Categories from '../component/Categories'
class RouterLink extends Component {
  render() {
    return (
      <Routes>

        <Route path="/" element={<Main />} exact />
        <Route path="/Home" element={<Home />} />
        <Route path="/Home:id" element={<Home />} />
        <Route path="/Details" element={<ItemDetails />} exact />
        <Route path="/Chats" element={<Chats />} exact />
        <Route path="/Categories" element={<Categories />} exact />

        {/*
        <Route path="/DailyDealDetails" element={<DailyDealDetails />} exact /> */}
        <Route path="/Profile" element={<Profile />} exact />
        <Route path="/Address" element={<Address />} exact />
        <Route path="/Map" element={<Map />} exact />
        <Route path="/Authuntication" element={<Authuntication />} exact />
        <Route path="/Payments" element={<Payments />} exact />
        <Route path="/PhoneInput" element={<PhoneInput />} exact />
        <Route path="/LoginInfoForm" element={<LoginInfoForm />} exact />
        <Route path="/GroupOrder" element={<GroupOrder />} exact />
        <Route path="/Support" element={<Support />} exact />
        <Route path="/Order" element={<Order />} exact />
        <Route path="/AvailableApps" element={<Availbility />} exact />

      </Routes>
    );
  }
}

export default RouterLink;
