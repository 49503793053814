import React from "react";
import { connect } from "react-redux";
import Navbar from "../component/Sub Component/NavbarBack";
import "aos/dist/aos.css"; // You can also use <link> for styles
import Aos from "aos";
import Footer from "../component/Sub Component/footer";
import "react-multi-carousel/lib/styles.css";



Aos.init();

function Support(props) {
  return (
    <>
      <div className="mainNav">
      <Navbar back="/" />
       
      </div>



    <div className="contact55">
      <div className="contact3 py-5 box">
  <div className="row no-gutters">
    <div className="container">
      <div className="row">
        <div className="col-lg-6">
            <img
            
              src="https://www.nonotes.com/assets/img/contact-img.svg"
              style={{borderRadius:5}}
            />
        </div>
        <div className="col-lg-6" >
          <div className="contact-box ml-3">
            <h1 className="groupOrderHEading">Need Help</h1>

            <p>Most of your Question Should be answer here</p>
            <form className="mt-4">
              <div className="row">
               
                <div className="col-lg-12">
                <p  style={{width:'100%',textAlign:'left',fontWeight:'bold'}}>Still need Help? Write to Us Below</p>
                <p  style={{width:'100%',textAlign:'left'}}>Our Agent Will Contact you ar the earlest</p>

                  <div className="form-group mt-2">

                    <textarea
                      className="form-control"
                      rows={3}
                      placeholder="Please Describe in detail"
                      defaultValue={""}
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <button
                    type="submit"
                    className="btn btn-danger-gradiant mt-3 text-white border-0 px-3 py-2"
                  >
                    <span> SUBMIT</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
   
      </div>
    </div>
  </div>
</div>
</div>
     

      <Footer />
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    name: state.name,
  };
};

export default connect(mapStateToProps, null)(Support);
