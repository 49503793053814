import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

 
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA8PRhDRMmbo_lM5kislnNhorxMr_p1CiE",
  authDomain: "cruuz-inc.firebaseapp.com",
  databaseURL: "https://cruuz-inc.firebaseio.com",
  projectId: "cruuz-inc",
  storageBucket: "cruuz-inc.appspot.com",
  messagingSenderId: "312374299941",
  appId: "1:312374299941:web:17b8be05dccdd722685ecc",
  measurementId: "G-TB331G3828"
};
 
const secondaryApp = initializeApp(firebaseConfig);

const analytics = getAnalytics(secondaryApp);
// const database = getDatabase(app);
// const auth = getAuth(app);
// const storage = getStorage(app);

  export  {secondaryApp,analytics};
  