/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { BellIcon, MenuIcon, XIcon } from "@heroicons/react/outline";
import "../../assets/style.css";
import "aos/dist/aos.css"; // You can also use <link> for styles
import Aos from "aos";
import {IoMdArrowBack} from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
Aos.init();


export default function Navbar(Props) {
  let navigate =useNavigate()

  return (
    <Disclosure as="nav"  className={"mainBar"}>
        <>
          <div  className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
              <div onClick={()=>navigate(Props.back)} style={{position:'absolute',left:10,top:12,backgroundColor:'rgba(211, 210, 211, 0.44)',borderRadius:100,padding:5,cursor:'pointer',zIndex:10}}>
      < IoMdArrowBack size={30} color='#fff' />
    </div>
            <div className="w-full relative d-flex items-center justify-center h-16">
            <h1 style={{color:'#fff'}} className="font-bold text-2xl ">
             {Props.heading?
                Props.heading:
                ''
              }
             </h1>
             
              
            </div>
          </div>
       
             
        </>
    </Disclosure>
  );
}
