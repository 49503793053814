/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { BellIcon, MenuIcon, XIcon } from "@heroicons/react/outline";
import "../../assets/style.css";
import "aos/dist/aos.css"; // You can also use <link> for styles
import Aos from "aos";
import {IoMdArrowBack} from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
Aos.init();

const navigation1 = [
  { name: "Logout", href: "#", current: true, filled: true },
];

export default function Navbar(Props) {
  let navigate =useNavigate()

  return (
    <Disclosure as="nav"  className={"mainBar"}>
        <>
          <div  className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
              <div onClick={()=>navigate(Props.back)} style={{position:'absolute',left:20,top:12,backgroundColor:'rgba(211, 210, 211, 0.44)',borderRadius:100,padding:5,cursor:'pointer'}}>
      < IoMdArrowBack size={30} color='#fff' />
    </div>
            <div className="relative flex items-center justify-between h-16">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
             
              </div>
              
            </div>
          </div>
          <div className="absolute right-10 top-3">
                  <div className="flex space-x-4">
                    {navigation1.map((item) => (
                      <button
                      data-aos="fade-left"
                      data-aos-duration="800"
                        key={item.name}
                        href={item.href}
                        className={"btn  btn-danger"}
                        aria-current={item.current ? "page" : undefined}
                      >
                        {item.name}
                      </button>
                    ))}
                  </div>
                </div>
             
        </>
    </Disclosure>
  );
}
