import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Navbar from "./Sub Component/Navbar";
import Carousel from "react-multi-carousel";
import { FiChevronLeft } from "react-icons/fi";
import "react-multi-carousel/lib/styles.css";
import "aos/dist/aos.css"; // You can also use <link> for styles
import Aos from "aos";
import { useNavigate } from "react-router-dom";
import Autocomplete from "react-google-autocomplete";

import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";

Aos.init();
function Map(props) {
  let navigate = useNavigate();
  const [address, setaddress] = useState("");
  const [lati, setlati] = useState();
  const [lng, setlng] = useState();

  const handleChange = (addres) => {
    setaddress(addres);
    console.log("awa>>>>>>>>>>>");
  };

  const handleSelect = (address) => {
    setaddress(address);
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        setlati(latLng.lat);
        setlng(latLng.lng);
        console.log("Success", latLng);
        latLng.addrs=address
        // if (JSON.parse(localStorage.getItem('user_location')).length === 0 || localStorage.getItem('user_location') == null) {

          localStorage.setItem('user_location',JSON.stringify([latLng.lat,latLng.lng]))
        // }


        if (localStorage.getItem('RefredUrl') && localStorage.getItem('RefredUrl').includes('&Item')) {
          navigate(`/Home${localStorage.getItem('RefredUrl')}`)
          return

        }

        localStorage.setItem('Selected_location',JSON.stringify(latLng))

        navigate("/Categories");
      })
      .catch((error) => console.error("Error", error));
  };
  useEffect(() => {

// if(!localStorage.getItem('_id')){
// navigate('/')
// }


  });

  return (
    <>
      {/* Navbar  */}
      <Navbar Login={false} />

      <div className="catagries_div ">
        <div className="pt-4 pl-2 pr-1  pb-3">
          {/* <div className="mb-4">
    <label for="default-input" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Where are you located?</label>
    <input placeholder="main street uk" type="text" id="default-input" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
</div> */}

          <PlacesAutocomplete
            value={address}
            onChange={(e) => {
              handleChange(e);
            }}
            onSelect={(e) => {
              handleSelect(e);
            }}
          >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
              <div>
                <div className="inputmapBox mb-2 pl-3">
                  <FiChevronLeft size={27} color={"#13242a"} onClick={() => {
                    // localStorage.removeItem('Searched_History')
                    // localStorage.removeItem('Selected_location')
                    navigate("/")
                  }} />
                  {/* <i className="fa fa-chevron-left text-gray-900   mapsearchbari"></i> */}
                  <input
                    {...getInputProps({
                      placeholder: "Enter a location",
                      className: " mapsearchbar fontPopin",
                    })}
                  />
                </div>

                <div style={{ boxShadow: "2px 2px 5px 0px rgba(140,125,125,0.75)" }} className="autocomplete-dropdown-container">
                  {loading && <div>Loading...</div>}
                  {suggestions.map((suggestion) => {
                    console.log(suggestions.length);
                    const className = "sugesstionBox fontPopin";
                    // inline style for demonstration purpose
                    const style = {
                      cursor: "pointer",
                    };
                    // const onClick = () => )
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style,
                          // onClick
                        })}
                      >
                        {suggestion.description}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
          {/* 
          <div className="sugesstionBox" onClick={() => navigate('/Home')}>
            I-8,Islamabad, Pakistan

          </div>
          <div className="sugesstionBox" onClick={() => navigate('/Home')}>
            I-80, Swedard, NE, USA

          </div> */}

          {/* <div className="catagevb w-100 mt-2 rounded-0 ">
            <iframe width="100%" height="480" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=islamadb+(My%20Business%20Name)&amp;t=&amp;z=17&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.maps.ie/distance-area-calculator.html">measure area map</a></iframe></div> */}
        </div>
      </div>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    name: state.name,
  };
};

export default connect(mapStateToProps, null)(Map);
