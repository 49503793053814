import React, { useEffect,useState } from "react";
import { connect } from "react-redux";
import Navbar from "./Sub Component/NavbarBack";
import "react-multi-carousel/lib/styles.css";
import "aos/dist/aos.css"; // You can also use <link> for styles
import Aos from "aos";
import { useNavigate } from "react-router-dom";
import {BsClockHistory} from 'react-icons/bs';
import {TbClock} from 'react-icons/tb';
import {MdOutlineFavoriteBorder} from 'react-icons/md';
import { GetOrder } from "../store/acion";
import { display } from "@mui/system";
Aos.init();
function Order({All_Order,GetOrder}) {
  let navigate = useNavigate();
  let [loading, setLoading] = useState(true);
  let [upcoming, setupcoming] = useState([]);
  let [past, setpast] = useState([]);
  let [favourite, setfavourite] = useState([]);
  let [upcomingShow, setupcomingShow] = useState('flex');
  let [pastShow, setpastShow] = useState('none');
  let [favouriteShow, setfavouriteShow] = useState('none');


  useEffect(()=>{
    if(All_Order==undefined){

      GetOrder(setLoading,navigate)
    }else{
      const {data}=All_Order
        setLoading(false)
        setfavourite(data?.favourite)
        setpast(data?.past)
        setupcoming(data?.pending)
    }
    console.log(">>v>>f>>>",All_Order);
  })
  
  const past_f=()=>{
    setpastShow('flex')
   setupcomingShow('none')
   setfavouriteShow('none')

  }
  const upcoming_f=()=>{
    setpastShow('none')
    setupcomingShow('flex')
    setfavouriteShow('none')
 
   }
   const favourite_f=()=>{
    setpastShow('none')
    setupcomingShow('none')
    setfavouriteShow('flex')
 
   }

   const status=(status)=>{
   
    if (status=='awaiting-acceptance' || status=='awaiting acceptance' )
    {
      return'Sheduled'
    }else{
     return status

   }
  }
  return (
    <>
   
   <Navbar back='/' heading='Orders' /> 
   {loading ? (
        <div className="loadingOpac" id="loadingOpac">
          <img src={require("../assets/loading.gif")} className="loadingIMG" />
        </div>
      ) : (
       

   <div>
        <link href="https://fonts.googleapis.com/css?family=Work+Sans:400,600,700&display=swap" rel="stylesheet" />
        <div className="layout">
          <input name="nav" type="radio" className="nav home-radio" id="home" defaultChecked="checked" />
          <div className="page home-page">
            <div className="catagries_div mainFlex pt-2 ">
              
            {upcoming?.length!=0
   ?          
upcoming?.map((v,i)=>{
     return(
      <div  
      className=" iteMWid relative   cursor-pointer  p-1    flex-row  align-items-center justify-content-between mainBoxing mt-1 mb-2"
      style={{display:upcomingShow}} >
      <div className=" d-flex flex-row ml-2 mr-5 align-item-start  justify-content-start align-items-center" >
       <BsClockHistory size={30} className='ml-2 mr-2 h-16' />
        <div className="d-flex flex-column align-items-start justify-content-start">
          <div style={{display:'flex',flexDirection:'row'}}>
          {v.names.map((v2,i)=>
          <>
          <h1 
          className="text-m font-bold leading-5 marginleft4">
            {v2}
            </h1>
            {
             
             i!=v.names.length-1&&
             <h1 
              className="text-m font-bold leading-5">
                , 
            </h1>
            }
            </>
          )}
          </div>
          <p
            className="showflex"
            style={{
              fontSize: 12,
              color: "grey",
              textAlign: "center",
            }}
          >
            {v.date} . {v.time} {v.timeslot}
          </p>
        </div>
      </div>
     
      <div
        className="showflex"
        style={{
          fontSize: 15,
          fontWeight: "500",
          color: "grey",
          textAlign: "center",
          cursor: "pointer",
        }}
      >
        {v.totalbill.toFixed(2)} $
        <br></br>
        <p className="status"
        style={{color:'awaiting-acceptance'?'green':'red'}}>
          {status(v.status)}
          </p>
      </div>
   
    
    </div>
    )
})
   
   :
   <div className="no-Search">
              <img src={require('../assets/nosearch.png')} />

              <p className="noSearchHeading">
                No Order found


              </p>
              <div
                            onClick={() => navigate('/')}
                            className="w-40 d-flex align-items-center justify-content-center mt-3"
                          >
                            <button
                              type="button"
                              className=" paymentButton d-flex flex-row align-items-center justify-content-center text-white bg-black  font-medium rounded-lg   py-2 text-center mr-2 mb-2  shadow-md  fw-600  text-base"

                            >
                              Place Order
                            </button>
                          </div>

              {/* <p className="nosearchpara">
                Please try another search
              </p> */}
            </div>
   }
            
            </div>
          </div>
          <label 
          onClick={upcoming_f}
          className="nav" htmlFor="home">
            <span>
              {/* <svg viewBox="0 0 24 24" width={24} height={24} stroke="currentColor" strokeWidth={2} fill="none" strokeLinecap="round" strokeLinejoin="round" className="css-i6dzq1"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" /><polyline points="9 22 9 12 15 12 15 22" /></svg> */}
              Upcocming 
            </span>
          </label>
          <input name="nav" type="radio" className="about-radio" id="about" />
          <div className="page about-page">
           <div className="catagries_div mainFlex pt-2">
              
           {past?.length!=0
   ?          
past?.map((v,i)=>{
     return(
      <div  
      className=" iteMWid relative   cursor-pointer  p-1    flex-row  align-items-center justify-content-between mainBoxing mt-1 mb-2"
      style={{display:pastShow}} >
      <div className=" d-flex flex-row ml-2 mr-5 align-item-start  justify-content-start align-items-center" >
       <BsClockHistory size={30} className='ml-2 mr-2 h-16' />

        <div className="d-flex flex-column align-items-start justify-content-start">
          {v.names.map((v)=>
          <h1 
          className="text-m font-bold leading-5">
            {v}
            </h1>
          )}
          <p
            className="showflex"
            style={{
              fontSize: 12,
              color: "grey",
              textAlign: "center",
            }}
          >
            {v.date} . {v.time} {v.timeslot}
          </p>
        </div>
      </div>
      <div
        className="showflex"
        style={{
          fontSize: 15,
          fontWeight: "500",
          color: "grey",
          textAlign: "center",
          cursor: "pointer",
        }}
      >
        {v.totalbill.toFixed(2)} $
        <p className="status"
        style={{color:'awaiting-acceptance'?'green':'red'}}>
          {status(v.status)}
          </p>
      </div>
    </div>
    )
})
   
   :
   <div className="no-Search">
              <img src={require('../assets/nosearch.png')} />

              <p className="noSearchHeading">
                No Past Order found


              </p>

              {/* <p className="nosearchpara">
                Please try another search
              </p> */}
            </div>
   }
              
            </div>
          </div>
          <label 
          onClick={past_f} 
          className="nav" 
          htmlFor="about">
            <span>
              {/* <svg viewBox="0 0 24 24" width={24} height={24} stroke="currentColor" strokeWidth={2} fill="none" strokeLinecap="round" strokeLinejoin="round" className="css-i6dzq1"><circle cx={12} cy={12} r={10} /><path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" /><line x1={12} y1={17} x2={12} y2={17} /></svg> */}
              Past
            </span>
          </label>
          <input name="nav" type="radio" className="contact-radio" id="contact" />
          <div className="page contact-page">
          <div className="catagries_div mainFlex pt-2">
   {favourite?.length!=0
   ?          
favourite?.map((v,i)=>{
     return(
      <div  
      className=" iteMWid relative   cursor-pointer  p-1    flex-row  align-items-center justify-content-between mainBoxing mt-1 mb-2"
      style={{display:favouriteShow}} >
      <div className=" d-flex flex-row ml-2 mr-5 align-item-start  justify-content-start align-items-center" >
       <BsClockHistory size={30} className='ml-2 mr-2 h-16' />

        <div className="d-flex flex-column align-items-start justify-content-start">
          {v.names.map((v)=>
          <h1 
          className="text-m font-bold leading-5">
            {v}
            </h1>
          )}
          <p
            className="showflex"
            style={{
              fontSize: 12,
              color: "grey",
              textAlign: "center",
            }}
          >
            {v.date} . {v.time} {v.timeslot}
          </p>
        </div>
      </div>
      <div
        className="showflex"
        style={{
          fontSize: 15,
          fontWeight: "500",
          color: "grey",
          textAlign: "center",
          cursor: "pointer",
        }}
      >
        {v.totalbill.toFixed(2)} $
        <p className="status"
        style={{color:'awaiting-acceptance'?'green':'red'}}>
          {status(v.status)}
          </p>
      </div>
    </div>
    )
})
   
   :
   <div className="no-Search">
              <img src={require('../assets/nosearch.png')} />

              <p className="noSearchHeading">
                No Favourite found


              </p>

              {/* <p className="nosearchpara">
                Please try another search
              </p> */}
            </div>
   }             
            </div>
          </div>
          <label
          onClick={favourite_f}
           className="nav" 
           htmlFor="contact">
            <span>
              {/* <svg viewBox="0 0 24 24" width={24} height={24} stroke="currentColor" strokeWidth={2} fill="none" strokeLinecap="round" strokeLinejoin="round" className="css-i6dzq1"><path d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z" /></svg> */}
              Favourite
            </span>
          </label>
        </div>
      </div>


)}

    </>
  );
}
const mapStateToProps = (state) => {
  return {
    name: state.name,
    All_Users: state.All_Users,
    All_Order: state.All_Orders
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    GetOrder: (setLoading,navigate) => dispatch(GetOrder(setLoading,navigate)),
    
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Order);

