import React, { useState, useEffect, useRef } from "react";
import OTPInput from "otp-input-react";
import BackImage from '../assets/login.jpg';
import "aos/dist/aos.css"; // You can also use <link> for styles
import Aos from "aos";
import { IoCloseOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import Swal from "sweetalert2";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from 'react-router-dom';
import { LoginIcon } from "@heroicons/react/outline";
import api from '../assets/variable'



Aos.init();
export default function Authuntication(props) {
  let navigate = useNavigate()
  const location = useLocation();

  const [OTP, setOTP] = useState("");
  let [code, setCode] = useState(false);
  let [error, setErrorMesseg] = useState("");
  console.log(props);
  let [edit, setEdit] = useState(false);

  useEffect(() => {

    document.body.style.background = `url(${BackImage})`;


    console.log(document);
  }, []);

  let VerifyME = () => {
    // console.log("verifing in process");
    setErrorMesseg("");
    // document.getElementById("animation_show_ha").style.display = "flex";


    localStorage.setItem('number', '+42342343')

    // if (OTP == "") {
    //   setErrorMesseg("Please Fill All Field's");
    //   document.getElementById("animation_show_ha").style.display = "none";
    //   return;
    // }
  };





  const send_otp = async () => {
    console.log("bbbbbbb",location.state);
    let body = {

      "phoneNumber":location.state,
      "option": "request"

    }


    try {
      Swal.showLoading();

      const response = await axios.post(`${api}/api/user/register-otp`, body, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json'
        }
      });
      console.log(">>>>.", response);
      Swal.close();
      setCode(true);
    } catch (error) {
      Swal.close();
      toast.error(error.response.data.code == 21211 ?
        "Invalid number" :
        error.response.data.message)

      console.log("eeeeee", error);

      if (error.response.data.message === "Too many Attempt") {
        let str1 = error.response.data.detail.replace(/[^\d.]/g, '');
        let total = parseInt(str1, 10)

        clearTimer(getDeadTime(total))
        console.log("Timer left >>>>>>>>>>", total);

      }
      //   Swal.fire({
      //   title:error.response.data.code==21211?
      //   "Invalid number":
      //   "Server Error",
      //   showDenyButton: false,
      //   showCancelButton: true,
      //   confirmButtonText: 'Yes',
      //   showConfirmButton:false,
      //   denyButtonText: 'No',
      //   customClass: {
      //     actions: 'my-actions',
      //     cancelButton: 'order-1 right-gap',
      //     confirmButton: 'order-2',
      //     denyButton: 'order-3',
      //   }
      // })    
    }

  }
  const Login = async () => {
    let body = {
      "phoneNumber": location.state,
      "option": "verify",
      "otp": parseInt(OTP)
    }

    try {
      Swal.showLoading();

      const response = await axios.post(`${api}/api/user/register-otp`, body, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json'
        }
      });
      setCode(true);

      let body1 = {
        "email": 'optional@gmail.com',
        "userId": response.data.data.user._id,

      }

      try {
        console.log("BEFORE <<<<<>>>>>>",response);
        if (response.data.statuscode != 100) {
          let response1 = await axios.patch(`${api}/api/user/update-profile`, body1, {
            withCredentials: true,
            headers: {
              'Content-Type': 'application/json'
            }
          });
          console.log("?????????????RESP1", response1);
        }
      } catch (error) {
        console.log(">>>RRT?>>>>>>", error.response.data.error);
        toast.error(error.response.data.error)
        Swal.close();
        return
      }

      Swal.fire(
        'Otp Confirmed',
        // 'You clicked the button!',
        // 'success'
      )




      var baseString = JSON.stringify(response.data.data.user._id);
      var encodedString = window.btoa(baseString); // returns "bXktbmFtZS0x"


      await localStorage.setItem('_id', encodedString)
      await localStorage.setItem('user_location', JSON.stringify(response.data.data.user.location))




      console.log(">>>>.......", response, "<<<<<<<", body);
      setTimeout(() => {
        Swal.close();

        if (localStorage.getItem('RefredUrl') && localStorage.getItem('RefredUrl').includes('&Item')) {
          navigate(`/Home${localStorage.getItem('RefredUrl')}`)
          return

        }
        navigate('/')


      }, 2500);



    } catch (error) {
      Swal.close();
      console.log(">>>>.", error, body);
      toast.error(error.response.data.data.message)
    }
    // navigate('/LoginInfoForm')

  }




  const Ref = useRef(null);

  // The state for our timer
  const [timer, setTimer] = useState('00:00');


  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    return {
      total, minutes, seconds
    };
  }


  const startTimer = (e) => {
    let { total, minutes, seconds }
      = getTimeRemaining(e);
    if (total >= 0) {

      // update the timer
      // check if less than 10 then we need to 
      // add '0' at the beginning of the variable
      setTimer(
        (minutes > 9 ? minutes : '0' + minutes) + ':'
        + (seconds > 9 ? seconds : '0' + seconds)
      )
    }
  }


  const clearTimer = (e) => {

    // If you adjust it you should also need to
    // adjust the Endtime formula we are about
    // to code next    
    setTimer('00:00');

    // If you try to remove this line the 
    // updating of timer Variable will be
    // after 1000ms or 1sec
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000)
    Ref.current = id;
  }

  const getDeadTime = (sec) => {
    let deadline = new Date();

    // This is where you need to adjust if 
    // you entend to add more time
    deadline.setSeconds(deadline.getSeconds() + sec);
    return deadline;
  }





  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <ToastContainer />
      <div
        id="animation_show_ha"
        style={{
          position: "absolute",
          backgroundColor: "rgb(23, 19, 54,0.8)",
          width: "100%",
          display: "none",
          justifyContent: "center",
          alignItems: "center",
          zIndex: "22",
          height: "100%",
          marginTop: "0px",
        }}
      >
        <div className="loading">Loading&#8230;</div>
      </div>

      <div className="content">
        <div className="formDiv"
          data-aos="flip-right"
          data-aos-duration="1500"
          data-aos-once="true">
          <IoCloseOutline onClick={() => navigate('/')} color="#fff" size={30} className='backArrow' />
          <h2 className="head">Phone Authuntication</h2>

          {code ? (
            <>
              <p
                className="pirat"
                style={{ textAlign: "center", color: "#fff", fontSize: 13 }}
              >
                Your Received OTP on {location.state}.{" "}
                <br />
                Type here
              </p>
              <OTPInput
                value={OTP}
                onChange={setOTP}
                autoFocus
                OTPLength={6}
                inputStyles={{
                  borderRadius: 3,
                  border: "1px solid purple",
                  backgroundColor: "#fff",
                  padding: 0,
                  marginTop: 30,
                  marginLeft: 6,
                  marginRight: 6

                }}
                otpType="number"
                disabled={false}
              />

              <div className="d-flex">
                <button className="buttonMe" onClick={() =>
                  Login()
                }>
                  Verify
                </button>

                <button className="buttonMe ml-3" onClick={() => navigate('/PhoneInput')}>
                  Cancel
                </button>
              </div>

            </>
          ) : (
            <>
              <p
                className="pirat"
                style={{
                  textAlign: "center",
                  color: "#fff",
                  fontSize: 13,
                  marginTop: 30,
                }}
              >
                Your Phone number is {location.state}
                <br /> Confirm Your number.
              </p>

              {edit ? (
                <>
                  <input
                    className="editNumber"
                    onChange={(e) =>
                      sessionStorage.setItem("otpNumber", e.target.value)
                    }
                    style={{ width: 260 }}
                    placeholder="Number2"
                  />
                  <div className="d-flex">
                    <button
                      onClick={() => {
                        setEdit(!edit);
                      }}
                      className="buttonMe"
                    >
                      Confirm
                    </button>
                    <button
                      onClick={() => {
                        setEdit(!edit);
                      }}
                      className="buttonMe ml-3"
                    >
                      Cancel
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <button
                    className="buttonMe"
                    onClick={() => {
                      send_otp()
                    }}
                  >
                    Send code
                  </button>

                  {timer !== '00:00' ?
                    <p
                      onClick={() => {
                        // setEdit(!edit);
                        navigate('/PhoneInput')
                      }}
                      style={{
                        width: "70%",
                        textAlign: "center",
                        color: "#fff",
                        fontSize: 14,
                        textDecoration: "underline",
                        fontWeight: 'bold'
                      }}
                    >
                      Next Try in : {timer}
                    </p>
                    :
                    ''}

                  <p
                    onClick={() => {
                      // setEdit(!edit);
                      navigate('/PhoneInput',{state:location.state.slice(1,location.state.length)})
                    }}
                    style={{
                      width: "70%",
                      textAlign: "center",
                      color: "#fff",
                      cursor: "pointer",
                      fontSize: 14,
                      textDecoration: "underline",
                    }}
                  >
                    Want to change Number ?
                  </p>
                </>
              )}
            </>
          )}

          <p
            style={{
              textAlign: "center",
              width: "80%",
              color: "red",
              fontSize: 12,
            }}
          >
            {error}
          </p>
        </div>
      </div>
    </div>
  );
}
