import React, { useEffect } from "react";
import { connect } from "react-redux";
import Navbar from "./Sub Component/Navbar";
import "react-multi-carousel/lib/styles.css";
import "aos/dist/aos.css"; // You can also use <link> for styles
import Aos from "aos";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { HomeCreate } from "../store/acion";
Aos.init();
function Main({Home,HomeCreate}) {
  let navigate = useNavigate();
  useEffect(() => {
    document.body.style.background = ``;
});

useEffect(()=>{
  if(Home==undefined){

    HomeCreate()
  }else{
    const {data}=Home
    console.log("bbbbbbbbbbb",data);
      // setLoading(false)
     
  }
})

  useEffect(() => {
    document.body.style.background = ``;
    if (localStorage.getItem("Selected_location") && localStorage.getItem('Searched_History')) {
      navigate("/Home")
      return
    }
    if (localStorage.getItem("Selected_location")) {
      navigate('/Categories')
    }

  }, []);


  const navi = async () => {
    // const response = await axios.post(
    //   `http://localhost:3004/api/v1/hotel/create`,
    //   {
    //     shopId: "ShopId2",
    //   },
    //   {
    //     // credentials : "include",
    //     withCredentials: true,
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //   }
    // );
    // console.log(">>>>>>>>.",response);
    // const response2 = await axios.post(
    //   `http://localhost:3004/api/v1/hotel/update`,
    //   {
    //     shopId: "ShopId2",
    //   },
    //   {
    //     // credentials : "include",
    //     withCredentials: true,
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //   }
    // );
    // console.log("<<<<<<<<>>>>>>>>.",response2);

      if (localStorage.getItem("Selected_location")) {
        navigate("/Categories")

      }
      else {
        navigate("/map")

      }
   


  }
  return (
    <>
      {/* Navbar  */}
      <Navbar Login={false} />
      <ToastContainer />

      {/* <img className="mainBoxi" src={require("../assets/back.png")} /> */}

      <div className="mainBoxi">

      </div>
      <div className="mainSearchBox">
        <p className="text-5xl text-white fontPopin">
          Welcome to Cruuz,
          how Can we help?
        </p>

        <div className="inputBox"
          onClick={() => navi()}
        >
          <i className="fa fa-search   mainsearchbari" style={{ color: '#13242A' }}></i>
          <input
            className=" mainsearchbar fontPopin"
disabled
            placeholder="What can we get you?"
          />
        </div>
      </div>

      <div className="mainFlex bordderBottomCards" style={{ marginTop: -160 }}>

        <div className="mainboxex" onClick={() => navi()} >
          <div className="p-2">
            <p className="text-3xl text-black  fontPopin mainBarC fixWid">Explore our Pros</p>

            <button className="newBookingBu fontPopin">Book Now</button>
          </div>

          <img className="mainboxeximg" src={require("../assets/b2.png")}></img>
        </div>

        <div className="mainboxex" onClick={() => navi()}>
          <div className="p-2">
            <p className="text-3xl text-black  fontPopin mainBarC fixWid">View our catalog</p>

            <button className="newBookingBu fontPopin">Explore</button>
          </div>

          <img className="mainboxeximg" src={require("../assets/b1.png")}></img>
        </div>
      </div>

      {/* <Footer /> */}
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    Home: state.Home
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    HomeCreate: () => dispatch(HomeCreate()),
    
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Main);
