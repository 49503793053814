import { secondaryApp } from "../../cofig/firebase";
import { doc, onSnapshot, getFirestore, collection, updateDoc, arrayRemove, arrayUnion, deleteDoc, query, where,setDoc } from "firebase/firestore";
import api from '../../assets/variable'
import { getStorage, ref, deleteObject } from "firebase/storage";
import { async } from "@firebase/util";
import axios from "axios";
import { useNavigate } from "react-router-dom";



// *****************************
// function for google login

const db = getFirestore();

// ******************************************************************************************

const get_all_Users = (setLoading) => {
  return (dispatch) => {
    setLoading(true);
    const db = getFirestore();
    const ChatRef = collection(db, "Chats");

    const q = query(ChatRef, where("users", "array-contains", "5fd705e40c3e39401926adfe"));
    onSnapshot(q, (querySnapshot) => {
      let arr = [];
      querySnapshot.forEach(async (doc) => {


        const ChatRef = await collection(db, "Chats", doc.id, 'ChatHistoryText');



        console.log(">>>>>>>>>",doc.id);
        onSnapshot(ChatRef, async (e) => {
          let chat_head = [];

          await e.forEach((document) => {
            chat_head.push({ id: document.id, data: document.data()});
          });

          console.log(">>>>Chat Head>>>>",  chat_head[0]);


          arr.push({ id: doc.id, heading: chat_head[0] });
          console.log("ARR>>>>>",arr);
          dispatch({
            type: "All_Users",
            payload: arr,
          });
          setLoading(false);

        });
  
      
      });


    });
  };
};
//  *********************************************************************************
// ******************************************************************************************
const SendMessage= async({senderID,recvicerID,senderName,recvicerName},e)=>{
 
const data={
  id:recvicerID,
  created:new Date(),
  read:false,
  content:e,
  senderID,
  recvicerID,
  senderName,
  recvicerName
}
console.log("dddddddddddddd",data);
    const newCityRef = doc(collection(db, "Chats", '9Dvle9ptSjn0HxSUU9AY', 'thread'));
    
    // later...
    await setDoc(newCityRef, data);
  
}

const get_all_Chats = (setLoading, setopen_Details) => {
  return (dispatch) => {
    setLoading(true);
    const db = getFirestore();
    const ChatRef = collection(db, "Chats", '9Dvle9ptSjn0HxSUU9AY', 'thread');

    onSnapshot(ChatRef, (querySnapshot) => {
    let arr = [];
   console.log("NNNNNNNNN");
    querySnapshot.forEach((doc) => {
    arr.push({ id: doc.id, data: doc.data() });
    });
    arr.sort(function(a, b){if(b.data.created){return(b.data.created.seconds-a.data.created.seconds);}})


    dispatch({
     type: "All_Chats",
     payload: arr,
    });
    setopen_Details(true)
    setLoading(false);
    });
  };
};

const open_google_map=(shop_location)=>{

  let UserLocation=JSON.parse( localStorage.getItem('user_location'))
  let ShopLocation=shop_location
    window.open(`https://www.google.com/maps/dir/?api=1&origin=${UserLocation[0]}%2C${UserLocation[1]}&destination=${ShopLocation[0]}%2C${ShopLocation[1]}`
    )
  
  }

const GetOrder=  (setLoading,navigate)=>{

  
    return( async (dispatch)=>{

      try {
        const response= await axios.get(
          `${api}/api/user/getappointmentall`,
          {
            withCredentials: true,
            headers: {
              "Content-Type": "application/json",
            },
          }
          );  
          console.log('data>>>>>>>>>>',response);
       
        dispatch({
          type: "All_Orders",
          payload: response,
        });


      }catch (e) {
        console.log('error>>>>>>>>>>',e);
        if (e.response.data.error == 'Auth Invalid')
         navigate('/')
  
         
          dispatch({
            type: "All_Orders",
            payload: e.message,
          });
      
          }

     
    })
    
 
 
}



const HomeCreate=  ()=>{


    return( async (dispatch)=>{
      try {
        const response= await axios.get(
          `${api}/api/GuestLogin`,
          {
            withCredentials: true,
            headers: {
              "Content-Type": "application/json",
            },
          }
          );  
        dispatch({
          type: "Home",
          payload: response,
        });
      }catch (e) {
        console.log('error>>>>>>>>>>',e);        
          dispatch({
            type: "Home",
            payload: e,
          });
      
          }

     
    })
   
  


}


export {
   get_all_Users,
   get_all_Chats,
   SendMessage,
   open_google_map,
   GetOrder,
   HomeCreate
  };
