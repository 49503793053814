import React from "react";
import { connect } from "react-redux";
import Navbar from "./Sub Component/cartNavbar";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "aos/dist/aos.css"; // You can also use <link> for styles
import Aos from "aos";
import { useNavigate } from "react-router-dom";

Aos.init();
function DailyDealDetails(props) {
  let navigate = useNavigate();

  let arr = [
    {
      title: "jasklfj",
      image: "https://img.homejournal.com/202008/5f27752d94eeb.jpeg",
      status: "In Home",
    },
    {
      title: "jasklfj",
      image: "https://femina.wwmindia.com/content/2020/sep/home-appliances.jpg",
      status: "In Shop",
    },
    {
      title: "jasklfj",
      image:
        "https://s3-ap-southeast-1.amazonaws.com/hnsgsfp/9/images/Home_Appliances_Category/homeappliances-homepage-banner-mobile.png",
      status: "In Shop",
    },
    {
      title: "jasklfj",
      image:
        "https://i.pinimg.com/736x/58/15/7f/58157ffd015159d4437316e3190cad09.jpg",
      status: "In Home",
    },
    {
      title: "jasklfj",
      image: "https://img.homejournal.com/202008/5f27752d94eeb.jpeg",
      status: "In Shop",
    },
    {
      title: "jasklfj",
      image: "https://femina.wwmindia.com/content/2020/sep/home-appliances.jpg",
      status: "In Home",
    },
    {
      title: "jasklfj",
      image:
        "https://s3-ap-southeast-1.amazonaws.com/hnsgsfp/9/images/Home_Appliances_Category/homeappliances-homepage-banner-mobile.png",
      status: "In Shop",
    },
    {
      title: "jasklfj",
      image:
        "https://i.pinimg.com/736x/58/15/7f/58157ffd015159d4437316e3190cad09.jpg",
      status: "In Home",
    },
    {
      title: "jasklfj",
      image: "https://img.homejournal.com/202008/5f27752d94eeb.jpeg",
      status: "In Home",
    },
    {
      title: "jasklfj",
      image: "https://femina.wwmindia.com/content/2020/sep/home-appliances.jpg",
      status: "In Home",
    },
    {
      title: "jasklfj",
      image:
        "https://s3-ap-southeast-1.amazonaws.com/hnsgsfp/9/images/Home_Appliances_Category/homeappliances-homepage-banner-mobile.png",
      status: "In Shop",
    },
    {
      title: "jasklfj",
      image:
        "https://i.pinimg.com/736x/58/15/7f/58157ffd015159d4437316e3190cad09.jpg",
      status: "In Home",
    },
  ];

  return (
    <>
      {/* Navbar  */}
      <Navbar back="/Details" />

      {/* Slider  */}

      {/* Nearby */}
      <div className="catagries_div">
        <div className="heading_main_mapbet">
          <font className="heading_main_map_font22">
            Daily Deals Sub Products
          </font>
          {/* <font className='heading_main_map_font11'>Available</font> */}

          <div className="form-group has-search">
            <span className="fa fa-search form-control-feedback zIndex-0"></span>
            <input
              type="text"
              className="form-control searchInput"
              placeholder="Search"
            />
          </div>
        </div>

        <div className="mainFlex">
          {arr.map((v, i) => {
            return (
              <div
              key={i}
                onClick={() => {}}
                className="flex justify-center items-center cursor-pointer"
                style={{ width: 360 }}
              >
                <a href="#popup1">
                  <div className="container flex justify-center">
                    <div className="max-w-sm py-12">
                      <div className="bg-white relative shadow-lg hover:shadow-xl transition duration-500 rounded-lg">
                        <img
                          style={{
                            width: "100%",
                            height: 250,
                            resize: "cover",
                            borderRadius: 10,
                          }}
                          src={v.image}
                          alt=""
                        />
                        <div className="py-6 px-8 rounded-lg bg-white">
                          <h1 className="text-gray-700 font-600 text-2xl mb-3 hover:text-gray-900 hover:cursor-pointer">
                            I'm supper dog for you.
                          </h1>
                          <div
                            style={{
                              fontSize: 18,
                              fontWeight: "600",
                              color: "#13242A",
                            }}
                          >
                            10$
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            );
          })}
        </div>

        <div id="popup1" className="overlay">
          <div className="popup">
            <div
              className="
          bg-white
          shadow-md
          mx-3          
          d-flex flex-row align-items-start
          flex-wrap
        "
            >
              <img
                className="popupimage1 object-cover"
                src="https://images.unsplash.com/photo-1484101403633-562f891dc89a?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1053&q=80"
                alt="image"
              />

              <div
                className="
            flex-1
            w-full
            flex flex-col
            items-baseline
            justify-around
           subDataDiv
          "
              >
                <div
                  style={{ width: "100%" }}
                  className="d-flex justify-content-md-between"
                >
                  <p
                    style={{
                      fontSize: 20,
                      fontWeight: "600",
                      color: "grey",
                      marginTop: 10,
                    }}
                  >
                    Furniture
                  </p>
                  <a className="close" href="#">
                    &times;
                  </a>
                </div>
                <span
                  style={{
                    fontSize: 15,
                    fontWeight: "600",
                    color: "#13242A",
                    paddingTop: 10,
                    paddingBottom: 5,
                  }}
                  className="text-xs text-indigo-300 mt-0"
                >
                  by supplier
                </span>
                <p className="text-xs text-gray-500 w-5/5">
                  Please Select More Options
                </p>

                {[0, 1, 1].map((v, i) => {
                  return (
                    <div className="d-flex p-2 align-items-center divBoxex" key={i}>
                      <div className="d-flex align-items-center ">
                        <input type={"checkbox"} />
                        <p className="ml-2">a</p>
                      </div>
                      <span
                        className="text-md"
                        style={{
                          fontSize: 17,
                          fontWeight: "500",
                          color: "#13242A",
                        }}
                      >
                        $150
                      </span>
                    </div>
                  );
                })}

                <button className=" mainBar text-white px-3 py-1 rounded-sm shadow-md">
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    name: state.name,
  };
};

export default connect(mapStateToProps, null)(DailyDealDetails);
