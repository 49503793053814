import React from "react";
import { connect } from "react-redux";
import Navbar from "./Sub Component/NavbarBack";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "aos/dist/aos.css"; // You can also use <link> for styles
import Aos from "aos";
import Footer from "./Sub Component/footer";
import { useNavigate } from "react-router-dom";
import { AiFillCheckCircle, AiFillPlusCircle } from "react-icons/ai";
import { BiCurrentLocation } from "react-icons/bi";
Aos.init();
function Address(props) {
  let navigate = useNavigate();

  let arr = [
    {
      title: "jasklfj",
      image: "https://img.homejournal.com/202008/5f27752d94eeb.jpeg",
      status: "In Home",
    },
    {
      title: "jasklfj",
      image: "https://femina.wwmindia.com/content/2020/sep/home-appliances.jpg",
      status: "In Shop",
    },

    {
      title: "jasklfj",
      image:
        "https://i.pinimg.com/736x/58/15/7f/58157ffd015159d4437316e3190cad09.jpg",
      status: "In Home",
    },
  ];

  return (
    <>
      {/* Navbar  */}
      <Navbar back="/" />

      {/* Slider  */}

      {/* Nearby */}
      <div className="catagries_div">
        <div className="heading_main_mapbet">
          <font className="heading_main_map_font22">Address</font>
        </div>
        <font className="heading_main_map_font11 font-bold ml-10">
          Select An Address
        </font>

        <div className="flex lookBox row">
          <div
            onClick={() => navigate("/map")}
            className="relative rounded-md p-3 hover:bg-gray-100 cursor-pointer shadow p-3 mb-5 bg-white rounded"
          >
            <div className="showflex">
              <AiFillPlusCircle size={30} color={"#13242A"} />
              <font className="heading_main_map_font11 font-bold ml-10">
                Add New Address
              </font>
            </div>
          </div>
          {arr.map((v, i) => {
            return (
              <div
                key={i}
                className="relative rounded-md p-3 hover:bg-gray-100 cursor-pointer shadow p-3 mb-5 bg-white rounded" >
                <BiCurrentLocation size={30} color={"#13242A"} />
                <div className="w-full d-flex flex-column ml-5 mr-5 align-item-start  justify-content-start ">
                  <h1 className="text-m font-bold leading-5">{v.title}</h1>

                  <p className="text-m  leading-1  " style={{ width: "100%" }}>
                    {v.title} + other details about address
                  </p>
                </div>
                <div className="showflex">
                  <AiFillCheckCircle size={30} color={"#13242A"} />
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <Footer />
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    name: state.name,
  };
};

export default connect(mapStateToProps, null)(Address);
