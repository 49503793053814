import React, { useState, useEffect } from "react";
import OTPInput from "otp-input-react";
import BackImage from "../../assets/login.jpg";
import "aos/dist/aos.css"; // You can also use <link> for styles
import Aos from "aos";
import { IoCloseOutline } from "react-icons/io5";
import { useNavigate ,useLocation} from "react-router-dom";
import { FaProcedures } from "react-icons/fa";

Aos.init();
export default function PhoneInput(props) {
  let navigate = useNavigate();
  const location = useLocation();

  const [OTP, setOTP] = useState("");
  let [number1, setNumber1] = useState();
  let [code, setCode] = useState(false);
  let [error, setErrorMesseg] = useState("");
  let [error_s, seterror_s] = useState("none");

  console.log(props);
  let [edit, setEdit] = useState(false);

  useEffect(() => {
    document.body.style.background = `url(${BackImage})`;

    console.log(location.state);

    if(location.state){
      setNumber1(location.state)
    }
  }, []);

  let VerifyME = () => {
    // console.log("verifing in process");
    setErrorMesseg("");
    // document.getElementById("animation_show_ha").style.display = "flex";

    localStorage.setItem("number", "+42342343");

    // if (OTP == "") {
    //   setErrorMesseg("Please Fill All Field's");
    //   document.getElementById("animation_show_ha").style.display = "none";
    //   return;
    // }
  };
  const proceed=()=>{
    console.log("sada",`+1${number1}` );
    if (number1){
      seterror_s("none")
      navigate("/Authuntication",{state:`+1${number1}`});
      
    }
    seterror_s("flex")

  }

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <div
        id="animation_show_ha"
        style={{
          position: "absolute",
          backgroundColor: "rgb(23, 19, 54,0.8)",
          width: "100%",
          display: "none",
          justifyContent: "center",
          alignItems: "center",
          zIndex: "22",
          height: "100%",
          marginTop: "0px",
        }}
      >
        <div class="loading">Loading&#8230;</div>
      </div>

      <div class="content">
        <div
          className="formDiv"
          data-aos="zoom-in-up"
          data-aos-duration="1500"
          data-aos-once="true"
        >
          <IoCloseOutline
            onClick={() => navigate("/")}
            color="#fff"
            size={30}
            className="backArrow"
          />
          <h2 className="head">Enter Your Mobile Number</h2>

          <p
            className="pirat"
            style={{
              textAlign: "center",
              color: "#fff",
              fontSize: 13,
              marginTop: 20,
            }}
          >
            We'll Send You a Text Code To verify your Phone
          </p>

         <div className="inputNumberBox">
         <p>
           +1
         </p>
         <input
            className="editNumber"
            onChange={(e) =>
              setNumber1(e.target.value)
            }
            style={{ width: 260, marginTop: 40 }}
            placeholder="(801) 123 12321"
            value={number1}
            maxLength={10}
          />
         </div>
          <div style={{display:error_s,justifyContent:'flex-start',alignItems:'flex-start',width:'50%'}}>
          <span style={{color:'red'}}>*Required Phone Number</span>

          </div>
          <div className="d-flex">
            <button
              onClick={() => {
                navigate("/");
              }}
              className="buttonMe"
            >
              Back
            </button>
            <button
              onClick={() => {
                 proceed()
              }}
              className="buttonMe ml-3"
            >
              Next
            </button>
          </div>

          <p
            style={{
              textAlign: "center",
              width: "80%",
              color: "red",
              fontSize: 12,
            }}
          >
            {error}
          </p>
        </div>
      </div>
    </div>
  );
}
