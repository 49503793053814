import React from "react";
import { connect } from "react-redux";
import Navbar from "./Sub Component/NavbarBack";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "aos/dist/aos.css"; // You can also use <link> for styles
import Aos from "aos";
import { useNavigate } from "react-router-dom";

import {IoMdArrowBack} from 'react-icons/io';
Aos.init();
function GroupOrder(Props) {
  let navigate = useNavigate();

  let arr = [
    {
      title: "jasklfj",
      image: "https://img.homejournal.com/202008/5f27752d94eeb.jpeg",
      status: "In Home",
    },
    {
      title: "jasklfj",
      image: "https://femina.wwmindia.com/content/2020/sep/home-appliances.jpg",
      status: "In Shop",
    },

    {
      title: "jasklfj",
      image:
        "https://i.pinimg.com/736x/58/15/7f/58157ffd015159d4437316e3190cad09.jpg",
      status: "In Home",
    },
  ];

  return (
    <>
    <div className="groupordermainDiv">
    <div onClick={()=>navigate(Props.back)} style={{position:'absolute',left:20,top:12,backgroundColor:'rgba(211, 210, 211, 0.44)',borderRadius:100,padding:5,cursor:'pointer'}}>
      < IoMdArrowBack size={30} color='#fff' />
    </div>
<h1 data-aos="fade-down"  data-aos-duration="1500" className="groupOrderHEading">
    Group Order
</h1>



<h1 data-aos="fade-down"  data-aos-duration="1500" className="groupOrderHEading coustomizeText mt-4">
   Book With a Friend and Earn a Discount
</h1>
<div className="groupOrderCard mt-6" data-aos="fade-left"  data-aos-duration="1500">
<img className="imgDo3 " src={'https://img.homejournal.com/202008/5f27752d94eeb.jpeg'} />

<h4 className="cardheadingGO ">
    10% of Booking of 2 people minimum
</h4>


</div>


<div className="groupOrderCard mt-6" data-aos="fade-left"  data-aos-duration="1500">
<img className="imgDo3 " src={'https://img.homejournal.com/202008/5f27752d94eeb.jpeg'} />

<h4 className="cardheadingGO ">
20% of Booking of 3 people minimum
</h4>


</div>




<div className="groupOrderCard mt-6" data-aos="fade-left"  data-aos-duration="1500">
<img className="imgDo3 " src={'https://img.homejournal.com/202008/5f27752d94eeb.jpeg'} />

<h4 className="cardheadingGO ">
    30% of Booking of 4 or More people 
</h4>


</div>


<div className="currentNav4 mt-9">Start Party</div>

    </div>
    





    </>
  );
}
const mapStateToProps = (state) => {
  return {
    name: state.name,
  };
};

export default connect(mapStateToProps, null)(GroupOrder);
