import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Navbar from './Sub Component/logoutNavbar';
import {BiPencil} from 'react-icons/bi';
function Profile(props) {
  let [name, setName] = useState("Hassan");
  let [address, setAddress] = useState("Local addredd");
  let [image, setImage] = useState(
    localStorage.getItem("user_profile") == "undefined" ||
      localStorage.getItem("user_profile") == undefined
      ? "http://i.pravatar.cc/500?img=7"
      : localStorage.getItem("user_profile")
  );
  let [progress, setProgress] = useState("");
  let [error, setError] = useState("");
  let [nameValidate, setnameValidate] = useState("");
  let [emailValidate, setemailValidate] = useState("");
  let [file, setFile] = useState("");

  let HideMeShowEdit = () => {
    document.getElementById("SeeME").style.display = "none";

    document.getElementById("EditME").style.display = "block";

    document.getElementById("hidingMeThis").style.display = "block";
  };

  let UpdateME = () => {
    if (name == "" || address == "") {
      if (name == "") {
        setnameValidate("* Required");
        return;
      }
      if (address == "" || address == null || address == "null") {
        setemailValidate("* Required");
        return;
      }
    }

    document.getElementById("SeeME").style.display = "block";

    document.getElementById("EditME").style.display = "none";
    document.getElementById("hidingMeThis").style.display = "none";
  };

  var openFile = function (event) {
    var input = event.target;
    var reader = new FileReader();
    reader.onload = fileLoaded;
    reader.readAsDataURL(input.files[0]);
  };

  var fileLoaded = function (event) {
    var dataURL = event.target.result;
    var output = document.getElementById("output");
    output.src = dataURL;

    // Do your other stuff here with dataURL
    console.log(">>>>>>>>>", dataURL);
  };

  return (
    <div>
      <div className="loading" id="loading" style={{ display: "none" }}>
        Loading&#8230;
      </div>

    
<Navbar back='/' />

      <div className="content">
        <div className="main-body">
          <nav aria-label="breadcrumb" className="main-breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="index.html">Home</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                User Profile
              </li>
            </ol>
          </nav>

          <div className="row gutters-sm">
            <div className="col-md-4 mb-3">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex flex-column align-items-center text-center">
                    <div className="container1">
                      <div className="avatar-upload">
                        <div className="avatar-edit">
                            <input
                              onChange={(e) => {
                                setFile(e.target.files[0]);
                                openFile(e);
                              }}
                              type="file"
                              id="imageUpload"
                              accept=".png, .jpg, .jpeg"
                            />
                          <label
                            id="hidingMeThis"
                            style={{ display: "none" }}
                            for="imageUpload"
                          ></label>
                         
                        </div>
                        <div className="avatar-preview">
                          <img
                            style={{
                              width: 140,
                              height: 140,
                              borderRadius: 70,
                            }}
                            id="output"
                            src={image}
                          ></img>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="card mb-3" id="SeeME">
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-3">
                      <h6 className="mb-0">Name</h6>
                    </div>
                    <div className="col-sm-9 text-secondary">{name} </div>
                  </div>
                  <hr />
                  <br></br>
                  <br></br>

                  <div className="row">
                    <div className="col-sm-3">
                      <h6 className="mb-0">Phone</h6>
                    </div>
                    <div className="col-sm-9 text-secondary">+9234234234234</div>
                  </div>
                  <hr />
                  <br></br>
                  <br></br>

                  <div className="row">
                    <div className="col-sm-3">
                      <h6 className="mb-0">Address</h6>
                    </div>
                    <div className="col-sm-9 text-secondary">{address}</div>
                  </div>
                  <hr />
                  <div style={{ color: "red" }}>{error}</div>
                  <br></br>

                  <div className="row">
                    <div className="col-sm-12">
                      <button
                        className="btn"
                        style={{backgroundColor:'#13242A',outline:'none',color:'#fff'}}
                        onClick={() => {
                          HideMeShowEdit();
                        }}
                      >
                        Edit Profile
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card" id="EditME" style={{ display: "none" }}>
                <div className="card-body">
                  <div className="row mb-3">
                    <div className="col-sm-3">
                      <h6 className="mb-0">Name</h6>
                    </div>
                    <div className="col-sm-9 text-secondary">
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={name}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                      />
                      <p style={{ color: "red", fontSize: 11, marginTop: 5 }}>
                        {nameValidate}
                      </p>
                    </div>
                  </div>

                  <div className="row mb-3">
                    <div className="col-sm-3">
                      <h6 className="mb-0">Phone</h6>
                    </div>
                    <div className="col-sm-9 text-secondary">
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={"+9123123213"}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                      />
                      <p style={{ color: "red", fontSize: 11, marginTop: 5 }}>
                        {nameValidate}
                      </p>
                    </div>
                  </div>

                  <div className="row mb-3">
                    <div className="col-sm-3">
                      <h6 className="mb-0">Email</h6>
                    </div>
                    <div className="col-sm-9 text-secondary">
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={"meet2awais@gmail.com"}
                        onChange={(e) => {
                          setAddress(e.target.value);
                        }}
                      />
                      <p style={{ color: "red", fontSize: 11, marginTop: 5 }}>
                        {emailValidate}
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-3"></div>
                    <div
                      className="col-sm-9 text-secondary"
                      onClick={() => {
                        UpdateME();
                      }}
                    >
                      {/* <input type="button" className="btn btn-danger" value="Save Changes" /> */}
                      <button
                        className="btn"
                        style={{backgroundColor:'#13242A',outline:'none',color:'#fff'}}

                        onClick={() => {
                          UpdateME();
                        }}
                      >
                        Save Changes
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
